/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/zendesk
 * @author  Mert Gulmus <mert.gulmus@scandiweb.com | info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ZendeskCustomLauncherComponent from './ZendeskCustomLauncher.component';
import { ZENDESK_SCRIPT_ID } from './ZendeskCustomLauncher.config';

/** @namespace Zendesk/Component/ZendeskCustomLauncher/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    zendesk: state.ConfigReducer.zendesk
});

/** @namespace Zendesk/Component/ZendeskCustomLauncher/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Zendesk/Component/ZendeskCustomLauncher/Container/ZendeskCustomLauncherContainer */
export class ZendeskCustomLauncherContainer extends PureComponent {
    static propTypes = {
        zendesk: PropTypes.shape({
            zendesk_enabled: PropTypes.bool,
            zendesk_url: PropTypes.string
        }).isRequired
    };

    componentDidMount() {
        this.initZenDesk();
    }

    componentWillUnmount() {
        this.removeZendeskScripts();

        window.removeEventListener('keydown', (_e) => {
            const iframe = document.getElementById('webWidget');
            iframe?.setAttribute('aria-label', 'Support help');
            iframe?.setAttribute('role', 'region');

            const button = document.getElementById('launcher')?.contentWindow
                .document.querySelector('button[aria-label="Chat"]');

            button?.remove();
        });

        window.removeEventListener('click', (_e) => {
            const iframe = document.getElementById('webWidget');
            iframe?.setAttribute('aria-label', 'Support help');
            iframe?.setAttribute('role', 'region');

            const button = document.getElementById('launcher')?.contentWindow
                .document.querySelector('button[aria-label="Chat"]');

            button?.remove();

            const mobileButton = iframe?.contentWindow.document.querySelector('button[aria-label="Support"]');
            mobileButton?.remove();
        });
    }

    initZenDesk() {
        const { zendesk: { zendesk_url, zendesk_enabled } = {} } = this.props;
        const script = document.getElementById(ZENDESK_SCRIPT_ID);

        if (!zendesk_enabled) {
            setTimeout(() => this.initZenDesk(), 1000);
            return;
        }

        if (!script) {
            this.injectZendeskScript();
        }

        window.addEventListener('keydown', (_e) => {
            const iframe = document.getElementById('webWidget');
            iframe?.setAttribute('aria-label', 'Support help');
            iframe?.setAttribute('role', 'region');

            const launchers = document.querySelectorAll('#launcher');
            launchers?.forEach((launcher) => {
                this.removeOldLauncher(launcher);
            });
        });

        window.addEventListener('click', (_e) => {
            const iframe = document.getElementById('webWidget');
            iframe?.setAttribute('aria-label', 'Support help');
            iframe?.setAttribute('role', 'region');

            const launchers = document.querySelectorAll('#launcher');
            launchers?.forEach((launcher) => {
                this.removeOldLauncher(launcher);
            });
        });

        const buttonElements = document.querySelectorAll('[id^="zendesk-custom-launcher-wrapper"]');
        if (buttonElements.length > 1) {
            buttonElements.forEach((buttonElement, index) => {
                if (index === 0) {
                    return;
                }

                buttonElement.remove();
            });
        }
    }

    removeOldLauncher(iframe) {
        iframe.setAttribute('tabindex', '-1');
        const button = iframe?.contentWindow.document.querySelector('button[aria-label="Chat"]');
        button?.remove();

        const mobileButton = iframe?.contentWindow.document.querySelector('button[aria-label="Support"]');
        mobileButton?.remove();
    }

    removeZendeskScripts() {
        const script = document.getElementById(ZENDESK_SCRIPT_ID);
        if (script) {
            script.remove();
        }

        const contactUs = document.getElementById('footer-contact-us-link');

        if (contactUs) {
            contactUs.onclick = () => {};
        }
    }

    injectZendeskScript() {
        const {
            zendesk: {
                zendesk_url,
                zendesk_enabled
            } = {}
        } = this.props;

        if (!zendesk_enabled) {
            return;
        }

        const scriptElement = document.createElement('script');
        scriptElement.id = ZENDESK_SCRIPT_ID;
        scriptElement.src = zendesk_url;
        scriptElement.onload = this.setupZendesk;

        document.head.insertBefore(scriptElement, document.head.childNodes[0]);
    }

    setupZendesk() {
        const { zE } = window;
        const waitForElement = (selector) => new Promise((resolve) => {
            if (document.getElementById(selector)) {
                return resolve(document.getElementById(selector));
            }

            const observer = new MutationObserver(() => {
                if (document.getElementById(selector)) {
                    observer.disconnect();
                    resolve(document.getElementById(selector));
                }
            });

            return observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });

        function onOpen() {
            const contactUs = document.getElementById('footer-contact-us-link');

            waitForElement('webWidget').then(
                /** @namespace Zendesk/Component/ZendeskCustomLauncher/Container/waitForElement/then */
                (iframe) => iframe.contentWindow?.focus()
            );

            contactUs?.setAttribute('aria-expanded', 'true');
        }

        function onClose() {
            const contactUs = document.getElementById('footer-contact-us-link');
            const customLauncher = document.getElementById('zendesk-custom-launcher-wrapper');

            contactUs?.setAttribute('aria-expanded', 'false');

            if (window.zenDeskFocusReturn) {
                window.zenDeskFocusReturn.focus();
            } else {
                const customLauncherButton = document.getElementById('zendesk-custom-launcher-button');

                customLauncherButton?.focus();
            }

            window.zenDeskFocusReturn = null;

            if (customLauncher) {
                customLauncher.style.opacity = 1;
                customLauncher.setAttribute('aria-expanded', 'false');
            }
        }

        zE('webWidget:on', 'open', onOpen);
        zE('webWidget:on', 'close', onClose);
    }

    render() {
        const {
            zendesk: {
                zendesk_enabled
            } = {}
        } = this.props;

        if (!zendesk_enabled) {
            return null;
        }

        return (
            <ZendeskCustomLauncherComponent />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZendeskCustomLauncherContainer));
