import {
    ProductReviewRating as SourceProductReviewRating
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.override.style';

/** @namespace Scandiweb/MoninTheme/Component/ProductReviewRating/Component/ProductReviewRatingComponent */
export class ProductReviewRatingComponent extends SourceProductReviewRating {}

export default ProductReviewRatingComponent;
