/* eslint-disable max-len */
import {
    CartIcon as SourceCartIcon
} from 'SourceComponent/CartIcon/CartIcon.component';

import './CartIcon.override.style';

/** @namespace Scandiweb/MoninTheme/Component/CartIcon/Component/CartIconComponent */
export class CartIconComponent extends SourceCartIcon {
    render() {
        return (
            <svg
              block="CartIcon"
              width="30"
              height="30"
              viewBox="0 0 31 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_4440_6368)">
                    <path d="M1.5 1.11436C1.5 0.818816 1.61741 0.535373 1.82639 0.326389C2.03537 0.117406 2.31882 0 2.61436 0H3.44345C4.85498 0 5.70189 0.949437 6.18478 1.83201C6.50721 2.4204 6.74048 3.10239 6.92324 3.72049C6.97267 3.71659 7.02223 3.7146 7.07182 3.71455H25.6416C26.8748 3.71455 27.7663 4.89428 27.4275 6.08145L24.7115 15.6041C24.4679 16.4582 23.9527 17.2097 23.2439 17.7448C22.535 18.28 21.6711 18.5696 20.7829 18.5698H11.9453C11.0501 18.5698 10.1796 18.2758 9.46774 17.733C8.75584 17.1903 8.2419 16.4287 8.00491 15.5654L6.87569 11.4467L5.00356 5.13499L5.00207 5.1231C4.77029 4.28064 4.55336 3.49167 4.22945 2.90329C3.91891 2.33125 3.66929 2.22873 3.44494 2.22873H2.61436C2.31882 2.22873 2.03537 2.11132 1.82639 1.90234C1.61741 1.69335 1.5 1.40991 1.5 1.11436ZM9.03756 10.9059L10.1534 14.9756C10.3763 15.7809 11.1088 16.341 11.9453 16.341H20.7829C21.1867 16.341 21.5794 16.2095 21.9016 15.9663C22.2239 15.7231 22.4581 15.3816 22.5689 14.9934L25.1498 5.94327H7.56957L9.01675 10.8272L9.03756 10.9059Z" />
                    <path d="M14.1298 23.0302C14.1298 23.8184 13.8167 24.5742 13.2594 25.1315C12.7021 25.6888 11.9463 26.0019 11.1582 26.0019C10.37 26.0019 9.61419 25.6888 9.0569 25.1315C8.49961 24.5742 8.18652 23.8184 8.18652 23.0302C8.18652 22.2421 8.49961 21.4863 9.0569 20.929C9.61419 20.3717 10.37 20.0586 11.1582 20.0586C11.9463 20.0586 12.7021 20.3717 13.2594 20.929C13.8167 21.4863 14.1298 22.2421 14.1298 23.0302ZM11.9011 23.0302C11.9011 22.8332 11.8228 22.6442 11.6835 22.5049C11.5442 22.3656 11.3552 22.2873 11.1582 22.2873C10.9611 22.2873 10.7722 22.3656 10.6328 22.5049C10.4935 22.6442 10.4153 22.8332 10.4153 23.0302C10.4153 23.2273 10.4935 23.4162 10.6328 23.5555C10.7722 23.6949 10.9611 23.7731 11.1582 23.7731C11.3552 23.7731 11.5442 23.6949 11.6835 23.5555C11.8228 23.4162 11.9011 23.2273 11.9011 23.0302Z" />
                    <path d="M24.5302 23.0302C24.5302 23.8184 24.2171 24.5742 23.6598 25.1315C23.1025 25.6888 22.3467 26.0019 21.5586 26.0019C20.7704 26.0019 20.0146 25.6888 19.4573 25.1315C18.9 24.5742 18.5869 23.8184 18.5869 23.0302C18.5869 22.2421 18.9 21.4863 19.4573 20.929C20.0146 20.3717 20.7704 20.0586 21.5586 20.0586C22.3467 20.0586 23.1025 20.3717 23.6598 20.929C24.2171 21.4863 24.5302 22.2421 24.5302 23.0302ZM22.3015 23.0302C22.3015 22.8332 22.2232 22.6442 22.0839 22.5049C21.9445 22.3656 21.7556 22.2873 21.5586 22.2873C21.3615 22.2873 21.1726 22.3656 21.0332 22.5049C20.8939 22.6442 20.8156 22.8332 20.8156 23.0302C20.8156 23.2273 20.8939 23.4162 21.0332 23.5555C21.1726 23.6949 21.3615 23.7731 21.5586 23.7731C21.7556 23.7731 21.9445 23.6949 22.0839 23.5555C22.2232 23.4162 22.3015 23.2273 22.3015 23.0302Z" />
                </g>
                <defs>
                    <clipPath id="clip0_4440_6368">
                        <rect width="30" height="26" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>

        );
    }
}

export default CartIconComponent;
