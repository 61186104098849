import RadioButton from 'Component/RadioButtonIcon';
import { KeyValueTable as SourceKeyValueTable } from 'SourceComponent/KeyValueTable/KeyValueTable.component';

/** @namespace Scandipwa/Component/KeyValueTable/Component/KeyValueTableComponent */
export class KeyValueTableComponent extends SourceKeyValueTable {
    renderTableRow = (data) => {
        const { key, label } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        return (
            <tr key={ key }>
                <th scope="row">{ label }</th>
                <td>{ value }</td>
            </tr>
        );
    };

    renderHeading() {
        const { title, isSelected } = this.props;
        if (!title) {
            return null;
        }

        return (
            <tr>
                <th
                  block="KeyValueTable"
                  elem="Heading"
                  colSpan={ 2 }
                  scope="col"
                >
                    { title }
                    <RadioButton isActive={ isSelected } />
                </th>
            </tr>
        );
    }
}

export default KeyValueTableComponent;
