/* eslint-disable max-len */
import { PureComponent } from 'react';

import './TrashIcon.style';

/** @namespace Scandiweb/MoninTheme/Component/TrashIcon/Component/TrashIconComponent */
export class TrashIconComponent extends PureComponent {
    render() {
        return (
            <svg block="TrashIcon" width="23" height="24" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.42288 9.29999C7.53191 9.29999 7.62933 9.34039 7.69539 9.40145C7.76012 9.4613 7.78826 9.53366 7.78826 9.59999L7.78826 19.2C7.78826 19.2663 7.76012 19.3387 7.69539 19.3985C7.62933 19.4596 7.53191 19.5 7.42288 19.5C7.31385 19.5 7.21642 19.4596 7.15037 19.3985C7.08564 19.3387 7.0575 19.2663 7.0575 19.2L7.0575 9.59999C7.0575 9.53366 7.08564 9.4613 7.15037 9.40145C7.21643 9.34039 7.31385 9.29999 7.42288 9.29999ZM11.7498 9.29999C11.8588 9.29999 11.9563 9.34039 12.0223 9.40145C12.087 9.46129 12.1152 9.53366 12.1152 9.59999L12.1152 19.2C12.1152 19.2663 12.087 19.3387 12.0223 19.3985C11.9563 19.4596 11.8588 19.5 11.7498 19.5C11.6408 19.5 11.5433 19.4596 11.4773 19.3985C11.4126 19.3387 11.3844 19.2663 11.3844 19.2L11.3844 9.59999C11.3844 9.53366 11.4126 9.4613 11.4773 9.40145L11.1396 9.03611L11.4773 9.40145C11.5433 9.34039 11.6408 9.29999 11.7498 9.29999ZM16.3492 9.40145C16.414 9.46129 16.4421 9.53366 16.4421 9.59999V19.2C16.4421 19.2663 16.414 19.3387 16.3492 19.3985C16.2832 19.4596 16.1858 19.5 16.0767 19.5C15.9677 19.5 15.8703 19.4596 15.8042 19.3985C15.7395 19.3387 15.7113 19.2663 15.7113 19.2L15.7113 9.59999C15.7113 9.53366 15.7395 9.46129 15.8042 9.40145C15.8703 9.34039 15.9677 9.29999 16.0767 9.29999C16.1858 9.29999 16.2832 9.34039 16.3492 9.40145Z" fill="none" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23 4.8C23 5.22435 22.8177 5.63131 22.4931 5.93137C22.1685 6.23143 21.7283 6.4 21.2692 6.4L20.4038 6.4L20.4038 20.8C20.4038 21.6487 20.0391 22.4626 19.39 23.0627C18.7408 23.6629 17.8604 24 16.9423 24L6.55769 24C5.63964 24 4.75918 23.6629 4.11001 23.0627C3.46085 22.4626 3.09615 21.6487 3.09615 20.8L3.09615 6.4H2.23077C1.77174 6.4 1.33151 6.23143 1.00693 5.93137C0.682348 5.63131 0.5 5.22435 0.5 4.8L0.5 3.2C0.5 2.77565 0.682348 2.36869 1.00693 2.06863C1.33151 1.76857 1.77174 1.6 2.23077 1.6L8.28846 1.6C8.28846 1.17565 8.47081 0.768687 8.79539 0.468629C9.11997 0.168571 9.5602 0 10.0192 0L13.4808 0C13.9398 0 14.38 0.168571 14.7046 0.468629C15.0292 0.768687 15.2115 1.17565 15.2115 1.6L21.2692 1.6C21.7283 1.6 22.1685 1.76857 22.4931 2.06863C22.8177 2.36869 23 2.77565 23 3.2V4.8ZM5.03115 6.4L4.82692 6.4944L4.82692 20.8C4.82692 21.2243 5.00927 21.6313 5.33385 21.9314C5.65844 22.2314 6.09866 22.4 6.55769 22.4L16.9423 22.4C17.4013 22.4 17.8416 22.2314 18.1661 21.9314C18.4907 21.6313 18.6731 21.2243 18.6731 20.8L18.6731 6.4944L18.4688 6.4L5.03115 6.4ZM2.23077 4.8L2.23077 3.2L21.2692 3.2V4.8L2.23077 4.8Z" fill="none" />
            </svg>
        );
    }
}

export default TrashIconComponent;
