import { PureComponent } from 'react';

import Link from 'Component/Link';
import { MixType } from 'Type/Common';

import { SOCIAL_MEDIA_MAP } from './SocialMediaLinks.config';

/** @namespace Scandiweb/MoninTheme/Component/SocialMediaLinks/Component/SocialMediaLinksComponent */
export class SocialMediaLinksComponent extends PureComponent {
    static propTypes = {
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    renderColumnItemLink = ({ href = '/', title, src }, i) => (
        <Link
          block="SocialMediaLinks"
          elem="LinksItem"
          to={ href }
          key={ i }
          aria-label={ title }
        >
            <img
              block="SocialMediaLinks"
              elem="RowItemImage"
              alt={ title }
              src={ src }
            />
        </Link>
    );

    render() {
        const { mix } = this.props;

        return (
            <div block="SocialMediaLinks" mix={ mix }>
                <div block="SocialMediaLinks" elem="ContentWrapper">
                    { SOCIAL_MEDIA_MAP.map(this.renderColumnItemLink) }
                </div>
            </div>
        );
    }
}

export default SocialMediaLinksComponent;
