/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import CheckoutBilling from '@scandipwa/scandipwa/src/component/CheckoutBilling';
import Loader from '@scandipwa/scandipwa/src/component/Loader';
import React from 'react';

import { INSTANT_PAYMENT_LOCATION_CHECKOUT } from '../component/InstantPayment/InstantPayment.config';
import { BILLING_STEP, INSTANT_PAYPAL_BILLING_STEP } from '../route/Checkout/Checkout.config';

export const renderInstantPayPalStep = (instance) => {
    const {
        email,
        setLoading,
        onEmailChange,
        setDetailsStep,
        shippingAddress,
        isGuestEmailSaved,
        savePaymentInformation,
        updatePrivacyConsentSelection
    } = instance.props;

    const mail = {
        email,
        onEmailChange,
        isGuestEmailSaved
    };

    return (
        <CheckoutBilling
          mailProps={ mail }
          setLoading={ setLoading }
          paymentMethods={ [] }
          setDetailsStep={ setDetailsStep }
          shippingAddress={ shippingAddress }
          savePaymentInformation={ savePaymentInformation }
          updatePrivacyConsentSelection={ updatePrivacyConsentSelection || (() => {}) }
        />
    );
};

// eslint-disable-next-line
export const stepMap = (originalMember, instance) => {
    const self = instance;
    self.renderInstantPayPalStep = renderInstantPayPalStep.bind(self, instance);

    return {
        ...originalMember,
        [INSTANT_PAYPAL_BILLING_STEP]: {
            ...originalMember[BILLING_STEP],
            title: __('PayPal Instant Payment').toString(),
            url: '/billing',
            render: self.renderInstantPayPalStep
        }
    };
};

export class CheckoutComponent {
    renderLoader = (args, cb, instance) => {
        const {
            payPalState: {
                isLoading,
                widgetLocation,
                isActiveInstant
            } = {}
        } = instance.props;

        if (!isActiveInstant || (!widgetLocation || widgetLocation === INSTANT_PAYMENT_LOCATION_CHECKOUT)) {
            return cb(...args);
        }

        return <Loader isLoading={ isLoading } />;
    };
}

const { renderLoader } = new CheckoutComponent();

export default {
    'Route/Checkout/Component': {
        'member-property': {
            stepMap
        },
        'member-function': {
            renderLoader
        }
    }
};
