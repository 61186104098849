import { connect } from 'react-redux';

import {
    FieldSelectContainer
} from 'Component/FieldSelect/FieldSelect.container';

import { CANADA_FR_STORE_CODE, CANADA_STORE_CODE } from '../Header/Header.config';
import ProductActionsDropdownComponent from './ProductActionsDropdown.component';

/** @namespace Scandipwa/Component/ProductActionsDropdown/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentStoreCode: state.ConfigReducer.code
});

/** @namespace Scandipwa/Component/ProductActionsDropdown/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/ProductActionsDropdown/Container/ProductActionsDropdownContainer */
export class ProductActionsDropdownContainer extends FieldSelectContainer {
    containerProps() {
        const { currentStoreCode } = this.props;

        return {
            ...super.containerProps(),
            currentStoreCode
        };
    }

    removeAppearance(node) {
        if (node) {
            node.style.setProperty('appearance', 'none', 'important');
        }
    }

    filterSelectOptions() {
        const { selectOptions } = this.containerProps();
        const { currentStoreCode } = this.props;

        if (currentStoreCode === CANADA_STORE_CODE || currentStoreCode === CANADA_FR_STORE_CODE) {
            return selectOptions.filter(({ stock_status }) => stock_status !== '' && stock_status !== undefined);
        }

        return selectOptions;
    }

    render() {
        const selectOptions = this.filterSelectOptions();

        this.containerFunctions.removeAppearance = this.removeAppearance;

        if (!selectOptions) {
            throw new Error('Prop `selectOptions` is required for Field type `select`');
        }

        return (
            <ProductActionsDropdownComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
              selectOptions={ selectOptions }
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsDropdownContainer);
