/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CATEGORY_WIDGET, Widget } from '../../component/Widgets';
import { DISPLAY_MODE_CMS_BLOCK } from 'SourceRoute/CategoryPage/CategoryPage.config';

const renderClerkWidget = (args, callback, instance) => {
    const {
        category: { display_mode: displayMode = DISPLAY_MODE_CMS_BLOCK }
    } = instance.props;
    const isSearchPage = window.location.pathname.includes('/search');

    if (isSearchPage || displayMode === DISPLAY_MODE_CMS_BLOCK) {
        return callback(...args);
    }

    return (
        <>
            <Widget type={ CATEGORY_WIDGET } />
            { callback(...args) }
        </>
    );
};

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            renderCmsBlock: renderClerkWidget
        }

    }
};
