import CheckoutQuery from 'Query/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import {
    fetchMutation
} from 'Util/Request';

import { PAYFLOWPRO_CODE, URLS } from '../component/PayFlowPro/PayFlowPro.config';
import PayFlowProQuery from '../query/PayFlowPro.query';

const referenceFn = { fn: null };

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const isCustomerSignedIn = isSignedIn();
    const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

    if (code === PAYFLOWPRO_CODE) {
        const {
            cc_details: {
                cc_exp_month,
                cc_exp_year,
                cc_last_4,
                cc_type
            }, is_active_payment_token_enabler,
            cc_details
        } = additional_data;

        const data = {
            cc_details: {
                cc_exp_month,
                cc_last_4,
                cc_exp_year,
                cc_type
            },
            is_active_payment_token_enabler
        };

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [code]: data,
                    purchase_order_number
                }
            }));

            await fetchMutation(PayFlowProQuery.createToken({ cart_id: getGuestQuoteId(), urls: URLS }))
                .then(({ payFlowPro }) => {
                    instance.setState({ payFlowPro: { ...payFlowPro, ...cc_details } });
                });
        } catch (e) {
            instance._handleError(e);
        }
    } else {
        callback(...args);
    }

    if (code === PAYFLOWPRO_CODE) {
        window.document.getElementById('payflow_pro').submit();

        const { fn } = referenceFn;
        if (fn) {
            window.document.getElementById('payflow-target-iframe').removeEventListener('load', fn);
        }

        referenceFn.fn = async (e) => {
            const text = window.document.getElementById('payflow-target-iframe')
                .contentDocument.getElementsByTagName('pre')[0].innerText || '';

            if (text) {
                const res = JSON.parse(text);
                const { RESPMSG = '' } = res;
                if (RESPMSG && !RESPMSG.includes('Declined')) {
                    try {
                        await fetchMutation(PayFlowProQuery.handlePayflowProResponse({
                            cart_id: getGuestQuoteId(),
                            paypal_payload: text
                        }))
                            .then(async () => {
                                const orderData = await fetchMutation(CheckoutQuery
                                    .getPlaceOrderMutation(guest_cart_id));
                                const { placeOrder: { order: { order_id } } } = orderData;

                                instance.setDetailsStep(order_id);
                            });
                    } catch (e) {
                        instance._handleError(e);
                    }
                } else {
                    instance._handleError({ message: RESPMSG });
                }
            }
        };

        window.document.getElementById('payflow-target-iframe').addEventListener('load', referenceFn.fn);
    }
};

const containerProps = (args, callback, instance) => {
    const { payFlowPro = undefined } = instance.state;

    return {
        ...callback(...args),
        payFlowPro
    };
};

function getExpMonth(month) {
    return `${month < 10 ? `0${month}` : month}`;
}

function getMode(url) {
    return url.includes('pilot') ? 'TEST' : 'LIVE';
}

const renderPayFloIframe = (pro) => {
    if (!pro) {
        return null;
    }

    return (
        <>
            <iframe name="payflow-target" id="payflow-target-iframe" title="PayFlowPro" hidden />
            <form id="payflow_pro" action={ pro.form_url } method="post" target="payflow-target">
                <input
                  name="MODE"
                  className="inpPPField"
                  id="hdn_pp_mode"
                  type="hidden"
                  value={ getMode(pro.form_url) }
                />
                <input
                  name="SECURETOKEN"
                  className="inpPPField"
                  id="hdn_pp_securetoken"
                  type="hidden"
                  value={ pro.secure_token }
                />
                <input
                  name="SECURETOKENID"
                  className="inpPPField"
                  id="hdn_pp_securetokenid"
                  type="hidden"
                  value={ pro.secure_token_id }
                />
                <input
                  name="CARDNUM"
                  className="inpPPField"
                  id="hdn_pp_CardNum"
                  type="hidden"
                  value={ pro.cardNumber }
                />
                <input
                  name="EXPMONTH"
                  className="inpPPField"
                  id="hdn_pp_expMonth"
                  type="hidden"
                  value={ getExpMonth(pro.cc_exp_month) }
                />
                <input
                  name="EXPYEAR"
                  className="inpPPField"
                  id="hdn_pp_expYear"
                  type="hidden"
                  value={ `${ pro.cc_exp_year }` }
                />
                <input name="CVV2" className="inpPPField" id="hdn_pp_cvv2" type="hidden" value={ pro.ccv } />
            </form>
        </>
    );
};

const render = (args, callback, instance) => {
    const { payFlowPro = undefined } = instance.props;

    return (
        <>
            { callback(...args) }
            { renderPayFloIframe(payFlowPro) }
        </>
    );
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder,
            containerProps
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            render
        }
    }
};

