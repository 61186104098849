/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Children } from 'react';

import { Slider as SourceSlider } from 'SourceComponent/Slider/Slider.component';

import {
    LEFT, RIGHT, SLIDER_CHEVRON_ICON_COLOR, SR_ONLY_TEXT
} from './Slider.config';

import './Slider.style';

/**
 * Slider component
 * @class Slider
 * @namespace Scandipwa/Component/Slider/Component/SliderComponent */
export class SliderComponent extends SourceSlider {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const { activeImage: prevActiveImage } = prevProps;
        const { activeImage, isArchive } = this.props;

        if (isArchive && activeImage !== prevActiveImage && this.getIsSlider()) {
            const { current: slider } = this.getSliderRef();

            slider.scroll(0, 0);
        }
    }

    renderSliderChevronIcon(direction) {
        return (
            <svg
              block="Slider"
              elem="ChevronIcon"
              mods={ { direction } }
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
                <g clipPath="url(#clip0_7560_50365)">
                    <path
                      d="M28
                      1C13.0883
                      1
                      0.999996
                      13.0883
                      0.999998
                      28C0.999999
                      42.9117
                      13.0883
                      55
                      28
                      55C42.9117
                      55
                      55
                      42.9117
                      55 28C55
                      13.0883
                      42.9117
                      1
                      28
                      1Z"
                      stroke={ SLIDER_CHEVRON_ICON_COLOR }
                      strokeWidth="2"
                    />
                    <path
                      d="M25.9748
                      35.0879L33.0623
                      28.0004L25.9748
                      20.9129"
                      stroke={ SLIDER_CHEVRON_ICON_COLOR }
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_7560_50365">
                        <rect width="56" height="56" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }

    renderCrumb(_, i) {
        const { activeImage } = this.props;
        const isActive = i === Math.abs(-activeImage);

        return (
            <li>
                <button
                  block="Slider"
                  elem="Image"
                  mods={ { type: 'single' } }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => this.changeActiveImage(i) }
                  aria-label={ `${__('Go to slide')} ${i + 1}` }
                  aria-current={ isActive }
                >
                    <div
                      block="Slider"
                      elem="Crumb"
                      mods={ { isActive } }
                    />
                </button>
            </li>
        );
    }

    renderCrumbs() {
        const { children, showCrumbs, slideCount } = this.props;

        if (!showCrumbs || children.length <= 1) {
            return null;
        }

        if (slideCount) {
            return (
                <ul
                  block="Slider"
                  elem="Crumbs"
                >
                    { children.slice(0, slideCount).map(
                        (childrenList, index) => this.renderCrumb(childrenList, index)
                    ) }
                </ul>
            );
        }

        return (
            <ul
              block="Slider"
              elem="Crumbs"
            >
                { Children.map(children, this.renderCrumb) }
            </ul>
        );
    }

    goPrev() {
        const { activeImage, children } = this.props;
        const prevImage = activeImage === 0 ? children.length - 1 : activeImage - 1;

        this.changeActiveImage(prevImage);
    }

    goNext() {
        const { activeImage, children } = this.props;
        const nextImage = activeImage + 1 === children.length ? 0 : activeImage + 1;

        this.changeActiveImage(nextImage);
    }

    renderLeftArrow() {
        const { showArrows, leftArrowRef } = this.props;

        if (!showArrows) {
            return null;
        }

        return (
            <button
              block="Slider"
              elem="Arrow"
              ref={ leftArrowRef }
              mods={ { isPrev: true } }
              aria-label={ __('Previous').toString() }
              onClick={ this.goPrev }
            >
                { this.renderSliderChevronIcon(LEFT) }
            </button>
        );
    }

    renderRightArrow() {
        const { showArrows } = this.props;

        if (!showArrows) {
            return null;
        }

        return (
            <button
              block="Slider"
              elem="Arrow"
              mods={ { isNext: true } }
              aria-label={ __('Next').toString() }
              onClick={ this.goNext }
            >
                { this.renderSliderChevronIcon(RIGHT) }
            </button>
        );
    }

    renderPauseButton() {
        const { paused, changeSliderState, sliderWidget } = this.props;

        if (!sliderWidget) {
            return null;
        }

        return (
            <button
              block="SliderWidget"
              elem="Controller"
              onClick={ changeSliderState }
              aria-label={ paused ? __('Play').toString() : __('Pause').toString() }
              tabIndex="0"
            >
                { paused ? __('PLAY').toString() : __('PAUSE').toString() }
            </button>
        );
    }

    renderScreenReaderText() {
        const { sliderWidget } = this.props;

        if (!sliderWidget) {
            return null;
        }

        return (
            <p className="sr-only">
                { SR_ONLY_TEXT }
            </p>
        );
    }

    render() {
        const { mix, regionDeclared, ariaLabelDeclared } = this.props;

        return (
            <div
              block="Slider"
              mix={ mix }
              ref={ this.getSliderRef() }
              role={ !regionDeclared ? 'region' : undefined }
              aria-label={ !ariaLabelDeclared ? __('Carousel').toString() : undefined }
            >
                { this.renderScreenReaderText() }
                { this.renderPauseButton() }
                { this.renderLeftArrow() }
                { this.renderSliderContent() }
                { this.renderRightArrow() }
                { this.renderCrumbs() }
            </div>
        );
    }
}

export default SliderComponent;
