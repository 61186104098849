import {
    CheckoutOrderSummaryComponent as MoninCheckoutOrderSummaryComponent
} from 'MoninComponent/CheckoutOrderSummary/CheckoutOrderSummary.component';

import './CheckoutOrderSummary.extend.style.scss';

/** @namespace Scandipwa/Component/CheckoutOrderSummary/Component/CheckoutOrderSummaryComponent */
export class CheckoutOrderSummaryComponent extends MoninCheckoutOrderSummaryComponent {
    // .svg file
    PAYMENT_METHOD_IMAGES = ['visa', 'mastercard', 'discovernetwork', 'americanexpress'];

    renderPaymentLogo(paymentMethod) {
        return (
            <li>
                <img src={ `/media/wysiwyg/images/${paymentMethod}.svg` } alt={ paymentMethod } />
            </li>
        );
    }

    renderPaymentLogos() {
        return (
            <ul block="CheckoutOrderSummary" elem="PaymentLogos">
                { this.PAYMENT_METHOD_IMAGES.map(
                    (paymentLogo) => this.renderPaymentLogo(paymentLogo)
                ) }
            </ul>
        );
    }

    /**
     * Different bottom summary section layouts for desktop and mobile.
     */
    renderBottomSummary() {
        const { children: [coupon, checkoutButton], device: { isMobile } } = this.props;

        if (isMobile) {
            return (
                <>
                    { this.renderOrderTotal() }
                    { coupon }
                    { checkoutButton }
                    { this.renderPaymentLogos() }
                </>
            );
        }

        return (
            <>
                { coupon }
                <div block="CheckoutOrderSummary" elem="BottomWrapper">
                    { this.renderOrderTotal() }
                    { checkoutButton }
                </div>
                { this.renderPaymentLogos() }
            </>
        );
    }

    renderHeading() {
        return (
            <div
              block="CheckoutOrderSummary"
              elem="Header"
              mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }
            >
                <h2>{ __('Summary').toString() }</h2>
            </div>
        );
    }
}

export default CheckoutOrderSummaryComponent;
