// import americanexpressIcon from 'Style/icons/logos/americanexpress.svg';
// import applepayIcon from 'Style/icons/logos/applepay.svg';
// import discovernetworkIcon from 'Style/icons/logos/discovernetwork.svg';
// import facebookIcon from 'Style/icons/logos/facebook.svg';
// import instagramIcon from 'Style/icons/logos/instagram.svg';
// import linkedinIcon from 'Style/icons/logos/linkedIn.svg';
// import mastercardIcon from 'Style/icons/logos/mastercard.svg';
// import paypalIcon from 'Style/icons/logos/paypal.svg';
// import pinterestIcon from 'Style/icons/logos/pinterest.svg';
// import twitterIcon from 'Style/icons/logos/twitter.svg';
// import visaIcon from 'Style/icons/logos/visa.svg';
// import youtubeIcon from 'Style/icons/logos/youtube.svg';

export * from 'SourceComponent/Footer/Footer.config.js';

// eslint-disable-next-line import/prefer-default-export
export const LINK_MAP = [
    {
        items: [
            {
                href: '/catalogsearch/advanced',
                title: __('ADVANCED SEARCH').toString()
            },
            {
                href: '/about-us',
                title: __('CONTACT US').toString()
            },
            {
                href: '/contactus/blog',
                title: __('COLLABORATIONS').toString()
            }
        ]
    },
    {
        items: [
            {
                href: '/tracking',
                title: __('TRACK MY ORDER').toString()
            },
            {
                href: '/shipping-information',
                title: __('SHIPPING INFORMATION').toString()
            },
            {
                href: '/monin-reviews',
                title: __('REVIEWS').toString()
            }
        ]
    },
    {
        items: [
            {
                href: '/women',
                title: __('RETURN POLICY').toString()
            },
            {
                href: '/faq',
                title: __('FAQ').toString()
            },
            {
                href: '/list',
                title: __('CAREERS').toString()
            },
            {
                href: '/htmlsitemap',
                title: __('SITEMAP').toString()
            }
        ]
    }
];

export const SOCIAL_ICONS_MAP = [
    {
        isItemsHorizontal: true,
        items: [
            {
                href: 'https://facebook.com/MoninUSA',
                // src: facebookIcon,
                title: __('Facebook').toString()
            },
            {
                href: 'http://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=MoninUSA',
                // src: twitterIcon,
                title: __('Twitter').toString()
            },
            {
                href: 'https://pinterest.com/moninUSA',
                // src: pinterestIcon,
                title: __('Pinterest').toString()
            },
            {
                href: 'https://instagram.com/monin_usa',
                // src: instagramIcon,
                title: __('Instagram').toString()
            },
            {
                href: 'https://linkedin.com/company/monin-americas',
                // src: linkedinIcon,
                title: __('Linkedin').toString()
            },
            {
                href: 'http://www.youtube.com/user/MONIN1912?sub_confirmation=1',
                // src: youtubeIcon,
                title: __('Youtube').toString()
            }
        ]
    }
];

export const CARD_MERCHANT_MAP = [
    {
        isItemsHorizontal: true,
        mods: { second: true },
        items: [
            {
                // src: visaIcon,
                title: __('VISA').toString()
            },
            {
                // src: mastercardIcon,
                title: __('Master Card').toString()
            },
            {
                // src: applepayIcon,
                title: __('Apple Pay').toString()
            },
            {
                // src: discovernetworkIcon,
                title: __('Discover Network').toString()
            },
            {
                // src: paypalIcon,
                title: __('PayPal').toString()
            },
            {
                // src: americanexpressIcon,
                title: __('American Express').toString()
            }
        ]
    }
];
export const CURRENT_STORE = {
    // eslint-disable-next-line no-magic-numbers
    store: window.location.href.split('/')[3] ? window.location.href.split('/')[3] : 'us'
};

export const US_TEXT = 'JOIN TO THE FLAVOR FAMILY';
export const CANADA_STORE_CODE = 'ca';
export const US_STORE_CODE = 'us';
export const EN_CONTACTUS = 'CONTACT US';
export const FR_CONTACTUS = 'NOUS CONTACTER';
export const SPA_CONTACTUS = 'CONTÁCTANOS';
