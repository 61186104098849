/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import { fetchMutation } from 'Util/Request';

import PrivacyInformationQuery from '../query/PrivacyInformation.query';
import { formatConsents } from '../util/Privacy';

export const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const { privacyConsentSelection } = instance.state;
    callback.apply(instance, args);

    const formattedConsentSelection = formatConsents(privacyConsentSelection);

    const query = PrivacyInformationQuery.getSaveConsentSettingsMutation(formattedConsentSelection, 'checkout');
    await fetchMutation(query);
};

export const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    updatePrivacyConsentSelection: (selection) => {
        instance.setState({
            privacyConsentSelection: selection
        });
    }
});

export const createUserOrSaveGuest = async (args, callback, instance) => {
    const {
        createAccount,
        totals: { is_virtual },
        showSuccessNotification,
        isEmailAvailable
    } = instance.props;

    const {
        privacyConsentSelection
    } = instance.state;

    const {
        email,
        password,
        isCreateUser,
        shippingAddress: {
            firstname,
            lastname
        }
    } = instance.state;

    if (!isCreateUser || !isEmailAvailable) {
        return instance.saveGuestEmail();
    }

    const formattedConsentSelection = formatConsents(privacyConsentSelection);

    const options = {
        customer: {
            email,
            firstname,
            lastname,
            privacyConsentSelection: formattedConsentSelection
        },
        password
    };

    const creation = await createAccount(options);

    if (!creation) {
        return creation;
    }

    showSuccessNotification(__('Your account has been created successfully!').toString());

    if (!is_virtual) {
        return instance.setShippingAddress();
    }

    return true;
};

// eslint-disable-next-line
export const renderBillingStep = (args, callback, instance) => {
    const { updatePrivacyConsentSelection } = instance.props;
    const original = callback.apply(instance, args);

    // eslint-disable-next-line no-undef
    return React.cloneElement(
        original.props.children,
        { updatePrivacyConsentSelection }
    );
};

export const renderShippingStep = (args, callback, instance) => {
    const {
        isCreateUser,
        privacyConsentSelection: {
            privacy_checkbox = false
        } = {}
    } = instance.props;
    const original = callback.apply(instance, args);

    const isUserConsentGiven = isCreateUser ? privacy_checkbox : true;

    // eslint-disable-next-line no-undef
    return React.cloneElement(
        original.props.children,
        { isUserConsentGiven }
    );
};

export const renderGuestForm = (args, callback, instance) => {
    const { updatePrivacyConsentSelection } = instance.props;
    const original = callback.apply(instance, args);

    // eslint-disable-next-line no-undef
    return React.cloneElement(
        original,
        { updatePrivacyConsentSelection },
        original.props.children
    );
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder,
            createUserOrSaveGuest
        },
        'member-property': {
            containerFunctions
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderBillingStep,
            renderGuestForm,
            renderShippingStep
        }
    }
};
