import { formatCurrency } from 'SourceUtil/Price/Price';
import getStore from 'Util/Store';

export const CA = 'ca';
export const FR_CA = 'fr_ca';
export const US = 'USD';

/** @namespace Scandipwa/Util/Manipulations/Price/formatPrice */
export const formatPrice = (price, currency = 'US') => {
    const { ConfigReducer: { code = null } = {} } = getStore().getState() || {};

    if (code === CA || code === FR_CA) {
        return `${formatCurrency(US)}${parseFloat(price).toFixed(2)}`;
    }

    return `${formatCurrency(currency)}${parseFloat(price).toFixed(2)}`;
};
