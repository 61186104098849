import PropTypes from 'prop-types';

import GroupIcon from 'Component/GroupIcon';
import ProductWriteReviewButton from 'Component/ProductWriteReviewButton';
import { PRODUCT_REVIEWS } from 'Route/ProductPage/ProductPage.config';
import { ProductTabs as SourceProductTabs } from 'SourceComponent/ProductTabs/ProductTabs.component';

import './ProductTabs.override.style';

/** @namespace Scandiweb/MoninTheme/Component/ProductTabs/Component/ProductTabsComponent */
export class ProductTabsComponent extends SourceProductTabs {
    static propTypes = {
        ...this.propTypes,
        isMobile: PropTypes.bool.isRequired,
        getTabNameByKey: PropTypes.func.isRequired,
        tabNameToExpand: PropTypes.string.isRequired,
        isScrollToggled: PropTypes.bool.isRequired,
        setProductTabsRef: PropTypes.func.isRequired,
        scrollToProductTab: PropTypes.func.isRequired,
        handleOnProductTabFormButtonClick: PropTypes.func.isRequired
    };

    noop = () => {};

    onTabClick = (tab, isScrollable = false) => {
        const { tabs, scrollToProductTab } = this.props;

        const currentTab = tabs.findIndex(({ name }) => name === tab);

        this.setState(
            { activeTab: currentTab },
            isScrollable ? scrollToProductTab : this.noop
        );
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        const { isScrollToggled: prevIsScrollToggled } = prevProps;
        const { isScrollToggled, tabNameToExpand } = this.props;

        if (isScrollToggled !== prevIsScrollToggled) {
            this.onTabClick(tabNameToExpand, true);
        }
    }

    /**
     * Can't move the method to the container, since it utilizes the core 'onTabClick' method that resides in the parent component.
     */
    handleOnWriteReviewClick = () => {
        const { getTabNameByKey, handleOnProductTabFormButtonClick } = this.props;

        const tabName = getTabNameByKey(PRODUCT_REVIEWS);

        if (!tabName) {
            return;
        }

        this.onTabClick(tabName);
        handleOnProductTabFormButtonClick(PRODUCT_REVIEWS)();
    };

    renderButtons() {
        const { isMobile } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <div block="ProductTabs" elem="ButtonsWrapper">
                <ProductWriteReviewButton
                  onClick={ this.handleOnWriteReviewClick }
                />
                <button block="Button" mods={ { likeLink: true } }>
                    <GroupIcon />
                    { __('Ask a question') }
                </button>
            </div>
        );
    }

    /**
     * Overridden to add write review and ask question buttons
     */
    renderTabs() {
        const { tabs, setProductTabsRef } = this.props;

        return (
            <>
                <ul block="ProductTabs" ref={ setProductTabsRef }>
                    { tabs.map(this.renderTab) }
                    { this.renderButtons() }
                </ul>
                { this.renderActiveTab() }
            </>
        );
    }
}

export default ProductTabsComponent;
