/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    resetReviewsFilter,
    setIsApplyingFilters,
    setReviewLoad,
    setReviewsFilter,
    setReviewsFilterApplied
} from '../../store/YotpoReviews/YotpoReviews.action';
import { adjustSearchFilter } from '../../util/Yotpo/Yotpo';
import {
    modifyStateToReduxValue,
    REDUX_STATE_FILTERS_MAP,
    STATE_FILTERS_TYPE,
    STATE_REDUX_FILTERS_MAP
} from '../YotpoReviewsFilter/YotpoReviewsFilter.config';
import YotpoPDPReviewsMoreFiltersPopup from './YotpoPDPReviewsMoreFiltersPopup.component';

export const YotpoReviewsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/YotpoReviews/YotpoReviews.dispatcher'
);

/** @namespace YotpoReviewsSpwa5/Component/YotpoPDPReviewsMoreFiltersPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    reviewsFilter: state.YotpoReviewsReducer.reviewsFilter,
    totalReview: state.YotpoReviewsReducer.totalReview,
    isLoadingTotalReview: state.YotpoReviewsReducer.isLoadingTotalReview
});

/** @namespace YotpoReviewsSpwa5/Component/YotpoPDPReviewsMoreFiltersPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setReviewsFilter: (filter) => dispatch(setReviewsFilter(filter)),
    getReviewsByCondition: (filter, isFetchingTotalOnly) => YotpoReviewsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getReviewsByCondition(dispatch, filter, false, isFetchingTotalOnly)
    ),
    setReviewsLoadingStatus: (isLoading) => dispatch(setReviewLoad(isLoading)),
    setReviewsFilterApplied: (filterStatus) => dispatch(setReviewsFilterApplied(filterStatus)),
    resetReviewsFilter: () => dispatch(resetReviewsFilter()),
    setIsApplyingFilters: (isApplyingFilters) => dispatch(setIsApplyingFilters(isApplyingFilters))
});

/** @namespace YotpoReviewsSpwa5/Component/YotpoPDPReviewsMoreFiltersPopup/Container/YotpoPDPReviewsMoreFiltersPopupContainer */
export class YotpoPDPReviewsMoreFiltersPopupContainer extends PureComponent {
    static propTypes = {
        totalReview: PropTypes.number.isRequired,
        toggleMoreFiltersPopup: PropTypes.func.isRequired,
        reviewsFilter: PropTypes.shape({
            freeTextSearch: PropTypes.string,
            perPage: PropTypes.number,
            pictured: PropTypes.bool,
            productId: PropTypes.number,
            scores: PropTypes.arrayOf(PropTypes.number),
            topics: PropTypes.arrayOf(PropTypes.string),
            page: PropTypes.number
        }).isRequired,
        setReviewsFilter: PropTypes.func.isRequired,
        getReviewsByCondition: PropTypes.func.isRequired,
        setReviewsFilterApplied: PropTypes.func.isRequired,
        setReviewsLoadingStatus: PropTypes.func.isRequired,
        isLoadingTotalReview: PropTypes.bool,
        resetReviewsFilter: PropTypes.func.isRequired,
        setIsApplyingFilters: PropTypes.func.isRequired,
        isApplyingFilters: PropTypes.bool
    };

    static defaultProps = {
        isLoadingTotalReview: false,
        isApplyingFilters: false
    };

    state = {
        isStatic: true,
        scoresToFilter: ['all'],
        isImagesAndVideosOnlySelectedOption: 'all'
    };

    containerFunctions = {
        closePopup: this.closePopup.bind(this),
        handleClickScoreFilterOption: this.handleClickScoreFilterOption.bind(this),
        handleClickOnlyImagesAndVideosIncludedFilterOption:
        this.handleClickOnlyImagesAndVideosIncludedFilterOption.bind(this),
        handleFetchReviews: this.handleFetchReviews.bind(this),
        handleResetReviewsFilter: this.handleResetReviewsFilter.bind(this),
        clearFreeTextSearch: this.clearFreeTextSearch.bind(this)
    };

    componentDidMount() {
        this.REDUX_STATE_FILTERS_MAP = REDUX_STATE_FILTERS_MAP;
    }

    containerProps() {
        const {
            isStatic, isImagesAndVideosOnlySelectedOption, scoresToFilter
        } = this.state;
        const {
            totalReview, isLoadingTotalReview, reviewsFilter: {
                pictured, freeTextSearch
            },
            resetReviewsFilter
        } = this.props;

        return {
            isStatic,
            pictured,
            scoresToFilter,
            totalReview,
            isImagesAndVideosOnlySelectedOption,
            isLoadingTotalReview,
            resetReviewsFilter,
            freeTextSearch
        };
    }

    closePopup() {
        const { toggleMoreFiltersPopup, resetReviewsFilter } = this.props;

        resetReviewsFilter();
        this.setState({ isStatic: false });
        toggleMoreFiltersPopup();
    }

    handleResetReviewsFilter() {
        const { resetReviewsFilter } = this.props;

        resetReviewsFilter();
        this.handleSetIsApplyingFilters(false);
        this.handleFetchTotalReview();
        this.setState({
            scoresToFilter: ['all'],
            isImagesAndVideosOnlySelectedOption: 'all'
        });
    }

    getAdjustedReviewsFilter(stateName, stateValue) {
        const { reviewsFilter } = this.props;

        const adjustedReviewsFilter = adjustSearchFilter(reviewsFilter, {
            [STATE_REDUX_FILTERS_MAP[stateName]]:
            modifyStateToReduxValue(stateName, stateValue)
        });

        return adjustedReviewsFilter;
    }

    handleFetchTotalReview(searchFilter) {
        const { getReviewsByCondition, reviewsFilter } = this.props;

        getReviewsByCondition((searchFilter || reviewsFilter), true);
    }

    handleSetFilter(stateName, stateValue) {
        const { setReviewsFilter } = this.props;

        this.setState({ [stateName]: stateValue }, () => {
            const searchFilter = this.getAdjustedReviewsFilter(stateName, stateValue);

            setReviewsFilter(searchFilter);
            this.handleSetIsApplyingFilters();
            this.handleFetchTotalReview(searchFilter);
        });
    }

    handleSetIsApplyingFilters(isApplyingFilters) {
        const { setReviewsFilterApplied } = this.props;

        this.setIsApplyingFilters(isApplyingFilters);
        setReviewsFilterApplied(false);
    }

    handleFetchReviews() {
        const {
            getReviewsByCondition,
            setReviewsLoadingStatus,
            setReviewsFilterApplied,
            reviewsFilter,
            resetReviewsFilter
        } = this.props;

        resetReviewsFilter();
        this.handleSetIsApplyingFilters(false);
        setReviewsFilterApplied(true);
        setReviewsLoadingStatus(true);
        getReviewsByCondition(reviewsFilter, false);
        this.closePopup();
    }

    handleClickScoreFilterOption(e) {
        const { value: valueToSet } = e.target;
        const { scoresToFilter: stateValue } = this.state;
        const newStateValue = [];
        const index = stateValue.indexOf(valueToSet);

        if (valueToSet === 'all') {
            newStateValue.push(valueToSet);
            this.handleSetFilter('scoresToFilter', newStateValue);

            return;
        }

        newStateValue.push(...stateValue);

        if (index === -1) {
            const allValueIndex = newStateValue.indexOf('all');

            if (allValueIndex !== -1) {
                newStateValue.splice(allValueIndex, 1);
            }

            newStateValue.push(valueToSet);
        } else {
            newStateValue.splice(index, 1);
        }

        this.handleSetFilter('scoresToFilter', newStateValue);
    }

    setIsApplyingFilters(isApplyingFilters) {
        const { setIsApplyingFilters } = this.props;

        if (isApplyingFilters) {
            setIsApplyingFilters(isApplyingFilters);

            return;
        }

        const isApplyingFiltersToSet = STATE_FILTERS_TYPE.some((stateName) => {
            const { [stateName]: stateValue } = this.state;

            if (Array.isArray(stateValue)) {
                const isApplyingFilters = Boolean(stateValue?.length);

                return isApplyingFilters;
            }

            const isApplyingFilters = Boolean(stateValue);

            return isApplyingFilters;
        });

        setIsApplyingFilters(isApplyingFiltersToSet);
    }

    handleClickOnlyImagesAndVideosIncludedFilterOption(e) {
        const { value } = e.target;

        const booleanValue = value === 'with-images-and-videos-only';

        this.handleSetFilter('pictured', booleanValue);
        this.setState({ isImagesAndVideosOnlySelectedOption: value });
    }

    clearFreeTextSearch() {
        this.handleSetFilter('freeTextSearch', '');
    }

    render() {
        return (
            <YotpoPDPReviewsMoreFiltersPopup
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(YotpoPDPReviewsMoreFiltersPopupContainer);
