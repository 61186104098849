/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import Form from 'Component/Form';
import { ProductAlerts as SourceProductAlerts } from 'SourceComponent/ProductAlerts/ProductAlerts.component';
import { StockStatusType } from 'Type/StockStatus';

import './ProductAlerts.style';

/** @namespace Scandipwa/Component/ProductAlerts/Component/ProductAlertsComponent */
export class ProductAlertsComponent extends SourceProductAlerts {
    static propTypes = {
        handlePriceDropSubscribeAlertPriceDrop: PropTypes.func.isRequired,
        handlePriceDropSubscribeAlertInStock: PropTypes.func.isRequired,
        isInStockAlertEnabled: PropTypes.bool,
        isPriceAlertEnabled: PropTypes.bool,
        stockStatus: StockStatusType,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isInStockAlertEnabled: false,
        isPriceAlertEnabled: false,
        stockStatus: null,
        selectedVariant: null
    };

    renderPriceDropSubscribeButton() {
        const { handlePriceDropSubscribeAlertPriceDrop, isPriceAlertEnabled } = this.props;

        if (!isPriceAlertEnabled) {
            return null;
        }

        return (
            <button
              block="ProductAlerts"
              elem="PriceDrop"
              onClick={ handlePriceDropSubscribeAlertPriceDrop }
            >
                { __('Notify me when the price drops').toString() }
            </button>
        );
    }

    renderActions() {
        return (
                <button
                  block="ProductAlerts"
                  elem="Button"
                  type="submit"
                  mods={ { isHollow: false } }
                >
                        { __('Notify me').toString() }
                </button>
        );
    }

    /* Add notification on mobile */
    renderMobileNotification(status) {
        const { isMobile, errorMessage, successMessage } = this.props;

        if (!isMobile || status === null) {
            return null;
        }

        return (
            <p
              block="ProductAlerts"
              elem="Notification"
              aria-label={ __('Notification message').toString() }
              mods={ { isSuccess: status } }
            >
                { status ? __(successMessage).toString() : __(errorMessage).toString() }
            </p>
        );
    }

    renderInStockSubscribeButton() {
        const {
            handlePriceDropSubscribeAlertInStock,
            isInStockAlertEnabled,
            selectedVariant,
            isSignedIn,
            guestEmail,
            setGuestEmail
        } = this.props;

        if (!isInStockAlertEnabled || selectedVariant.stock_status === 'IN_STOCK') {
            return null;
        }

        if (!isSignedIn) {
            return (
                <Form
                  onSubmitSuccess={ handlePriceDropSubscribeAlertInStock }
                  mix={ { block: 'ProductAlertsForm' } }
                  id="productStockAlertForm"
                >
                    <Field
                      type="email"
                      label={ __('Email me when the product is back in stock').toString() }
                      id="productAlertField"
                      name="email"
                      aria-label={ __('Email address').toString() }
                      placeholder={ __('Your email address').toString() }
                      autocomplete="email"
                      validation={ ['notEmpty', 'email'] }
                      value={ guestEmail }
                      onChange={ setGuestEmail }
                    />
                    { this.renderActions() }
                </Form>
            );
        }

        return (
            <button
              block="ProductAlerts"
              elem="InStock"
              onClick={ handlePriceDropSubscribeAlertInStock }
            >
                { __('Email me when the product is back in stock').toString() }
            </button>
        );
    }

    render() {
        const { status } = this.props;
        return (
            <div
              block="ProductAlerts"
              elem="Wrapper"
            >
                { this.renderPriceDropSubscribeButton() }
                { this.renderInStockSubscribeButton() }
                { this.renderMobileNotification(status) }
            </div>
        );
    }
}

export default ProductAlertsComponent;
