/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

export const GUEST_QUOTE_ID = 'guest_quote_id';

export const STOREVIEWS = {
    default: false,
    us: 'en',
    spa: 'spa',
    ca: 'ca',
    ca_fr: 'ca',
    fr: 'ca',
    fr_ca: 'ca',
    'ca-fr': 'ca',
    'fr-ca': 'ca'
};

/** @namespace Scandipwa/Util/Cart/Token/getStoreCode */
export const getStoreCode = () => {
    const { location: { pathname } } = window;
    const parts = pathname.split('/');

    if (parts.length > 1 && STOREVIEWS[parts[1]]) {
        return STOREVIEWS[parts[1]];
    }

    return STOREVIEWS.default;
};

/** @namespace Scandipwa/Util/Cart/Token/setGuestQuoteId */
export const setGuestQuoteId = (token) => {
    const data = {
        token,
        isCustomerToken: isSignedIn()
    };

    const storeCode = getStoreCode();
    if (storeCode) {
        BrowserDatabase.setItem(data, `${storeCode}_${GUEST_QUOTE_ID}`);
    }

    BrowserDatabase.setItem(data, GUEST_QUOTE_ID);
};

/** @namespace Scandipwa/Util/Cart/Token/getGuestQuoteId */
export const getGuestQuoteId = () => {
    const storeCode = getStoreCode();
    const path = storeCode ? `${storeCode}_${GUEST_QUOTE_ID}` : GUEST_QUOTE_ID;

    const {
        token,
        isCustomerToken
    } = BrowserDatabase.getItem(path) || {};

    if (isCustomerToken && !isSignedIn()) {
        return null;
    }

    return token;
};

/** @namespace Scandipwa/Util/Cart/Token/deleteGuestQuoteId */
export const deleteGuestQuoteId = () => {
    const storeCode = getStoreCode();
    if (storeCode) {
        BrowserDatabase.deleteItem(`${storeCode}_${GUEST_QUOTE_ID}`);
    }
    BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
};
