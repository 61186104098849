/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author  Vladlens Starovoitovs <info@scandiweb.com>
 * @author  Zintis Scerbakovs <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation, fetchQuery } from 'Util/Request';

import PrivacyInformationQuery from '../../query/PrivacyInformation.query';
import { formatConsents } from '../../util/Privacy';
import FirstVisitPrivacyPolicyPopupComponent from './FirstVisitPrivacyPolicyPopup.component';
import { COOKIES_ACCEPTED } from './FirstVisitPrivacyPolicyPopup.config';

/** @namespace Gdpr/Component/FirstVisitPrivacyPolicyPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Gdpr/Component/FirstVisitPrivacyPolicyPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Gdpr/Component/FirstVisitPrivacyPolicyPopup/Container/FirstVisitPrivacyPolicyPopupContainer */
export class FirstVisitPrivacyPolicyPopupContainer extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    containerFunctions = {
        acceptCookies: this.acceptCookies.bind(this),
        handleClosePopup: this.handleClosePopup.bind(this)
    };

    componentDidMount() {
        this.requestPrivacyInformation();
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            privacyPolicy: '',
            isLoading: true,
            isPopupEnabled: false,
            isStatic: true
        };
    }

    containerProps = () => {
        const {
            privacyPolicy, isPopupEnabled, isLoading, isDisabled
        } = this.state;

        return ({
            privacyPolicy,
            isPopupEnabled,
            isLoading,
            isDisabled
        });
    };

    handleClosePopup() {
        const { hideActiveOverlay } = this.props;

        this.setState({ isStatic: false });
        hideActiveOverlay();
    }

    async acceptCookies() {
        const { showNotification, hideActiveOverlay } = this.props;

        const formattedConsentSelection = formatConsents({ privacy_checkbox: true });
        const query = PrivacyInformationQuery.getSaveConsentSettingsMutation(formattedConsentSelection, 'privacy_policy_popup');
        const response = await fetchMutation(query);

        if (response.updateConsents) {
            // eslint-disable-next-line no-magic-numbers
            BrowserDatabase.setItem(true, COOKIES_ACCEPTED, 1200);
            this.setState({ isStatic: false });
            hideActiveOverlay();
            showNotification('success', __('Saved consents!').toString());
        }
    }

    requestPrivacyInformation() {
        this.setState(
            { isLoading: true },
            () => {
                fetchQuery(PrivacyInformationQuery.getQuery())
                    .then(
                        /** @namespace Gdpr/Component/FirstVisitPrivacyPolicyPopup/Container/fetchQuery/then */
                        ({ getPrivacyInformation: { consents, privacyPolicy, isPopupEnabled } }) => {
                            if (consents === null) {
                                this.setState({
                                    isLoading: false,
                                    isDisabled: true
                                });
                            } else {
                                this.setState({
                                    isLoading: false,
                                    privacyPolicy,
                                    isPopupEnabled
                                });
                            }
                        },
                        /** @namespace Gdpr/Component/FirstVisitPrivacyPolicyPopup/Container/fetchQuery/then */
                        (e) => console.error(e)
                    );
            }
        );
    }

    render() {
        const { isPopupEnabled, isStatic } = this.state;

        if (!isPopupEnabled) {
            return null;
        }

        return (
            <FirstVisitPrivacyPolicyPopupComponent
              isStatic={ isStatic }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstVisitPrivacyPolicyPopupContainer);
