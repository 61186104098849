/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const getWishlistProduct = (args, callback, instance) => {
    const { product: { categories = [] } } = instance.props;
    const result = callback(...args);

    return { ...result, categories };
};

export default {
    'Component/WishlistItem/Component': {
        'member-function': {
            getWishlistProduct
        }
    }
};
