/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import CartCoupon from 'Component/CartCoupon';
import {
    CheckoutOrderSummary as SourceCheckoutOrderSummary
} from 'SourceComponent/CheckoutOrderSummary/CheckoutOrderSummary.component';
import { DeviceType } from 'Type/Device';

import './CheckoutOrderSummary.override.style';

/** @namespace Scandiweb/MoninTheme/Component/CheckoutOrderSummary/Component/CheckoutOrderSummaryComponent */
export class CheckoutOrderSummaryComponent extends SourceCheckoutOrderSummary {
    static propTypes = {
        ...this.propTypes,
        device: DeviceType.isRequired
    };

    renderDiscountCode() {
        const {
            totals: { coupon_code, items }
        } = this.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <CartCoupon couponCode={ coupon_code } />
        );
    }

    /**
     * Overridden to change heading to h3
     */
    renderHeading() {
        return (
            <div
              block="CheckoutOrderSummary"
              elem="Header"
              mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }
            >
                <h3>{ __('Summary') }</h3>
            </div>
        );
    }

    /**
     * Different bottom summary section layouts for desktop and mobile.
     */
    renderBottomSummary() {
        const { children: [coupon, checkoutButton], device: { isMobile } } = this.props;

        if (isMobile) {
            return (
                <>
                    { this.renderOrderTotal() }
                    { coupon }
                    { checkoutButton }
                </>
            );
        }

        return (
            <>
                { coupon }
                <div block="CheckoutOrderSummary" elem="BottomWrapper">
                    { this.renderOrderTotal() }
                    { checkoutButton }
                </div>
            </>
        );
    }

    /**
     * Move part of logic to a separate method.
     */
    renderTotals() {
        const { totals: { items = [] } } = this.props;

        return (
            <div block="CheckoutOrderSummary" elem="OrderTotals">
                <ul>
                    { this.renderSubTotal() }
                    { this.renderTax() }
                    { this.renderDiscount() }
                    { this.renderShipping() }
                    <div block="CheckoutOrderSummary" elem="ButtonWrapper" mods={ { isEmpty: items.length < 1 } }>
                        { this.renderBottomSummary() }
                    </div>
                </ul>
            </div>
        );
    }

    // /**
    //  * Overridden to add discountCode and different rendering for mobile
    //  */
    // renderTotals() {
    //     const { children, totals: { items = [] }, device: { isMobile } } = this.props;
    //
    //     if (isMobile) {
    //         return (
    //             <div block="CheckoutOrderSummary" elem="OrderTotals">
    //                 <ul>
    //                     <div block="CheckoutOrderSummary" elem="ButtonWrapper" mods={ { isEmpty: items.length < 1 } }>
    //                         { this.renderOrderTotal() }
    //                         { children }
    //                     </div>
    //                 </ul>
    //             </div>
    //         );
    //     }
    //
    //     return (
    //         <div block="CheckoutOrderSummary" elem="OrderTotals">
    //             <ul>
    //                 { this.renderSubTotal() }
    //                 { this.renderTax() }
    //                 { this.renderDiscount() }
    //                 { this.renderShipping() }
    //                 { this.renderDiscountCode() }
    //                 <div block="CheckoutOrderSummary" elem="ButtonWrapper" mods={ { isEmpty: items.length < 1 } }>
    //                     { this.renderOrderTotal() }
    //                     { children }
    //                 </div>
    //             </ul>
    //         </div>
    //     );
    // }
}

export default CheckoutOrderSummaryComponent;
