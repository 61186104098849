import domToReact from 'html-react-parser/lib/dom-to-react';

import { CONTACT_US_LINK_ID } from '../component/ZendeskLiveChat/ZendeskLiveChat.config';

const contactUsOnClick = (e) => {
    // Prevent scrolling to top
    e.preventDefault();

    const { zE } = window;
    const { target } = e;
    const wrapper = document.getElementById('zendesk-custom-launcher-wrapper');
    const button = document.getElementById('zendesk-custom-launcher-button');
    const webWidget = document.getElementById('webWidget');

    zE('webWidget', 'show');
    zE('webWidget', 'open');

    window.zenDeskFocusReturn = target;

    if (wrapper) {
        wrapper.style.opacity = 0;
    }

    button?.setAttribute('aria-expanded', 'true');
    webWidget?.setAttribute('role', 'region');
    webWidget?.setAttribute('aria-label', 'Support help');

    return false;
};

const initContactUsLink = (args, callback, instance) => {
    const [{ attribs: { id = '' }, children }] = args;

    if (id === CONTACT_US_LINK_ID) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              id={ CONTACT_US_LINK_ID }
              href="#"
              role="button"
              tabIndex="0"
              aria-expanded="false"
              onClick={ contactUsOnClick }
            >
                { domToReact(children, instance.parserOptions) }
            </a>
        );
    }

    return callback(...args);
};

export default {
    'Component/Html/Component': {
        'member-function': {
            replaceLinks: initContactUsLink
        }
    }
};
