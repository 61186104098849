/**
 * ScandiPWA - Progressive Web App for Magento
 * @package @scandiweb/payflowpro
 */

import PayFlowPro from '../component/PayFlowPro';
import { PAYFLOWPRO_CODE } from '../component/PayFlowPro/PayFlowPro.config';

export function renderPayFlowPro() {
    const { setOrderButtonEnableStatus, sendPayFlowProState } = this.props;

    return (
        <PayFlowPro
          setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
          sendPayFlowProState={ sendPayFlowProState }
        />
    );
}

export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [PAYFLOWPRO_CODE]: renderPayFlowPro.bind(instance)
});

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap
        }
    }
};

export default config;
