/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import isMobile from 'Util/Mobile';

import { CLERK_LIVE_SEARCH_ID, CLERK_SEARCH_WRAPPER_ID } from './config';

/** @namespace Scandiweb/Clerk/Util/HtmlHelpers/setSearchResultsPosition */
export function setSearchResultsPosition() {
    const searchWrapper = document.getElementById(CLERK_LIVE_SEARCH_ID);
    const position = searchWrapper?.getBoundingClientRect();

    const element = document.getElementById(CLERK_SEARCH_WRAPPER_ID);
    const bottomDistance = 50;

    if (element) {
        element.style.top = `${ position.bottom + window.scrollY }px`;
        element.style.left = `${ position.left }px`;

        /* restricting search results height to keep it within the screen */
        if (isMobile.any()) {
            element.style.height = `${window.innerHeight - position.bottom - bottomDistance}px`;
        }
    }
}
