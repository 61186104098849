/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import './BreadcrumbHomeIcon.style.scss';

/** @namespace Scandiweb/MoninTheme/Component/BreadcrumbHomeIcon/Component/BreadcrumbHomeIconComponent */
export class BreadcrumbHomeIconComponent extends PureComponent {
    render() {
        return (
            <svg block="BreadcrumbHomeIcon" width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5525 0.675258C7.9579 0.334593 8.47047 0.147827 9 0.147827C9.52953 0.147827 10.0421 0.334593 10.4475 0.675258L17.1975 6.35126C17.4488 6.56239 17.6508 6.82593 17.7894 7.12341C17.928 7.42088 17.9999 7.74507 18 8.07326V17.5008C18 17.8986 17.842 18.2801 17.5607 18.5614C17.2794 18.8427 16.8978 19.0008 16.5 19.0008H12.375C11.9772 19.0008 11.5956 18.8427 11.3143 18.5614C11.033 18.2801 10.875 17.8986 10.875 17.5008V12.2508H7.125V17.5008C7.125 17.8986 6.96696 18.2801 6.68566 18.5614C6.40436 18.8427 6.02282 19.0008 5.625 19.0008H1.5C1.10218 19.0008 0.720644 18.8427 0.43934 18.5614C0.158035 18.2801 0 17.8986 0 17.5008V8.07251C8.29948e-05 7.74432 0.0719593 7.42013 0.210589 7.12266C0.349218 6.82519 0.551245 6.56164 0.8025 6.35051L7.5525 0.674508V0.675258ZM9.483 1.82276C9.34779 1.70894 9.17674 1.64653 9 1.64653C8.82326 1.64653 8.65221 1.70894 8.517 1.82276L1.767 7.50026C1.68357 7.57049 1.61645 7.65808 1.57034 7.75691C1.52422 7.85575 1.50022 7.96345 1.5 8.07251V17.5H5.625V12.25C5.625 11.8522 5.78304 11.4707 6.06434 11.1893C6.34564 10.908 6.72718 10.75 7.125 10.75H10.875C11.2728 10.75 11.6544 10.908 11.9357 11.1893C12.217 11.4707 12.375 11.8522 12.375 12.25V17.5H16.5V8.07251C16.5 7.9632 16.4761 7.8552 16.43 7.75609C16.3839 7.65699 16.3166 7.56916 16.233 7.49876L9.483 1.82276V1.82276Z" fill="none" />
            </svg>
        );
    }
}

export default BreadcrumbHomeIconComponent;
