/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/jsx-no-bind */
import Field from 'Component/Field';

import { Facets, Results, ResultsItems } from '../../component/Results';
import { ResultsProvider } from '../../context/Results/Results.provider';

const renderMiscellaneous = (_args, _callback) => (
    <aside block="Clerk" elem="Miscellaneous">
        <ResultsItems />
    </aside>
);

const setPerPage = (instance, value) => {
    instance.setState({ perPage: value });
};

const renderDropdown = (instance) => {
    const { perPage } = instance.state;
    return (
        <div block="SearchPage" elem="DropdownWrapper">
            <p block="SearchPage" elem="Text">{ __('Items per page:').toString() }</p>
            <Field
              id="view"
              name="view"
              placeholder={ __('Number of items').toString() }
              type="select"
              mix={ { block: 'SearchPage', elem: 'Dropdown' } }
              selectOptions={ [
                  {
                      id: 12,
                      label: '12',
                      value: 12
                  },
                  {
                      id: 24,
                      label: '24',
                      value: 24
                  },
                  {
                      id: 36,
                      label: '36',
                      value: 36
                  }
              ] }
              value={ perPage }
              onChange={ (value) => setPerPage(instance, value) }
            />
        </div>
    );
};

const renderSearchResults = (_args, _callback, instance) => (
    <>
        <Results />
        { renderDropdown(instance) }
    </>
);

const wrapWithResultsProvider = (args, callback, instance) => (
    <ResultsProvider instance={ instance }>
        { callback(...args) }
    </ResultsProvider>
);

const renderFacets = () => (
    <Facets />
);

const componentDidMount = (args, callback, _instance) => {
    const button = document.getElementById('clerk-search-filters-button');
    _instance.setState({ perPage: 12 });

    if (button) {
        button.addEventListener('click', () => {
            const element = document.getElementById('clerk-search-filters');

            if (element.classList.contains('ClerkFiltersExpanded')) {
                button.classList.remove('FilterExpanded');
                element.classList.remove('ClerkFiltersExpanded');
            } else {
                button.classList.add('FilterExpanded');
                element.classList.add('ClerkFiltersExpanded');
            }
        });
    }

    callback(...args);
};

const state = (originalMember) => ({
    ...originalMember,
    perPage: 12
});

export default {
    'Route/SearchPage/Component': {
        'member-function': {
            renderMiscellaneous,
            renderCategoryProductList: renderSearchResults,
            renderFilterOverlay: renderFacets,
            renderContent: wrapWithResultsProvider,
            componentDidMount
        }
    },
    'Route/SearchPage/Container': {
        'member-property': {
            state
        }
    }
};
