import facebookIcon from 'Style/icons/facebook.svg';
import linkedInIcon from 'Style/icons/linkedIn.svg';
import twitterIcon from 'Style/icons/twitter.svg';

export const SOCIAL_MEDIA_MAP = [
    {
        href: 'https://www.facebook.com/Monin.official',
        src: facebookIcon,
        title: __('Facebook').toString()
    },
    {
        href: 'https://twitter.com/MONINFlavours',
        src: twitterIcon,
        title: __('Twitter').toString()
    },
    {
        href: 'https://www.linkedin.com/company/monin-europe-middle-east-africa/',
        src: linkedInIcon,
        title: __('LinkedIn').toString()
    }
];
