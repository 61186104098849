/* eslint-disable max-len */
import { PureComponent } from 'react';

import './GroupIcon.style';

/** @namespace Scandiweb/MoninTheme/Component/GroupIcon/Component/GroupIconComponent */
export class GroupIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="GroupIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5.455 15L1 18.5L1 3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2L17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L18 15L5.455 15ZM4.763 13L16 13V4L3 4L3 14.385L4.763 13ZM8 17L18.237 17L20 18.385L20 8L21 8C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9L22 22.5L17.545 19L9 19C8.73478 19 8.48043 18.8946 8.29289 18.7071C8.10536 18.5196 8 18.2652 8 18V17Z" />
            </svg>
        );
    }
}

export default GroupIconComponent;
