/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductAlertsQuery from 'Query/ProductAlerts.query';
// eslint-disable-next-line max-len
import { ProductAlertsContainer as SourceProductAlertsContainer } from 'SourceComponent/ProductAlerts/ProductAlerts.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { customerType } from 'Type/Account';
import { StockStatusType } from 'Type/StockStatus';
import { fetchMutation } from 'Util/Request';

import ProductAlertsComponent from './ProductAlerts.component';
import { PRODUCT_ALERT_IN_STOCK, PRODUCT_ALERT_PRICE_DROP } from './ProductAlerts.config';

/** @namespace Scandipwa/Component/ProductAlerts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isPriceAlertEnabled: state.ConfigReducer.product_alert_allow_price,
    isInStockAlertEnabled: state.ConfigReducer.product_alert_allow_stock,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isMobile: state.ConfigReducer.device.isMobile,
    customer: state.MyAccountReducer.customer
});

/** @namespace Scandipwa/Component/ProductAlerts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace Scandipwa/Component/ProductAlerts/Container/ProductAlertsContainer */
export class ProductAlertsContainer extends SourceProductAlertsContainer {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        productId: PropTypes.number.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        isInStockAlertEnabled: PropTypes.bool,
        isPriceAlertEnabled: PropTypes.bool,
        stockStatus: StockStatusType,
        isMobile: PropTypes.bool.isRequired,
        customer: customerType.isRequired
    };

    static defaultProps = {
        isInStockAlertEnabled: false,
        isPriceAlertEnabled: false,
        stockStatus: null
    };

    state = {
        guestEmail: '',
        status: null,
        errorMessage: __('Looks like we already have you on the list. We`ll notify you as soon as the item is available.').toString(),
        successMessage: __('Great! We`ve added you to the list. Keep an eye out for an email from us about this item.').toString()
    };

    containerFunctions = {
        handlePriceDropSubscribeAlertPriceDrop: this.handlePriceDropSubscribe.bind(this, PRODUCT_ALERT_PRICE_DROP),
        handlePriceDropSubscribeAlertInStock: this.handlePriceDropSubscribe.bind(this, PRODUCT_ALERT_IN_STOCK),
        setGuestEmail: this.setGuestEmail.bind(this)
    };

    containerProps() {
        const {
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            stockStatus,
            selectedVariant,
            isSignedIn,
            isMobile
        } = this.props;

        const {
            guestEmail,
            status,
            successMessage,
            errorMessage
        } = this.state;

        return {
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            stockStatus,
            selectedVariant,
            isSignedIn,
            guestEmail,
            status,
            successMessage,
            errorMessage,
            isMobile
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedVariant: { id: prevId } } = prevProps;
        const { selectedVariant: { id } } = this.props;

        if (prevId !== id) {
            this.setState({ status: null });
        }
    }

    setGuestEmail(guestEmail) {
        this.setState({ guestEmail });
    }

    setStatus(status) {
        this.setState({ status });
    }

    handlePriceDropSubscribe() {
        const {
            productId,
            isSignedIn,
            customer: { email }
        } = this.props;

        const { guestEmail } = this.state;

        const emailAddress = isSignedIn ? email : guestEmail;
        const query = ProductAlertsQuery.getProductAlertSubscribeNotSignedInMutation(productId, emailAddress);

        this.makeRequest(query);
    }

    makeRequest(query) {
        const { errorMessage, successMessage } = this.state;
        const { showNotification, showErrorNotification } = this.props;

        return fetchMutation(query).then(
            /** @namespace Scandipwa/Component/ProductAlerts/Container/fetchMutation/then */
            (ProductAlertSubscribe) => {
                if (!ProductAlertSubscribe.GuestStockAlert) {
                    this.setStatus(false);
                    return showNotification('error', __(errorMessage).toString());
                }
                this.setStatus(true);
                return showNotification('success', __(successMessage).toString());
            }
        ).catch(
            /** @namespace Scandipwa/Component/ProductAlerts/Container/fetchMutation/then/catch */
            (error) => {
                showErrorNotification('error', error[0]);
            }
        );
    }

    render() {
        return (
            <ProductAlertsComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlertsContainer);
