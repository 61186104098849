/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    EVENT_GTM_PRODUCT_CLICK
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

/** ProductCard */
const registerSharedElement = (args, callback, instance) => {
    const {
        currentVariantIndex: configurableVariantIndex,
        selectedFilters,
        product,
        event,
        eventPathname,
        position,
        linkTo: { state: { prevCategoryId } }
    } = instance.props;
    const { pathname: urlPathname } = window.location;
    const [e] = args;

    const pathname = eventPathname || urlPathname;

    const productToPass = Object.keys(selectedFilters).length
        ? {
            ...product, configurableVariantIndex, prevCategoryId, position, pathname
        }
        : {
            ...product, prevCategoryId, position, pathname
        };

    /* event is not triggered on Add To Cart click */
    if (!e.target.closest('.AddToCart')) {
        event(EVENT_GTM_PRODUCT_CLICK, productToPass);
    }

    callback(...args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        event,
        eventPathname,
        selectedFilters,
        position = 0
    } = instance.props;

    return {
        ...callback(...args),
        selectedFilters,
        position,
        event,
        eventPathname
    };
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            registerSharedElement
        }
    },
    'Component/ProductCard/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps
        }
    }
};
