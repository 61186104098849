/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { EVENT_GTM_PRODUCT_CLICK } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const MINI_CART_PATH = 'mini-cart';
export const CART_PATH = 'cart';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export class CartItemContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        handleItemClick: this.handleItemClick.bind(instance)
    });

    handleItemClick(e) {
        const {
            event, position, isCartOverlay, item: { product = {} }
        } = this.props;
        const search = false;

        const pathname = isCartOverlay ? MINI_CART_PATH : CART_PATH;

        const productToPass = {
            ...product, position, pathname, search
        };

        /* event is not triggered on +/- click */
        if (!e.target.closest('.CartItem-Qty')) {
            event(EVENT_GTM_PRODUCT_CLICK, productToPass);
        }
    }
}

const { containerFunctions } = new CartItemContainer();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            containerFunctions
        }
    },
    'Component/CartItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
