import Field from 'Component/Field';
import { VALIDATION_STATUS } from 'Component/Field/Field.config';
import {
    CartCoupon as SourceCartCoupon
} from 'SourceComponent/CartCoupon/CartCoupon.component';

import './CartCoupon.override.style';

/** @namespace Scandiweb/MoninTheme/Component/CartCoupon/Component/CartCouponComponent */
export class CartCouponComponent extends SourceCartCoupon {
    /**
     * Overridden to change button text and field placeholder
     */
    renderApplyCoupon() {
        const { enteredCouponCode } = this.state;
        const { skip, success } = VALIDATION_STATUS;

        return (
            <>
                <div block="CartCoupon" elem="Input">
                <Field
                  type="text"
                  id="couponCode"
                  name="couponCode"
                  value={ enteredCouponCode }
                  placeholder={ __('Your discount code') }
                  onChange={ this.handleCouponCodeChange }
                  customValidationStatus={ !enteredCouponCode ? skip : success }
                  mix={ { block: 'CartCoupon', elem: 'Input' } }
                  aria-label={ __('Your discount code') }
                />
                </div>
                <button
                  block="CartCoupon"
                  elem="Button"
                  type="button"
                  mods={ { isHollow: true } }
                  disabled={ !enteredCouponCode }
                  onClick={ this.handleApplyCoupon }
                >
                    { __('Apply coupon code') }
                </button>
            </>
        );
    }
}

export default CartCouponComponent;
