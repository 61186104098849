import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MinusIcon.style';

/** @namespace Scandipwa/Component/MinusIcon/Component/MinusIconComponent */
export class MinusIconComponent extends PureComponent {
    static propTypes = {
        isPrimary: PropTypes.bool
    };

    static defaultProps = {
        isPrimary: false
    };

    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="MinusIcon"
              mods={ { isPrimary } }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
                <path d="M5 11H19V13H5V11Z" />
            </svg>
        );
    }
}

export default MinusIconComponent;
