import { LockIcon as SourceLockIcon } from 'SourceComponent/LockIcon/LockIcon.component';

import './LockIcon.override.style.scss';

/** @namespace Scandiweb/MoninTheme/Component/LockIcon/Component/LockIconComponent */
export class LockIconComponent extends SourceLockIcon {
    render() {
        return (
            <svg
              block="LockIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  d="M19 11L5 11C3.89543 11 3 11.8954 3 13L3 20C3 21.1046 3.89543 22 5
                  22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                  fill="none"
                />
                <path
                  d="M7 11L7 7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739
                  2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                  fill="none"
                />
            </svg>
        );
    }
}

export default LockIconComponent;
