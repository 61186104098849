/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ChildrenType } from 'Type/Common';

import { getClerk } from '../../util/config';
import { CLERK_SEARCH_RESULTS_WRAPPER_ID, useSearchResults } from '../../util/hooks';
import { ResultsContext } from './Results.context';

/** @namespace Scandiweb/Clerk/Context/Results/Provider/ResultsProvider */
export const ResultsProvider = ({ children, instance }) => {
    const location = useLocation();
    const searchResults = useSearchResults(instance);
    const [itemCount, setItemCount] = useState(-1);
    const [canRender, setCanRender] = useState(false);
    const [hasRendered, setHasRendered] = useState(false);

    const renderResults = async () => {
        const clerk = await getClerk();
        clerk('content', `#${CLERK_SEARCH_RESULTS_WRAPPER_ID}`);
    };

    useEffect(() => {
        (async () => {
            if (canRender) {
                await renderResults();
                setHasRendered(true);
            }
        })();
    }, [canRender]);

    useEffect(() => {
        (async () => {
            if (hasRendered) {
                const spanE = document.getElementById(CLERK_SEARCH_RESULTS_WRAPPER_ID);
                spanE.removeAttribute('data-clerk-content-id');
                // ^^^ this resets the content id, clerk re-initializes the element
                await renderResults();
            }
        })();
    }, [searchResults]);

    useEffect(() => {
        (async () => {
            const clerk = await getClerk();
            clerk('on', 'rendered', `#${CLERK_SEARCH_RESULTS_WRAPPER_ID}`, (_content, data) => {
                const newItemCount = data.response.result.length;
                setItemCount(itemCount === -1 ? newItemCount : newItemCount + itemCount);
            });
        })();

        const observer = new MutationObserver((_mutations, mutationInstance) => {
            const facetsT = document.getElementById('clerk-search-filters');
            const spanT = document.getElementById('search-results');

            if (facetsT && spanT) {
                setCanRender(true);
                mutationInstance.disconnect();
            }
        });

        observer.observe(document, {
            childList: true,
            subtree: true
        });
    }, []);

    useEffect(() => {
        setItemCount(-1);
    }, [location]);

    return (
        <ResultsContext.Provider value={ {
            ...searchResults,
            itemCount
        } }
        >
            { children }
        </ResultsContext.Provider>
    );
};

ResultsProvider.propTypes = {
    children: ChildrenType.isRequired
};
