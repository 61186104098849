/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import SocialMediaLinks from 'Component/SocialMediaLinks';
import { PRODUCT_ATTRIBUTES, PRODUCT_REVIEWS } from 'Route/ProductPage/ProductPage.config';
import {
    ProductActions as SourceProductActions
} from 'SourceComponent/ProductActions/ProductActions.component';
import { isCrawler, isSSR } from 'Util/Browser';
import { GROUPED } from 'Util/Product';

import './ProductActions.override.style';

/** @namespace Scandiweb/MoninTheme/Component/ProductActions/Component/ProductActionsComponent */
export class ProductActionsComponent extends SourceProductActions {
    /**
     * Overridden to change text
     */
    renderReviewButton() {
        const {
            areReviewsEnabled,
            handleOnExpandAndScrollClick,
            getTabNameByKey
        } = this.props;

        if (!areReviewsEnabled) {
            return null;
        }

        return (
            <button
              block="ProductActions"
              elem="Review"
              onClick={ handleOnExpandAndScrollClick(getTabNameByKey(PRODUCT_REVIEWS)) }
            >
                { __('View all reviews') }
            </button>
        );
    }

    renderMoreDetailsButton() {
        const { getTabNameByKey, handleOnExpandAndScrollClick } = this.props;

        return (
            <button
              block="ProductActions"
              elem="MoreDetailsButton"
              mix={ { block: 'Button', mods: { likeLink: true } } }
              onClick={ handleOnExpandAndScrollClick(getTabNameByKey(PRODUCT_ATTRIBUTES)) }
            >
                { __('More details') }
            </button>
        );
    }

    renderSocialMediaLinks() {
        return (
            <SocialMediaLinks mix={ { block: 'ProductActions', elem: 'SocialMediaLinks' } } />
        );
    }

    /**
     * Overridden to add media links
     */
    renderReviewSection() {
        const { device: { isMobile } = {} } = this.props;

        const renderMediaLinks = !isMobile ? this.renderSocialMediaLinks() : null;

        return (
            <div
              block="ProductActions"
              elem="Reviews"
            >
                { this.renderReviews() }
                { this.renderReviewButton() }
                { renderMediaLinks }
            </div>
        );
    }

    /**
     * Overridden to remove wishlist button and add more details button
     */
    renderAddToCartMobile() {
        return (
            <div
              block="ProductActions"
              elem="AddToCartFixed"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                <div>
                    { this.renderQuantityInput() }
                    { this.renderMoreDetailsButton() }
                </div>
                { this.renderAddToCart() }
            </div>
        );
    }

    /**
     * Overridden to remove sku and stock, and change order
     */
    renderDesktop() {
        return (
            <>
                { this.renderBrand() }
                { this.renderName() }
                { this.renderReviewSection() }
                { this.renderShortDescription() }
                { this.renderPriceWithGlobalSchema() }
                { this.renderTierPrices() }
                { this.renderConfigurableAttributes() }
                { this.renderCustomizableOptions() }
                { this.renderBundleItems() }
                { this.renderGroupedItems() }
                { this.renderDownloadableProductSample() }
                { this.renderDownloadableProductLinks() }
                { this.renderProductAlerts() }
                { this.renderAddToCartActionBlock() }
            </>
        );
    }

    /**
     * Overridden to remove sku and stock, move tier prices, add name, and change render logic a bit
     */
    renderMobile() {
        const { product: { type_id } } = this.props;
        const isWithoutPriceTotal = type_id === GROUPED;

        return (
            <>
                { this.renderName() }
                <div block="ProductActions" elem="ActionsWrapper">
                    { this.renderReviewSection() }
                </div>
                <div block="ProductActions" elem="ActionsWrapper" mods={ { isWithoutPriceTotal } }>
                    { this.renderPriceWithGlobalSchema() }
                </div>
                { this.renderTierPrices() }
                { this.renderBrand(true) }
                { this.renderShortDescription() }
                { this.renderProductAlerts() }
                { this.renderCustomizableOptions() }
                { this.renderConfigurableAttributes() }
                { this.renderCustomizableOptions() }
                { this.renderDownloadableProductSample() }
                { this.renderDownloadableProductLinks() }
                { this.renderAddToCartMobile() }
                { this.renderSocialMediaLinks() }
            </>
        );
    }

    /**
     * Overridden to remove wishlist and compare button and add more details button
     */
    renderAddToCartActionBlock() {
        return (
            <div
              block="ProductActions"
              elem="AddToCartWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderQuantityInput() }
                { this.renderAddToCart() }
                { this.renderMoreDetailsButton() }
            </div>
        );
    }
}

export default ProductActionsComponent;
