/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';
import Popup from 'Component/Popup';

import { PRIVACY_SETTINGS_POPUP_ID } from './MyAccountPrivacySettingsPopup.config';

import './MyAccountPrivacySettingsPopup.style';

/** @namespace Gdpr/Component/MyAccountPrivacySettingsPopup/Component/MyAccountPrivacySettingsPopupComponent */
export class MyAccountPrivacySettingsPopupComponent extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            isDownload: PropTypes.bool,
            downloadData: PropTypes.func,
            isAnonymise: PropTypes.bool,
            anonymiseData: PropTypes.func,
            isDelete: PropTypes.bool,
            deleteData: PropTypes.func
        }).isRequired,
        onSuccess: PropTypes.func.isRequired
    };

    renderContent() {
        const { onSuccess } = this.props;

        return (
            <Form key="privacySettings" onSubmitSuccess={ onSuccess }>
                <Field
                  type="password"
                  placeholder={ __('Password').toString() }
                  id="password"
                  name="password"
                  validation={ ['notEmpty', 'password'] }
                />
                <button
                  block="Button"
                  mods={ {
                      Narrow: true,
                      Dark: true
                  } }
                  type="submit"
                >
                    { __('Proceed').toString() }
                </button>
            </Form>
        );
    }

    render() {
        return (
            <Popup
              id={ PRIVACY_SETTINGS_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'MyAccountPrivacySettingsPopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default MyAccountPrivacySettingsPopupComponent;
