/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import FirstVisitPrivacyPolicyPopup from '../component/FirstVisitPrivacyPolicyPopup';
import PrivacyPolicyPopup from '../component/PrivacyPolicyPopup';

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

export const addGdprPrivacyPolicyPopup = (originalMember) => {
    const maxPosition = Math.max(
        ...originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    return [
        ...originalMember,
        {
            component: <PrivacyPolicyPopup />,
            position: maxPosition + INCREASED_POSITION
        },
        {
            component: <FirstVisitPrivacyPolicyPopup />,
            position: maxPosition + INCREASED_POSITION + INCREASED_POSITION
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addGdprPrivacyPolicyPopup
        }
    }
};
