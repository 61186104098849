/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import BackToTop from './BackToTop.component';
import { FOCUSABLE_ELEMENT_QUERY, VISIBILITY_THRESHOLD } from './BackToTop.config';

/* @namespace Scandipwa/Component/BackToTop/Container/BackToTopContainer */
export class BackToTopContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false
    };

    state = {
        isLoading: false,
        isVisible: false
    };

    containerFunctions = {
        scrollToTop: this.scrollToTop.bind(this)
    };

    componentDidMount() {
        const scrollComponent = this;

        window.addEventListener('scroll', () => {
            scrollComponent.onScroll();
        });
    }

    containerProps() {
        const { isLoading, isVisible } = this.state;

        return {
            isLoading,
            isVisible
        };
    }

    onScroll = () => {
        const { scrollY: position } = window;

        this.setState({ isVisible: position > VISIBILITY_THRESHOLD });
    };

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        this.findMain();
    }

    findMain() {
        const mainList = document.getElementsByTagName('main');

        if (mainList.length) {
            const [focusable] = mainList[0]?.querySelectorAll(FOCUSABLE_ELEMENT_QUERY) || [];

            focusable?.focus();
        } else {
            const sectionList = document.getElementsByTagName('section');

            if (sectionList.length > 1) {
                const [focusable] = sectionList[1]?.querySelectorAll(FOCUSABLE_ELEMENT_QUERY) || [];

                focusable?.focus();
            }
        }
    }

    render() {
        return (
            <BackToTop
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default BackToTopContainer;
