const containerProps = (args, callback, instance) => {
    const { variants } = instance.props;

    return {
        ...callback(...args),
        variants
    };
};

export const config = {
    'Component/ProductConfigurableAttributes/Container': {
        'member-function': {
            containerProps
        }
    }
};

export default config;
