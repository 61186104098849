import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MoninChevronIcon from 'Component/MoninChevronIcon';

/** @namespace Scandiweb/MoninTheme/Component/SlickSliderArrow/Component/SlickSliderArrowComponent */
export class SlickSliderArrowComponent extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        style: PropTypes.instanceOf(Object),
        className: PropTypes.string,
        ariaLabel: PropTypes.string.isRequired
    };

    static defaultProps = {
        onClick: () => {},
        style: {},
        className: ''
    };

    render() {
        const {
            onClick, style, className, ariaLabel
        } = this.props;

        /**
         * className and style props are passed by the 'react-slick'.
         */
        return (
            <button
              block="SlickSliderArrow"
              onClick={ onClick }
              style={ { ...style, display: 'flex' } }
              className={ className }
              aria-label={ ariaLabel }
            >
                <MoninChevronIcon />
            </button>
        );
    }
}

export default SlickSliderArrowComponent;
