import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutOrderSummary/CheckoutOrderSummary.container';

import CheckoutOrderSummary from './CheckoutOrderSummary.component';

export { mapDispatchToProps };

/**
 * Overridden to pass device prop
 */
/** @namespace Scandiweb/MoninTheme/Component/CheckoutOrderSummary/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOrderSummary);
