/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export const aroundGetProductInterfaceFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);
    const isVariant = args[0] === undefined ? false : args[0];

    if (!isVariant) {
        fields.push(instance._getAttributesField());
    }

    fields.push(
        instance._getCategoriesField(),
        instance._getPriceRangeField()
    );

    fields.push(
        getDimensions()
    );

    return fields;
};

const getDimensions = () => new Field('dimensions');

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: aroundGetProductInterfaceFields
        }
    }
};
