// Disabled by scandipwa:
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import {
    CHECKBOX_TYPE,
    RADIO_TYPE
} from 'Component/Field/Field.config';
import FieldInput from 'Component/FieldInput';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldComponent as MoninFieldComponent
} from 'MoninComponent/Field/Field.component';

import './Field.extend.style';

/** @namespace Scandipwa/Component/Field/Component/FieldComponent */
export class FieldComponent extends MoninFieldComponent {
    static propTypes = {
        ...this.propTypes,
        size: PropTypes.string,
        isPlaceholderIncluded: PropTypes.bool,
        selectedOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.arrayOf(PropTypes.number)
        ]),
        message: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ])
    };

    static defaultProps = {
        ...this.defaultProps,
        size: null,
        isPlaceholderIncluded: true
    };

    /*
    * Overriden to use props in current instance
    */
    renderTypeText() {
        return (
            <FieldInput
              { ...this.props }
              type="text"
            />
        );
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            step
        } = this.props;

        return (
            <>
                <button
                  block="FieldNumber"
                  elem="DecreaseButton"
                  disabled={ value === min }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - step) }
                  aria-label={ __('Subtract').toString() }
                  tabIndex="0"
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
                <FieldInput
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...this.props }
                  type="number"
                  readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value').toString() }
                />
                <button
                  block="FieldNumber"
                  elem="IncreaseButton"
                  disabled={ +value === max }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + step) }
                  aria-label={ __('Add').toString() }
                  tabIndex="0"
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
            </>
        );
    }

    renderCheckbox() {
        const {
            id,
            onChangeCheckbox,
            label,
            subLabel,
            disabled,
            categoryFilter
        } = this.props;

        return (
            <label htmlFor={ id } block="Field" elem="CheckboxLabel" id={ `${id}_label_top` }>
                <FieldInput
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                  isDisabled={ disabled }
                  categoryFilter={ categoryFilter }
                />
                <div block="input-control" />
                <span>
                    { label }
                    { subLabel && (
                        <strong block="Field" elem="SubLabel">
                            { ` (${subLabel})` }
                        </strong>
                    ) }
                </span>
            </label>
        );
    }

    renderMessage() {
        const { message, id } = this.props;

        if (!message) {
            return null;
        }

        return (
            <p block="Field" elem="Message" id={ `${ id }-error` }>
                { message }
            </p>
        );
    }

    renderLabel() {
        const {
            id,
            label,
            validation,
            type
        } = this.props;
        const isRequired = validation.includes('notEmpty');
        const noRenderLabel = type === CHECKBOX_TYPE || type === RADIO_TYPE;

        if (!label || noRenderLabel) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label
                  block="Field"
                  elem="Label"
                  mods={ { isRequired } }
                  htmlFor={ id }
                  id={ `${ id }_top-label` }
                >
                    { label }
                </label>

                { this.renderArrow() }
            </div>
        );
    }

    render() {
        const {
            mix,
            type,
            message,
            validationStatus,
            testValidationMessage
        } = this.props;

        if (type === 'number') {
            return (
                <div
                  block="FieldNumber"
                  elem="Wrapper"
                >
                    { this.renderLabel() }
                    <div
                      block="Field"
                      mods={ {
                          type,
                          hasError: validationStatus === false || !!message || !!testValidationMessage,
                          isValid: validationStatus === true
                      } }
                      mix={ mix }
                    >
                        { this.renderInputOfType(type) }
                    </div>
                    { this.renderMessage() }
                </div>
            );
        }

        return (
            <div
              block="Field"
              mods={ {
                  type,
                  hasError: validationStatus === false || !!message || !!testValidationMessage,
                  isValid: validationStatus === true
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}

export default FieldComponent;
