/** @namespace Scandipwa/Util/Carousel/Sort/sortItemsBySkuPosition */
export const sortItemsBySkuPosition = (items, itemsSku) => {
    if (!itemsSku) {
        return items;
    }

    const skuList = itemsSku.split(',').reduce((acc, sku, index) => {
        acc[sku.trim()] = index;
        return acc;
    }, {});

    const itemsSorted = Array.from(items).sort((itemA, itemB) => skuList[itemA.sku] - skuList[itemB.sku]);

    return itemsSorted;
};
