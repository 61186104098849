import { Image as SourceImage } from 'SourceComponent/Image/Image.component';
import { IMAGE_LOADING } from 'SourceComponent/Image/Image.config';

import './Image.extend.style';

/** @namespace Scandipwa/Component/Image/Component/ImageComponent */
export class ImageComponent extends SourceImage {
    renderStyledImage() {
        const {
            alt,
            src,
            style,
            title,
            emptyAlt
        } = this.props;
        const { imageStatus } = this.state;

        return (
            <img
              block="Image"
              elem="Image"
              src={ src || '' }
              alt={ emptyAlt ? '' : alt || title }
              mods={ { isLoading: imageStatus === IMAGE_LOADING } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading="lazy"
              emptyAlt={ emptyAlt }
            />
        );
    }

    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className,
            emptyAlt
        } = this.props;

        return (
            <img
              block={ className }
              src={ src || '' }
              alt={ emptyAlt ? '' : alt || title }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading="lazy"
              emptyAlt={ emptyAlt }
            />
        );
    }
}

export default ImageComponent;
