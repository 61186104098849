/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { caCodeRegExpArr } from 'Component/CheckoutAddressForm/CheckoutAddressForm.config';

/** @namespace Scandipwa/Util/Account/focusActionOverlayFirstInput */
export const focusActionOverlayFirstInput = () => {
    const actionOverlay = document.getElementById('ActionOverlay');

    if (!actionOverlay) {
        return;
    }

    const input = actionOverlay.getElementsByTagName('input')[0];

    if (!input) {
        return;
    }

    input.focus();
};

/** @namespace Scandipwa/Util/Account/testCaPostRegExp */
export const testCaPostRegExp = (value) => {
    if (!value) {
        return false;
    }

    const caCodeSpaceIdx = 3;
    const val = value.indexOf(' ') === caCodeSpaceIdx
        ? value
        : value.trim();

    const sliceIdx = /\s/.test(val) && value.indexOf(' ') !== value.length - 1
        ? value.length - 1
        : value.length;

    const regExp = new RegExp(
        `^${caCodeRegExpArr.slice(0, sliceIdx).join('')}$`,
        'i'
    );

    return regExp.test(val);
};
