/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// ^^^ I need this because I want to modify items property

import {
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

import {
    BEFORE_ITEMS_TYPE,
    NAVIGATION_TABS
} from './Router.config';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
};

/** @namespace Scandiweb/MoninTheme/Component/Router/Component/RouterComponent */
export class RouterComponent extends SourceRouter {
    __construct(props) {
        super.__construct(props);
        const navigationIndex = this[BEFORE_ITEMS_TYPE].findIndex((item) => item.name === NAVIGATION_TABS);
        this[BEFORE_ITEMS_TYPE].splice(navigationIndex, 1);
    }
}

export default RouterComponent;
