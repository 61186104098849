/* eslint-disable arrow-body-style */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const PostsListing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/PostsListing')
);
export const PostsDetails = lazy(
    // eslint-disable-next-line max-len
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsDetails" */ '../route/PostsDetails')
);
export const NewsListing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/NewsListing')
);
export const NewsDetails = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/NewsDetails')
);

export const TrendsListing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/TrendsListing')
);
export const WhatsHotListing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/WhatsHotListing')
);
export const WhatsHotDetails = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/WhatsHotDetails')
);

export const EventsAndTradeshowsListing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/EventsAndTradeshowsListing')
);

export const EventsAndTradeshowsDetails = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/EventsAndTradeshowsDetails')
);

export const SeasonalInspiration = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/SeasonalInspiration')
);

export const SeasonalDetails = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/SeasonalDetails')
);

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export class RouterComponentPlugin {
    /**
     * Adds blog url handles to the router SWITCH_ITEMS_TYPE list
     * @param originalMember
     * @returns {*[]}
     */
    // eslint-disable-next-line no-unused-vars
    switchItems = (originalMember, instance) => {
        return [
            ...originalMember,
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/video-library/:handle') } component={ PostsListing } />,
                position: 100
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/videoteca/:handle') } component={ PostsListing } />,
                position: 101
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/videotheque/:handle') } component={ PostsListing } />,
                position: 102
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/video-library') } component={ PostsListing } />,
                position: 110
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/videotheque') } component={ PostsListing } />,
                position: 111
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/videoteca') } component={ PostsListing } />,
                position: 112
            },
            {
                component: <Route path={ withStoreRegex('/news/:handle') } component={ NewsDetails } />,
                position: 120
            },
            {
                component: <Route path={ withStoreRegex('/dans-le-journaux/:handle') } component={ NewsDetails } />,
                position: 121
            },
            {
                component: <Route path={ withStoreRegex('/en-las-noticias/:handle') } component={ NewsDetails } />,
                position: 122
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/resources/whats-hot/:handle') } component={ WhatsHotDetails } />,
                position: 123
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/ressources/tendances/:handle') } component={ WhatsHotDetails } />,
                position: 124
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/recursos/novedades/:handle') } component={ WhatsHotDetails } />,
                position: 125
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/news') } component={ NewsListing } />,
                position: 130
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/dans-le-journaux') } component={ NewsListing } />,
                position: 131
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/en-las-noticias') } component={ NewsListing } />,
                position: 132
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/culinary/hot-new-trends') } component={ TrendsListing } />,
                position: 140
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/cuisine/nouvelles-tendances') } component={ TrendsListing } />,
                position: 141
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/culinario/nuevas-tendencias') } component={ TrendsListing } />,
                position: 142
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/resources/whats-hot') } component={ WhatsHotListing } />,
                position: 145
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/ressources/tendances') } component={ WhatsHotListing } />,
                position: 145
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/recursos/novedades') } component={ WhatsHotListing } />,
                position: 145
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/events-and-tradeshows/:handle') } component={ EventsAndTradeshowsDetails } />,
                position: 146
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/recursos/eventos-y-ferias/:handle') } component={ EventsAndTradeshowsDetails } />,
                position: 147
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/recursos/eventos-y-ferias') } component={ EventsAndTradeshowsListing } />,
                position: 148
            },
            {
            // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/events-and-tradeshows') } component={ EventsAndTradeshowsListing } />,
                position: 150
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/seasonal-inspiration/:handle') } component={ SeasonalDetails } />,
                position: 151
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/seasonal-inspiration/:handle') } component={ SeasonalDetails } />,
                position: 152
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/inspiracion-estacional/:handle') } component={ SeasonalDetails } />,
                position: 153
            },
            {
                component: <Route path={ withStoreRegex('/seasonal-inspiration') } component={ SeasonalInspiration } />,
                position: 154
            },
            {
                // eslint-disable-next-line max-len
                component: <Route path={ withStoreRegex('/inspiracion-estacional') } component={ SeasonalInspiration } />,
                position: 155
            }
        ];
    };
}

const { switchItems } = new RouterComponentPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};

export default config;
