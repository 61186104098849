/** @namespace Scandipwa/Route/SomethingWentWrong/Container/mapStateToProps */
const mapStateToProps = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    currentStoreCode: args[0].ConfigReducer.code
});

function containerProps(args, callback, instance) {
    const { currentStoreCode } = instance.props;

    return {
        ...callback.apply(instance, args),
        currentStoreCode
    };
}

export const config = {
    'Route/SomethingWentWrong/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/SomethingWentWrong/Container': {
        'member-function': {
            containerProps
        }
    }
};

export default config;
