import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CartItemPriceContainer as SourceCartItemPriceContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/CartItemPrice/CartItemPrice.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandiweb/MoninTheme/Component/CartItemPrice/Container/CartItemPriceContainer */
export class CartItemPriceContainer extends SourceCartItemPriceContainer {
    static propTypes = {
        ...this.propTypes,
        baseRowTotal: PropTypes.number.isRequired
    };

    /**
     * Overridden to add `singleItemPrice` to the containerProps.
     */
    containerProps = () => {
        const {
            getCartItemPrice,
            getCartItemSubPrice,
            currency_code,
            mix,
            singleItemPrice,
            isMobile,
            baseRowTotal,
            ...rest
        } = this.props;

        return {
            currency_code,
            mix,
            price: getCartItemPrice(rest),
            subPrice: getCartItemSubPrice(rest),
            singleItemPrice,
            isMobile,
            baseRowTotal
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemPriceContainer);
