/* eslint-disable max-len */
// Disabled to pass parameter
/* eslint-disable react/jsx-no-bind */
import Loader from 'Component/Loader';
import {
    FREE_SHIPPING_OVERLAY
} from 'Route/CartPage/CartPage.config';

import { PRODUCTION_CDN, SANDBOX_CDN } from '../../../bolt-checkout/src/component/Bolt/Bolt.config';
import { APPLE_PAY_CHECKOUT, BOLT_CHECKOUT } from './CartReducer.plugin';

export const onCheckoutActionsClick = (e, instance) => {
    const { showFreeShippingOverlay } = instance.state;
    const { showOverlay } = instance.props;

    if (showFreeShippingOverlay) {
        return;
    }

    window.boltButton = document.getElementById('bolt-wrapper')?.lastChild;
    window.boltAppleButton = document.querySelector('[data-tid="apple-pay-button"]');
    instance.setState({ showFreeShippingOverlay: true });
    showOverlay(FREE_SHIPPING_OVERLAY);
};

export const onBoltCheckoutClick = (e, instance) => {
    const { updateBoltCheckoutType } = instance.props;

    updateBoltCheckoutType(BOLT_CHECKOUT);
    onCheckoutActionsClick(e, instance);
};

export const onAppleCheckoutClick = (e, instance) => {
    const { updateBoltCheckoutType } = instance.props;

    updateBoltCheckoutType(APPLE_PAY_CHECKOUT);
    onCheckoutActionsClick(e, instance);
};

export const state = (originalMember) => ({
    ...originalMember,
    showFreeShippingOverlay: false
});

const renderActionButtonOverlay = (instance, buttonProps) => {
    const {
        id,
        style,
        onClick,
        hidden = false
    } = buttonProps;

    return (
        <button
          id={ id }
          style={ {
              position: 'absolute',
              width: '100%',
              cursor: 'pointer',
              display: `${ !hidden ? 'block' : 'none' }`,
              ...style
          } }
          aria-hidden={ hidden }
          tabIndex={ hidden ? -1 : 0 }
          onClick={ (e) => onClick(e, instance) }
          aria-label="Checkout"
        />
    );
};

export const render = (_args, _callback, instance) => {
    const {
        publishableKey,
        isPDP,
        isSandbox,
        isLoading,
        isBoltLoading,
        freeShippingAction: {
            actionEnabled,
            minCartAmount
        } = {},
        totals
    } = instance.props;

    const { showFreeShippingOverlay } = instance.state;
    const buttonUrl = `${isSandbox ? SANDBOX_CDN : PRODUCTION_CDN}v1/checkout_button`;
    const isApplePayEnabled = !!window.ApplePaySession;

    const freeShippingEnabled = !showFreeShippingOverlay
        && actionEnabled
        && totals.subtotal < minCartAmount;

    if (publishableKey) {
        if (!isPDP) {
            return (
                <div className="boltwithloader" id="bolt-wrapper">
                    <Loader isLoading={ isBoltLoading || isLoading } />
                    { renderActionButtonOverlay(
                        instance,
                        {
                            id: 'FreeShippingPopup-Bolt',
                            style: { height: '48px', top: 0, zIndex: 2 },
                            onClick: onBoltCheckoutClick,
                            hidden: !freeShippingEnabled
                        }
                    ) }
                    { renderActionButtonOverlay(
                        instance,
                        {
                            id: 'FreeShippingPopup-Bolt-Apple',
                            style: { height: '51px', bottom: 0, zIndex: 1 },
                            onClick: onAppleCheckoutClick,
                            hidden: !isApplePayEnabled
                        }
                    ) }
                    <div
                      id="bolt-button-wrapper"
                      aria-hidden={ freeShippingEnabled }
                    >
                        <object
                          aria-label="Complete order"
                          ref={ instance.boltRef }
                          data-tid="instant-bolt-checkout-button"
                          data={ `${buttonUrl}?publishable_key=${publishableKey}` }
                        />
                    </div>
                </div>
            );
        }

        // For PDP we need a different div because of how the connect.js script handles the init
        // https://help.bolt.com/merchants/guides/merchant-setup/checkout/buttons/product-page-checkout-button/#custom-cart-installation
        return (
            <div className="bolt-product-checkout-button bolt-multi-step-checkout" />
        );
    }

    return <Loader isBoltLoading={ isBoltLoading } />;
};

export const config = {
    'BoltCheckout/Component/Bolt/Component/BoltComponent': {
        'member-function': {
            render
        },
        'member-property': {
            state
        }
    }
};

export default config;
