import { PropTypes } from 'prop-types';

import {
    FieldInputContainer as SourceFieldInputContainer
} from 'SourceComponent/FieldInput/FieldInput.container';

import FieldInput from './FieldInput.component';

/** @namespace Scandipwa/Component/FieldInput/Container/FieldInputContainer */
export class FieldInputContainer extends SourceFieldInputContainer {
    static propTypes = {
        ...this.propTypes,
        size: PropTypes.number,
        isPlaceholderIncluded: PropTypes.bool,
        validationStatus: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        size: null,
        isPlaceholderIncluded: true
    };

    containerProps() {
        const {
            size,
            isPlaceholderIncluded,
            validationStatus

        } = this.props;

        return {
            ...super.containerProps(),
            size,
            isPlaceholderIncluded,
            validationStatus
        };
    }

    render() {
        const { validationStatus } = this.props;

        return (
            <FieldInput { ...this.containerProps() } validationStatus={ validationStatus } />
        );
    }
}

export default FieldInputContainer;
