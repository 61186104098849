/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import {
    FieldInput as SourceFieldInput
} from 'SourceComponent/FieldInput/FieldInput.component';

/** @namespace Scandipwa/Component/FieldInput/Component/FieldInputComponent */
export class FieldInputComponent extends SourceFieldInput {
    static propTypes = {
        ...this.propTypes,
        isPlaceholderIncluded: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isPlaceholderIncluded: true
    };

    render() {
        const {
            formRef,
            validateSeparately,
            isSubmitted,
            fileExtensions,
            filename,
            isPlaceholderIncluded,
            placeholder,
            value,
            type,
            validationStatus,
            categoryFilter,
            ...validProps
        } = this.props;
        const overriddenValue = (type === 'file') ? undefined : value;

        const { id } = validProps;

        return (
            <input
              ref={ formRef }
              // Disabled by SPWA:
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...validProps }
              tabIndex={ categoryFilter ? '-1' : '0' }
              aria-invalid={ validationStatus === false }
              placeholder={ isPlaceholderIncluded ? placeholder : undefined }
              value={ overriddenValue }
              type={ type }
              aria-describedby={ validationStatus === false ? `${ id }-error` : undefined }
            />
        );
    }
}

export default FieldInputComponent;
