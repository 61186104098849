import BreadcrumbChevronIcon from 'Component/BreadcrumbChevronIcon';
import BreadcrumbHomeIcon from 'Component/BreadcrumbHomeIcon';
import Link from 'Component/Link';
import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import { HOME } from './Breadcrumb.config';

import './Breadcrumb.override.style.scss';

/** @namespace Scandiweb/MoninTheme/Component/Breadcrumb/Component/BreadcrumbComponent */
export class BreadcrumbComponent extends SourceBreadcrumb {
    // adding render method for home svg
    renderLinkItem() {
        const { name } = this.props;

        if (name === HOME) {
            return <BreadcrumbHomeIcon />;
        }

        return (
            <span itemProp="name">
                { this.renderName() }
            </span>
        );
    }

    /**
    *  Override to change chevron icon.
    */
    renderLink() {
        const {
            index,
            isDisabled
        } = this.props;

        const url = this.getLinkUrl() || {};

        return (
            <Link
              block="Breadcrumb"
              elem="Link"
              to={ url }
              tabIndex={ isDisabled ? '-1' : '0' }
            >
                <meta itemProp="item" content={ window.location.origin + url.pathname } />
                { this.renderLinkItem() }
                <BreadcrumbChevronIcon />
                <meta itemProp="position" content={ index } />
            </Link>
        );
    }
}

export default BreadcrumbComponent;
