/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package monin
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import BackToTop from 'Component/BackToTop';
import {
    RouterComponent as BaseRouter
} from 'SourceComponent/Router/Router.component';

import {
    AFTER_ITEMS_TYPE,
    BACK_TO_TOP,
    SWITCH_ITEMS_TYPE,
    TRACK_MY_ORDER,
    VIEW_ALL_RECIPES,
    VIEW_WORDPRESS_BLOG
} from './Router.config';

/** @namespace Scandipwa/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

// eslint-disable-next-line max-len
export const TrackMyOrderPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/TrackMyOrder'));
export const ViewAllRecipes = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "compare" */
    'Route/ViewAllRecipes'
));
export const WordPress = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "wordpress" */
    'Route/WordPress'
));

/** @namespace Scandipwa/Component/Router/Component/RouterComponent */
export class RouterComponent extends BaseRouter {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [SWITCH_ITEMS_TYPE] = [
        ...this.SWITCH_ITEMS_TYPE,
        {
            // eslint-disable-next-line max-len,react/jsx-no-bind,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/tracking') } exact render={ (props) => <TrackMyOrderPage { ...props } /> } />,
            position: 400,
            name: TRACK_MY_ORDER
        },
        {
            component: <Route
              path={ withStoreRegex('/recipes/with/product/id/:id?') }
            // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <ViewAllRecipes { ...props } /> }
            />,
            position: 500,
            name: VIEW_ALL_RECIPES
        },
        {
            component: <Route
              path={ withStoreRegex('/blog/:url?') }
            // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <WordPress { ...props } /> }
            />,
            position: 700,
            name: VIEW_WORDPRESS_BLOG
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [AFTER_ITEMS_TYPE] = [
        ...this.AFTER_ITEMS_TYPE,
        {
            component: <BackToTop />,
            position: 30,
            name: BACK_TO_TOP
        }
    ];

    renderRouterContent() {
        const { hasError } = this.state;

        if (hasError) {
            return this.renderErrorRouterContent();
        }

        return this.renderDefaultRouterContent();
    }
}

export default RouterComponent;
