Mosaic.setPlugins([require('./plugin/ConfigQuery.plugin.js'),
require('./plugin/MyAccountQuery.plugin.js'),
require('./plugin/NewVersionPopup.plugin.js'),
require('./plugin/ProductConfigurableAttributesContainer.plugin.js'),
require('./plugin/ProductPageComponent.plugin.js'),
require('./plugin/RouterComponent.plugin.js'),
require('./plugin/RouterContainerMDTP.plugin.js'),
require('./plugin/SomethingWentWrongContainer.plugin.js'),
require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/ada-video/src/plugin/component/ProductGallery.component.plugin.js'),
require('./../packages/ada-video/src/plugin/component/WidgetFactory.component.plugin.js'),
require('./../packages/product-alert-guest/src/plugin/ProductConfigurableAttributesComponent.plugin.js'),
require('./../packages/product-alert-guest/src/plugin/ProductConfigurableAttributesContainer.plugin.js'),
require('./../packages/monin-tiered-price-fix/src/plugin/Request.plugin.js'),
require('./../packages/scandipwa-flexibleforms/src/plugin/FieldComponent.plugin.js'),
require('./../packages/scandipwa-flexibleforms/src/plugin/WidgetFactoryComponent.plugin.js'),
require('./../packages/shopgate/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/shopgate/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/shopgate/src/plugin/RouterComponent.plugin.js'),
require('./../packages/payflowpro/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/payflowpro/src/plugin/CheckoutContainer.plugin.js'),
require('./../packages/payflowpro/src/plugin/CheckoutPayment.plugin.js'),
require('./../packages/payflowpro/src/plugin/CheckoutPaymentContainer.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/AmastyCCPARouterContainer.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/AmastyCCPAStoreReducer.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/CheckoutBillingComponent.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/ContactFormComponent.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/MyAccountCreateAccountComponent.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/MyAccountCreateAccountContainer.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/MyAccountNewsletterSubscriptionComponent.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/MyAccout.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/NewsletterSubscription.component.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/NewsletterSubscription.container.plugin.js'),
require('./../packages/scandipwa-ccpa/src/plugin/Router.Component.plugin.js'),
require('./../packages/gtm-new/src/plugin/BoltContainer.plugin.js'),
require('./../packages/gtm-new/src/plugin/CartItem.container.plugin.js'),
require('./../packages/gtm-new/src/plugin/CartItemChangeQuantity.plugin.js'),
require('./../packages/gtm-new/src/plugin/CartPage.container.plugin.js'),
require('./../packages/gtm-new/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/gtm-new/src/plugin/CheckoutPurchasePrepare.plugin.js'),
require('./../packages/gtm-new/src/plugin/CheckoutShippingContainer.plugin.js'),
require('./../packages/gtm-new/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/gtm-new/src/plugin/HandleCustomerDataOnInit.plugin.js'),
require('./../packages/gtm-new/src/plugin/MyAccountQuery.plugin.js'),
require('./../packages/gtm-new/src/plugin/NoMatch.plugin.js'),
require('./../packages/gtm-new/src/plugin/ProductClick.plugin.js'),
require('./../packages/gtm-new/src/plugin/ProductList.plugin.js'),
require('./../packages/gtm-new/src/plugin/ProductListPageComponent.plugin.js'),
require('./../packages/gtm-new/src/plugin/ProductListQuery.plugin.js'),
require('./../packages/gtm-new/src/plugin/ProductPageContainer.plugin.js'),
require('./../packages/gtm-new/src/plugin/RouterComponentPlugin.plugin.js'),
require('./../packages/gtm-new/src/plugin/RouterContainerMDTP.plugin.js'),
require('./../packages/gtm-new/src/plugin/SearchFieldComponent.plugin.js'),
require('./../packages/gtm-new/src/plugin/SearchItemContainer.plugin.js'),
require('./../packages/gtm-new/src/plugin/SearchOverlayComponent.plugin.js'),
require('./../packages/gtm-new/src/plugin/StoreReducer.plugin.js'),
require('./../packages/gtm-new/src/plugin/WishlistItemComponent.plugin.js'),
require('./../packages/gtm-new/src/plugin/addToCart.plugin.js'),
require('./../packages/gtm-new/src/plugin/myAccountLoginRegister.plugin.js'),
require('./../packages/gtm-new/src/plugin/wishlistItemAddToCart.plugin.js'),
require('./../packages/monin-schema/src/plugin/CmsPageComponent.plugin.js'),
require('./../packages/monin-schema/src/plugin/CmsPageContainer.plugin.js'),
require('./../packages/monin-schema/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/MoninProductTabsComponent.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/MoninProductTabsContainer.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductActions.component.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductActions.container.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductCard.component.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductCard.container.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductList.query.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductPage.component.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductPage.container.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/ProductTabs.component.plugin.js'),
require('./../packages/yotpo-reviews-spwa5/src/plugin/YotpoStore.plugin.js'),
require('./../packages/scandipwa-social-login/src/plugin/ConfigStatusQuery.plugin.js'),
require('./../packages/scandipwa-social-login/src/plugin/MyAccountSignInComponent.plugin.js'),
require('./../packages/scandipwa-social-login/src/plugin/MyAccountSignInMSTP.plugin.js'),
require('./../packages/scandipwa-pinterest-share/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/scandipwa-pinterest-share/src/plugin/SocialMediaLinks.component.plugin.js'),
require('./../packages/scandipwa-promotions/src/plugin/CartCouponContainer.plugin.js'),
require('./../packages/scandipwa-promotions/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/scandipwa-promotions/src/plugin/FooterComponent.plugin.js'),
require('./../packages/scandipwa-promotions/src/plugin/FooterContainerMSTP.plugin.js'),
require('./../packages/scandipwa-promotions/src/plugin/StoreReducer.plugin.js'),
require('./../packages/paypal/src/plugin/Cart.query.plugin.js'),
require('./../packages/paypal/src/plugin/CartOverlay.component.plugin.js'),
require('./../packages/paypal/src/plugin/CartPage.component.plugin.js'),
require('./../packages/paypal/src/plugin/Checkout.component.plugin.js'),
require('./../packages/paypal/src/plugin/Checkout.container.plugin.js'),
require('./../packages/paypal/src/plugin/Checkout.query.plugin.js'),
require('./../packages/paypal/src/plugin/CheckoutBilling.component.plugin.js'),
require('./../packages/paypal/src/plugin/CheckoutBilling.container.plugin.js'),
require('./../packages/paypal/src/plugin/CheckoutPayments.component.plugin.js'),
require('./../packages/paypal/src/plugin/CheckoutPayments.container.plugin.js'),
require('./../packages/paypal/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/paypal/src/plugin/Router.container.plugin.js'),
require('./../packages/paypal/src/plugin/StoreStaticReducers.plugin.js'),
require('./../packages/blog/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/blog/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/blog/src/plugin/Image.plugin.js'),
require('./../packages/blog/src/plugin/RouterComponent.plugin.js'),
require('./../packages/blog/src/plugin/RouterContainer.plugin.js'),
require('./../packages/blog/src/plugin/StoreIndex.plugin.js'),
require('./../packages/page-builder/src/plugin/CmsPage.container.plugin.js'),
require('./../packages/page-builder/src/plugin/Html.component.plugin.js'),
require('./../packages/clerkio/src/plugin/exitintent/Router.plugin.js'),
require('./../packages/clerkio/src/plugin/liveSearch/SearchField.plugin.js'),
require('./../packages/clerkio/src/plugin/results/SearchPage.plugin.js'),
require('./../packages/clerkio/src/plugin/tracking/BoltSuccessComponent.plugin.js'),
require('./../packages/clerkio/src/plugin/tracking/BrowserDatabase.plugin.js'),
require('./../packages/clerkio/src/plugin/tracking/Cart.dispatcher.plugin.js'),
require('./../packages/clerkio/src/plugin/tracking/Checkout.plugin.js'),
require('./../packages/clerkio/src/plugin/tracking/ProductCard.plugin.js'),
require('./../packages/clerkio/src/plugin/tracking/ProductPage.plugin.js'),
require('./../packages/clerkio/src/plugin/widget/CategoryPage.plugin.js'),
require('./../packages/clerkio/src/plugin/widget/CmsPage.plugin.js'),
require('./../packages/clerkio/src/plugin/widget/ProductLinks.plugin.js'),
require('./../packages/checkoutactions/src/plugin/BoltComponent.plugin.js'),
require('./../packages/checkoutactions/src/plugin/BoltContainer.plugin.js'),
require('./../packages/checkoutactions/src/plugin/CartPageContainer.plugin.js'),
require('./../packages/checkoutactions/src/plugin/CartReducer.plugin.js'),
require('./../packages/checkoutactions/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/checkoutactions/src/plugin/RouterComponent.plugin.js'),
require('./../packages/checkoutactions/src/plugin/StoreReducer.plugin.js'),
require('./../packages/zendesk/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/zendesk/src/plugin/HtmlComponent.plugin.js'),
require('./../packages/zendesk/src/plugin/RouterComponent.plugin.js'),
require('./../packages/scandipwa-amasty-labels/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/scandipwa-amasty-labels/src/plugin/ProductCardPlugins/ProductCardComponent.plugin.js'),
require('./../packages/scandipwa-amasty-labels/src/plugin/ProductGalleryPlugins/ProductGalleryComponent.plugin.js'),
require('./../packages/scandipwa-amasty-labels/src/plugin/ProductListPlugins/ProductListCDU.plugin.js'),
require('./../packages/scandipwa-amasty-labels/src/plugin/ProductListPlugins/ProductListMDTP.plugin.js'),
require('./../packages/scandipwa-amasty-labels/src/plugin/ProductListPlugins/ProductListMSTP.plugin.js'),
require('./../packages/scandipwa-amasty-labels/src/plugin/StoreReducer.plugin.js'),
require('./../packages/monin-clienttype/src/plugin/MyAccountCreateAccount.component.plugin.js'),
require('./../packages/monin-clienttype/src/plugin/MyAccountCreateAccount.container.plugin.js'),
require('./../packages/monin-clienttype/src/plugin/NewsletterSubscription.component.plugin.js'),
require('./../packages/monin-clienttype/src/plugin/NewsletterSubscription.container.plugin.js'),
require('./../packages/monin-clienttype/src/plugin/NewsletterSubscription.dispatcher.plugin.js'),
require('./../packages/monin-clienttype/src/plugin/NewsletterSubscription.query.plugin.js'),
require('./../packages/address-verification/src/plugin/CheckoutAddressForm.component.plugin.js'),
require('./../packages/address-verification/src/plugin/CheckoutAddressForm.container.plugin.js'),
require('./../packages/address-verification/src/plugin/MyAccountAddressFormContainer.plugin.js'),
require('./../packages/scandipwa-yotpo-ugc/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/scandipwa-yotpo-ugc/src/plugin/WidgetFactoryComponent.plugin.js'),
require('./../packages/gdpr/gdpr-cookie/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/gdpr/gdpr-cookie/src/plugin/Router.plugin.js'),
require('./../packages/gdpr/gdpr-cookie/src/plugin/StoreIndex.plugin.js'),
require('./../packages/gdpr/src/plugin/Checkout.plugin.js'),
require('./../packages/gdpr/src/plugin/CheckoutBilling.plugin.js'),
require('./../packages/gdpr/src/plugin/CheckoutGuestForm.plugin.js'),
require('./../packages/gdpr/src/plugin/CheckoutShipping.plugin.js'),
require('./../packages/gdpr/src/plugin/Html.plugin.js'),
require('./../packages/gdpr/src/plugin/MyAccount.plugin.js'),
require('./../packages/gdpr/src/plugin/MyAccountCreateAccount.plugin.js'),
require('./../packages/gdpr/src/plugin/Notification.plugin.js'),
require('./../packages/gdpr/src/plugin/Router.plugin.js'),
require('./../packages/monin-pumps/src/plugin/CartItem.component.plugin.js'),
require('./../packages/monin-pumps/src/plugin/CartItem.container.plugin.js'),
require('./../packages/monin-pumps/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/monin-pumps/src/plugin/Popup.container.plugin.js'),
require('./../packages/monin-pumps/src/plugin/ProductList.query.plugin.js'),
require('./../packages/monin-pumps/src/plugin/Router.container.plugin.js'),
require('./../packages/monin-pumps/src/plugin/StoreReducer.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/AddToCart.container.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/CartItem.container.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/CartOverlay.component.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/CartOverlay.container.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/CheckoutPayments.component.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/ProductActions.component.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/ProductActions.container.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/component/Router.component.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/query/Config.query.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/route/CartPage.component.plugin.js'),
require('./../packages/bolt-checkout/src/plugin/route/CartPage.container.plugin.js'),
require('./../packages/links-to-product/src/plugin/component/ProductActions.component.plugin.js'),
require('./../packages/links-to-product/src/plugin/query/ProductList.query.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { render } from 'react-dom';

import App from 'Component/App';

import addPolyfills from './util/polyfill/polyfills';

import 'Util/Polyfill';
import 'Style/main';

// TODO: move this out to i18-runtime
PropTypes.string = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
]);

addPolyfills();

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
