/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-len */

import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import ClerkProductCardPopup from './ClerkProductCardPopup/ClerkProductCardPopup.container';
import FilterIcon from 'Component/FilterIcon';
import Link from 'Component/Link';
import { appendWithStoreCode } from 'Util/Url';

import { ResultsContext } from '../context/Results/Results.context';

import './Results.style';

/** @namespace Scandiweb/Clerk/Component/Results/Results */
export const Results = () => {
    const {
        targetId,
        spanAttributes
    } = useContext(ResultsContext);

    if (!spanAttributes) {
        return null;
    }

    return (
        <>
            <ClerkProductCardPopup />
            <div id={ targetId } />
            { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */ }
            <span { ...spanAttributes } />
        </>
    );
};

/** @namespace Scandiweb/Clerk/Component/Results/renderFilterButton */
export const renderFilterButton = (itemCount) => {
    const noItems = itemCount > 0 ? '' : 'ClerkNoItems';
    return (
            <button
              block={ `CategoryPage-Filter ${ noItems}` }
              id="clerk-search-filters-button"
            >
                <FilterIcon />
                <span>{ __('Filters').toString() }</span>
            </button>
    );
};

/** @namespace Scandiweb/Clerk/Component/Results/renderNoItemsFound */
export const renderNoItemsFound = (itemCount, isRecipe) => {
    const categoryOpened = isRecipe ? __('recipes').toString() : __('products').toString();

    if (itemCount === 0) {
        return (
            <p className="CategoryPage-ItemsCount">{ __('No ').toString() + categoryOpened + __(' found').toString() }</p>
        );
    }

    return null;
};

/** @namespace Scandiweb/Clerk/Component/Results/renderTabs */
export const renderTabs = (itemCount, searchValue, isRecipe) => (
        <div block="CategoryPage" elem="TabsWrapper">
            <div block="CategoryPage" elem="Tabs">
                <Link to={ appendWithStoreCode(`search/${ searchValue }?products`) }>
                    <div className={ `CategoryPage-Tab Products${ !isRecipe ? ' active' : ''}` }>
                        { __('Products').toString() }
                    </div>
                </Link>
                <Link to={ appendWithStoreCode(`search/${ searchValue }?recipes`) }>
                    <div className={ `CategoryPage-Tab Recipe${ isRecipe ? ' active' : ''}` }>{ __('Recipes').toString() }</div>
                </Link>
            </div>

            { renderNoItemsFound(itemCount, isRecipe) }
        </div>
);

/** @namespace Scandiweb/Clerk/Component/Results/ResultsItems */
export const ResultsItems = (_isMobile) => {
    const {
        itemCount,
        // noResultsText,
        searchValue
    } = useContext(ResultsContext);

    const location = useLocation();
    const { search } = location;
    const isRecipe = search.includes('?recipes');

    // const labelMap = {
    //     0: () => noResultsText,
    //     '-1': () => __('Products are loading...').toString()
    // };

    // const label = labelMap[itemCount] ? labelMap[itemCount]() : __('%s items found', itemCount);

    return (
        <>
            { renderTabs(itemCount, searchValue, isRecipe) }
            { renderFilterButton(itemCount) }
        </>
    );
};

/** @namespace Scandiweb/Clerk/Component/Results/Facets */
export const Facets = () => {
    const {
        itemCount
        // noResultsText
    } = useContext(ResultsContext);

    if (itemCount === 0) {
        return null;
    }

    return (
        <div block="CategoryFilterOverlay ClerkFilters" id="clerk-search-filters" />
    );
};
