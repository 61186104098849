import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductTabs from 'Component/ProductTabs/ProductTabs.component';
import { getFixedElementHeight } from 'Util/CSS';

/** @namespace Scandiweb/MoninTheme/Component/ProductTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandiweb/MoninTheme/Component/ProductTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandiweb/MoninTheme/Component/ProductTabs/Container/ProductTabsContainer */
export class ProductTabsContainer extends PureComponent {
    static propTypes = {
        tabs: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                render: PropTypes.func.isRequired
            })
        ).isRequired,
        defaultTab: PropTypes.number,
        isMobile: PropTypes.bool.isRequired,
        getTabNameByKey: PropTypes.func.isRequired,
        tabNameToExpand: PropTypes.string.isRequired,
        isScrollToggled: PropTypes.bool.isRequired,
        handleOnProductTabFormButtonClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        defaultTab: 0
    };

    productTabsWrapper = createRef(null);

    containerFunctions = {
        setProductTabsRef: this.setProductTabsRef.bind(this),
        scrollToProductTab: this.scrollToProductTab.bind(this)
    };

    setProductTabsRef(element) {
        this.productTabsWrapper = element;
    }

    scrollToProductTab() {
        if (!this.productTabsWrapper) {
            return;
        }

        const elemToWindowTopDist = this.productTabsWrapper.getBoundingClientRect().top;
        const windowToPageTopDist = document.body.getBoundingClientRect().top;

        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;

        const { top: topFixedHeight } = getFixedElementHeight();

        const scrollTo = topToElemDistance - (topFixedHeight + this.productTabsWrapper.offsetHeight);

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    containerProps() {
        const {
            tabs,
            defaultTab,
            isMobile,
            getTabNameByKey,
            tabNameToExpand,
            isScrollToggled,
            handleOnProductTabFormButtonClick
        } = this.props;

        return {
            tabs,
            defaultTab,
            isMobile,
            getTabNameByKey,
            tabNameToExpand,
            isScrollToggled,
            handleOnProductTabFormButtonClick
        };
    }

    render() {
        return (
            <ProductTabs
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductTabsContainer);
