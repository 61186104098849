import Html from 'SourceComponent/Html';

export class HtmlCode extends React.PureComponent {
    renderElement(element) {
        if (typeof element === "string") {
            return (
                <>
                    {!!element ? <Html content={element} /> : null}
                </>
            );
        }

        return element;
    }

    render() {
        const {
            BaseHtmlCode
        } = this.props.elements;

        const html = BaseHtmlCode.childEleBag[0]

        if (Array.isArray(html)) {
            return (
                <BaseHtmlCode.Ele>
                    { html.map(this.renderElement) }
                </BaseHtmlCode.Ele>
            );
        }
        return (
            <BaseHtmlCode.Ele>
            {typeof html === "object" ? html : <Html content={html} />}
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCode
