import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import Loader from 'Component/Loader';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED
} from 'Util/Product';

import './ProductCard.override.style';

/** @namespace Scandiweb/MoninTheme/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...this.propTypes,
        isProductLinks: PropTypes.bool.isRequired,
        isWidget: PropTypes.bool.isRequired
    };

    /**
     * Overridden to change methods and its order
     */
    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderAdditionalProductDetails() }
                        { this.renderMainDetails() }
                        { this.renderProductPrice() }
                        { this.renderReviews() }
                    </div>
                    <div>
                        { this.renderAddToCart() }
                    </div>
                </>
            ))
        );
    }

    /**
     * Conditionally render additional content
     */
    renderChildren() {
        const { children } = this.props;

        if (!children) {
            return null;
        }

        return (
            <div block="ProductCard" elem="AdditionalContent">
                { children }
            </div>
        );
    }

    /**
     * Overridden to change layout
     */
    renderCardListContent() {
        const {
            layout, renderContent
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderMainDetails() }
                        { this.renderAdditionalProductDetails() }
                        { this.renderReviews() }
                        { this.renderChildren() }
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        { this.renderProductPrice() }
                        { this.renderConfigurableOptions() }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        { this.renderAddToCart() }
                    </div>
                </div>
            </div>
        ));
    }

    /**
     * Overriden to move ProductCard-Elem div to renderBrandValue
     */
    renderBrandValue() {
        const {
            getAttribute,
            siblingsHaveBrands,
            setSiblingsHaveBrands
        } = this.props;
        const {
            product_list_content: {
                attribute_to_display
            } = {}
        } = window.contentConfiguration;
        const brand = getAttribute(attribute_to_display || 'brand');

        if (!brand) {
            return null;
        }

        if (!siblingsHaveBrands) {
            setSiblingsHaveBrands();
        }

        return (
            <div block="ProductCard" elem="Brand">
                <ProductAttributeValue
                  attribute={ brand }
                  isFormattedAsText
                  mix={ {
                      block: 'ProductCard',
                      elem: 'BrandAttributeValue'
                  } }
                />
            </div>
        );
    }

    /**
     * Overriden to move ProductCard-Elem div to renderBrandValue
     */
    renderAdditionalProductDetails() {
        return this.renderBrandValue();
    }

    /**
     * Overridden to add span wrapping "add to cart"
     * and to not render it in recently viewed list
     */
    renderAddToCart() {
        const {
            product,
            product: {
                type_id,
                options = [],
                configurable_options: confOptions = {}
            },
            configurableVariantIndex,
            layout,
            showSelectOptionsNotification,
            inStock
        } = this.props;

        const quantity = 1;
        const groupedProductQuantity = {};

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions
        };

        const redirectOnConfig = type_id === CONFIGURABLE
            && Object.keys(confOptions).length !== Object.keys(this.getAttributesToShow()).length;

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button
                  block="Button AddToCart"
                  mods={ { layout } }
                  onClick={ showSelectOptionsNotification }
                >
                    <span>{ __('Add to cart') }</span>
                </button>
            );
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
              disabled={ !inStock }
              layout={ layout }
            />
        );
    }

    /**
     * Overridden so it doesn't render on product links
     */
    renderPrice() {
        const { isProductLinks } = this.props;

        if (isProductLinks) {
            return null;
        }

        return super.renderPrice();
    }

    /**
     * Overridden so it doesn't render on product links
     */
    renderReviews() {
        const { isProductLinks } = this.props;

        if (isProductLinks) {
            return null;
        }

        return super.renderReviews();
    }

    /**
     * Overridden to pass isProductLinks, isWidget, and isRecentlyViewed mods
     */
    render() {
        const {
            children,
            mix,
            isLoading,
            layout,
            isProductLinks,
            isWidget,
            isRecentlyViewed
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ {
                  layout, isProductLinks, isWidget, isRecentlyViewed
              } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
