/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { AFTER_ITEMS_TYPE } from 'Component/Router/Router.config';

import GdprCookieGroupPopup from '../component/GdprCookieGroupPopup';
import GdprCookiePopup from '../component/GdprCookiePopup';

export const GDPR_COOKIE_GROUP_POPUP = 'GdprCookieGroupPopup';
export const GDPR_COOKIE_POPUP = 'GdprCookiePopup';
export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addGdprCookiePopup = (originalMember) => {
    const allComponentNames = originalMember.map(({ name }) => name);

    if (allComponentNames.includes(GDPR_COOKIE_GROUP_POPUP)) {
        return originalMember;
    }

    const maxPosition = Math.max(
        ...originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    return [
        ...originalMember,
        {
            component: <GdprCookiePopup />,
            position: maxPosition + INCREASED_POSITION,
            name: GDPR_COOKIE_POPUP
        },
        {
            component: <GdprCookieGroupPopup />,
            position: maxPosition + INCREASED_POSITION + INCREASED_POSITION,
            name: GDPR_COOKIE_GROUP_POPUP
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [AFTER_ITEMS_TYPE]: addGdprCookiePopup
        }
    }
};
