import FieldSelect from 'Component/FieldSelect/FieldSelect.component';

/** @namespace Scandipwa/Component/ProductActionsDropdown/Component/ProductActionsDropdownComponent */
export class ProductActionsDropdownComponent extends FieldSelect {
    renderNativeSelect() {
        const {
            name,
            id,
            onChange,
            formRef,
            value,
            option,
            isDisabled,
            isSelectExpanded: isExpanded,
            autocomplete,
            skipValue,
            removeAppearance
        } = this.props;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              autoComplete={ autocomplete }
              mods={ { isExpanded } }
              mix={ { block: 'ProductActionsDropdown' } }
              ref={ (node) => {
                  formRef.current = node;
                  removeAppearance(node);
              } }
              name={ name }
              id={ id }
              option={ option }
              disabled={ isDisabled }
               // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              value={ value }
              onChange={ onChange }
              data-skip-value={ skipValue }
              aria-label={ __('Select drop-down').toString() }
              style={ { appearance: 'none !important' } }
            >
                 { this.renderPlaceholder() }
                 { this.renderNativeOption(option) }
            </select>
        );
    }

    renderNativeOption = (option) => {
        const {
            id,
            value,
            disabled
        } = option;

        return (
             <option
               block="FieldSelect"
               elem="NativeOption"
               key={ id }
               id={ id }
               value={ value }
               disabled={ disabled }
             >
                 { this.renderNativeOptionLabel(option) }
             </option>

        );
    };

    renderNativeOptionLabel(option) {
        const { label, stock_status } = option;

        if (stock_status === 'OUT_OF_STOCK') {
            return `${label} (${__('OUT OF STOCK').toString()})`;
        }

        return label;
    }
}

export default ProductActionsDropdownComponent;
