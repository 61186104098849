/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getGuestQuoteId } from 'Util/Cart';

import ClerkWidgetQuery from '../query/ClerkWidget.query';
import { getClerk } from '../util/config';
import { useWidgetResults } from '../util/hooks';

export const PRODUCT_WIDGET = 'product';
export const CATEGORY_WIDGET = 'category';
export const CART_WIDGET = 'cart';

/** @namespace Scandiweb/Clerk/Component/Widgets/Widget */
export const Widget = ({ type }) => {
    const location = useLocation();
    const [id, setId] = useState(null);
    const [query, setQuery] = useState(null);
    const productId = useSelector((state) => state.ProductReducer?.product?.id);
    const categoryId = useSelector((state) => state.CategoryReducer?.category?.id);
    const results = useWidgetResults(query);

    const removePreviousIfPresent = () => {
        const spanE = document.querySelector(`#${id} .clerk`);

        if (spanE) {
            spanE.removeAttribute('data-clerk-content-id');
            // ^^^ this resets the content id, clerk re-initializes the element
        }
    };

    const renderResults = async () => {
        const clerk = await getClerk();
        clerk('content', `#${id} .clerk`);
    };

    const updateQuery = async () => {
        if (type === CART_WIDGET) {
            setQuery(ClerkWidgetQuery.getCartQuery({ guestCartId: getGuestQuoteId() }));
        } else if (type === PRODUCT_WIDGET && productId) {
            setQuery(ClerkWidgetQuery.getProductQuery({ productId }));
        } else if (type === CATEGORY_WIDGET && categoryId) {
            setQuery(ClerkWidgetQuery.getCategoryQuery({ categoryId }));
        }
    };

    useEffect(() => {
        updateQuery();
    }, [location, categoryId, productId]);

    useEffect(() => {
        // eslint-disable-next-line no-magic-numbers
        setId(`a${ Math.random().toString(36).substring(2, 15)}`);
        updateQuery();
    }, []);

    useEffect(() => {
        if (results) {
            removePreviousIfPresent();
            renderResults();
        }
    }, [results]);

    if (!results) {
        return null;
    }

    return (
        <span
          block="Widget"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: results.spanHtml } }
          id={ id }
        />
    );
};

Widget.propTypes = {
    type: PropTypes.string.isRequired
};
