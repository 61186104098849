/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Payflowpro/Query/PayFlowPro/Query/PayFlowProQuery */
export class PayFlowProQuery {
    createToken(input) {
        return new Field('createPayflowProToken')
            .addArgument('input', 'PayflowProTokenInput!', input)
            .addFieldList(this._getTokenFields())
            .setAlias('payFlowPro');
    }

    _getTokenFields() {
        return [
            'secure_token',
            'secure_token_id',
            'response_message',
            'result',
            'result_code',
            'form_url'
        ];
    }

    handlePayflowProResponse(input) {
        return new Field('handlePayflowProResponse')
            .addArgument('input', 'PayflowProResponseInput!', input)
            .addField(this._getCartField())
            .setAlias('payflowResponse');
    }


    _getCartField() {
        return new Field('cart')
            .addFieldList(this._getCartFieldList());
    }

    _getCartFieldList() {
        return [
            'id'
        ];
    }
}

export default new PayFlowProQuery();
