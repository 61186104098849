/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import GoogleTagManager from '../component/GoogleTagManager';

export const GOOGLE_TAG_MANAGER = 'GoogleTagManager';
export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

const addGoogleTagManager = (originalMember) => {
    const allComponentNames = originalMember.map(({ name }) => name);

    if (allComponentNames.includes(GOOGLE_TAG_MANAGER)) {
        return originalMember;
    }

    const maxPosition = Math.max(
        ...originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...originalMember,
        {
            component: <GoogleTagManager />,
            position: maxPosition + INCREASED_POSITION,
            name: GOOGLE_TAG_MANAGER
        }
    ];
};

export const config = {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addGoogleTagManager
        }
    }
};

export default config;
