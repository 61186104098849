/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CLERK_SEARCH_WRAPPER_ID, DEFAULT_SEARCH_INPUT_ID } from '../util/config';
import { useClerkInitById, withConfig } from '../util/hooks';
import { setSearchResultsPosition } from '../util/htmlHelpers';

/** @namespace Scandiweb/Clerk/Component/LiveSearch/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandiweb/Clerk/Component/LiveSearch/mapDispatchToProps */
export const mapDispatchToProps = () => {};

/** @namespace Scandiweb/Clerk/Component/LiveSearch/LiveSearch */
export const LiveSearch = withConfig(({
    closeSearch,
    isActive,
    isMobile,
    clerkConfig
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const location = useLocation();
    useClerkInitById(`#${CLERK_SEARCH_WRAPPER_ID}`);
    const { clerkLiveSearchConfig: config } = clerkConfig;

    useEffect(() => {
        const config = { attributes: true, childList: true, subtree: true };

        const observer = new MutationObserver(() => {
            const clerkElem = document.getElementById(CLERK_SEARCH_WRAPPER_ID);
            const rootElem = document.getElementById('root');

            if (!clerkElem || rootElem.contains(clerkElem)) {
                return;
            }

            const isSearchOpened = clerkElem.classList.contains('clerk-instant-search-visible');

            if (isOpened !== isSearchOpened) {
                setIsOpened((isOpened) => !isOpened);
                setSearchResultsPosition();
            }
        });

        observer.observe(document.body, config);

        return () => {
            observer.disconnect();
        };
    }, [isOpened, isMobile]);

    useEffect(
        () => {
            // vvv hide search, if URL changes
            closeSearch();
        },
        [location]
    );

    useEffect(
        () => {
            // handle visibility of search overlay
            const wrapper = document.getElementById(CLERK_SEARCH_WRAPPER_ID);

            if (wrapper) {
                // vvv controlling with DOM, as className is managed by Clerk.io
                if (isActive) {
                    wrapper.classList.add('clerk-instant-search-visible');
                    wrapper.classList.remove('clerk-instant-search-hidden');
                } else {
                    wrapper.classList.remove('clerk-instant-search-visible');
                    wrapper.classList.add('clerk-instant-search-hidden');
                }
            }
        },
        [isActive]
    );

    // console.log(123, isOpened, isActive);

    return config && (
        <span
          id={ CLERK_SEARCH_WRAPPER_ID }
          data-template={ `@${config.liveSearchTemplate}` }
          data-instant-search-suggestions={ config.suggestionsCount }
          data-instant-search-categories={ config.categoriesCount }
          data-instant-search-pages={ config.pagesCount }
          data-instant-search-pages-type={ config.pagesType }
          data-instant-search-positioning={ config.positioning }
          // data-instant-search={ config.inputSelector || `#${DEFAULT_SEARCH_INPUT_ID}` }
          data-instant-search={ `#${DEFAULT_SEARCH_INPUT_ID}` }
          // ^^^ using hardcoded selector for now
        />

    );
});

LiveSearch.propTypes = {
    closeSearch: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveSearch);
