/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CROSS_SELL, RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

import { CART_WIDGET, PRODUCT_WIDGET, Widget } from '../../component/Widgets';

// vvv This adds support for cart and product page widget

const renderClerkWidget = (_args, _callback, instance) => {
    const { linkType } = instance.props;

    const typeMap = {
        [RELATED]: PRODUCT_WIDGET,
        [CROSS_SELL]: CART_WIDGET
    };

    const type = typeMap[linkType];

    if (!type) {
        return null;
    }

    return <Widget type={ type } />;
};

export default {
    'Component/ProductLinks/Container': {
        'member-function': {
            render: renderClerkWidget

        }
    }
};
