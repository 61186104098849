import { caCodeRegExpArr } from 'Component/CheckoutAddressForm/CheckoutAddressForm.config';
import { CANADA_STORE_CODE } from 'Component/Footer/Footer.config';

/* eslint-disable no-undef */
/* eslint-disable fp/no-let */
/** @namespace Component/CheckoutAddressForm/Component */
const state = (originalMember) => ({
    ...originalMember,
    street0: {},
    street1: {}
});

export const componentDidUpdate = (args, callback, instance) => {
    const {
        countryId,
        regionId,
        region,
        city,
        postcode,
        street0 = {},
        street1 = {}
    } = instance.state;

    // eslint-disable-next-line no-unused-vars
    const [prevProps, prevState] = args;

    const {
        countryId: prevCountryId,
        regionId: prevRegionId,
        region: prevRegion,
        city: prevCity,
        postcode: prevPostcode,
        street0: prevStreet = {},
        street1: prevStreet1 = {}
    } = prevState;

    if (
        countryId !== prevCountryId
        || regionId !== prevRegionId
        || city !== prevCity
        || region !== prevRegion
        || postcode !== prevPostcode
        || street0.value !== prevStreet.value
        || street1.value !== prevStreet1.value
    ) {
        instance.estimateShippingDebounced();
    }
};

export const estimateShipping = (args, callback, instance) => {
    const { onShippingEstimationFieldsChange, code } = instance.props;

    let region = '';
    const {
        countryId,
        regionId,
        city,
        postcode,
        street0 = {},
        street1 = {},
        availableRegions = []
    } = instance.state;

    if (regionId !== null) {
        region = availableRegions.find((regionObj) => regionObj.id === regionId)?.name;
    } else {
        region = availableRegions[0]?.name ?? '';
    }

    const fullStreet = `${street0.value ? street0.value : ''} ${street1.value ? street1.value : ''}`;
    const region_id = regionId == null ? availableRegions[0].id : regionId;
    const caPostcodeRegExp = new RegExp(
        `^${caCodeRegExpArr.join('')}$`,
        'i'
    );

    const isPostcodeValid = code !== CANADA_STORE_CODE
        || caPostcodeRegExp.test(postcode);

    if (
        !region_id
        || !countryId
        || !postcode
        || !isPostcodeValid
    ) {
        return;
    }

    onShippingEstimationFieldsChange({
        country_id: countryId,
        region_id,
        region,
        city,
        postcode,
        street: [fullStreet],
        telephone: ''
    });
};

export const getStreetFields = (args, callback, instance) => {
    const [label, placeholder, index] = args;
    const { address: { street = [] }, isSubmitted } = instance.props;

    if (index === 0) {
        return {
            label,
            placeholder,
            value: street[index],
            onChange: (streetValue) => instance.setState({ street0: { ...street0, value: streetValue } }),
            validation: index === 0 ? ['notEmpty'] : [],
            validateSeparately: false,
            isSubmitted
        };
    } if (index === 1) {
        return {
            label,
            placeholder,
            value: street[index],
            onChange: (streetValue) => instance.setState({ street1: { ...street, value: streetValue } }),
            validation: index === 0 ? ['notEmpty'] : [],
            validateSeparately: false,
            isSubmitted
        };
    }

    return {
        label,
        placeholder,
        value: street[index],
        validation: index === 0 ? ['notEmpty'] : [],
        validateSeparately: false,
        isSubmitted
    };
};

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            getStreetFields,
            estimateShipping,
            componentDidUpdate
        },
        'member-property': {
            state
        }
    }
};
