/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

 import { sendCartEvent } from '../../util/tracking';

export const addSendCartEvent = (args, callback, instance) => {
    const [cartData] = args;
    const { items } = cartData;

    if (!items) {
        callback(...args);
        return;
    }

    const iIds = items.map(({ id }) => id).sort((a, b) => a - b);

    sendCartEvent({ productIds: iIds });
    callback(...args);
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData: addSendCartEvent
        }
    }
}