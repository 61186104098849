import { StarIconComponent as SourceStarIconComponent } from 'MoninComponent/StarIcon/StarIcon.component';

/** @namespace Scandipwa/Component/StarIcon/Component/StarIconComponent */
export class StarIconComponent extends SourceStarIconComponent {
    render() {
        // empty, half-full, full
        const { starFill, hideAria } = this.props;

        return (
            <svg
              block="StarIcon"
              mods={ { fillState: starFill } }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              role={ hideAria ? undefined : 'img' }
              aria-label={ hideAria ? undefined : `${starFill} star` }
              aria-hidden={ hideAria ? 'true' : undefined }
              xmlns="http://www.w3.org/2000/svg"
            >
                { this.getStarPath() }
            </svg>
        );
    }
}

export default StarIconComponent;
