/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { roundPrice } from 'Util/Price';
import { CONFIGURABLE, SIMPLE } from 'Util/Product/Types';

import {
    BRAND_NAME,
    CATEOGRY_LIMIT, CHECKOUT, GROUPED, NOT_APPLICABLE, ZERO
} from '../component/GoogleTagManager/GoogleTagManager.config';

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductName */
export const getProductName = (type, product, mainSku = '', click) => {
    const { name = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === SIMPLE) {
        return name;
    }

    if (type === CONFIGURABLE && mainSku) {
        const { variants } = product;
        return variants[0].name;
    }

    return name;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductSKU */
export const getProductSKU = (type, product, mainSku = '', click) => {
    const { sku = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === SIMPLE) {
        return sku;
    }

    if (type === CONFIGURABLE && mainSku) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).sku;
    }

    return sku;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getCategories */
export const getCategories = (categories = []) => {
    if (!Array.isArray(categories)) {
        return [];
    }

    const itemCategoryKey = 'item_category';
    const limitedCategories = categories.slice(0, CATEOGRY_LIMIT).map(({ name }) => name);
    return limitedCategories.reduce((acc, cat, idx) => {
        if (idx === 0) {
            acc[itemCategoryKey] = cat;
        } else {
            acc[`${itemCategoryKey}${idx + 1}`] = cat;
        }

        return acc;
    }, {});
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPurchaseItemCategories */
export const getPurchaseItemCategories = (categoriesStr = '') => getCategories(
    categoriesStr.split('/').map((name) => ({ name }))
);

/** @namespace GtmNew/EventData/BaseProduct/Data/getQty */
export const getQty = (qty) => {
    if (qty) {
        return { quantity: qty };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariantIndex */
export const getVariantIndex = (type_id, variants, configurableVariantIndex) => {
    if (type_id === SIMPLE) {
        return -1;
    // eslint-disable-next-line no-restricted-globals
    } if (!isNaN(configurableVariantIndex) && configurableVariantIndex >= 0 && configurableVariantIndex !== false) {
        return configurableVariantIndex;
    }
    if (variants[0] !== undefined) {
        return 0;
    }

    return -1;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPrice */
export const getPrice = (variant, type_id, price, notInGetProduct = true) => {
    if (price && notInGetProduct) {
        return price;
    }

    const {
        price_range: {
            minimum_price: {
                final_price: {
                    value: discountValue = null
                } = {},
                regular_price: {
                    value = 0
                } = {}
            } = {}
        } = {}
    } = variant || {};

    return type_id !== GROUPED
        ? +roundPrice(discountValue || value) || 0
        : 0;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBrand */
export const getBrand = (product) => {
    const {
        attributes: {
            manufacturer
        }
    } = product;

    if (manufacturer === undefined) {
        return BRAND_NAME;
    }

    const {
        attribute_options,
        attribute_value
    } = manufacturer;

    const { label } = attribute_options[attribute_value];
    return label;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariant */
export const getVariant = (product, type_id, configurableVariantIndex = false) => {
    const {
        variants,
        configurable_options
    } = product;

    if (type_id === SIMPLE || variants[0] === undefined) {
        return NOT_APPLICABLE;
    }

    const configIndex = !configurableVariantIndex ? 0 : configurableVariantIndex;

    const { productsize = null, color = null } = configurable_options;
    if (!color && productsize) {
        const {
            attribute_options = {}
        } = productsize;

        const {
            attributes: {
                productsize: {
                    attribute_value
                } = {}
            } = {}
        } = variants[configIndex] || {};

        const { label = '' } = attribute_options[attribute_value] || {};
        return label;
    }

    if (color && !productsize) {
        const {
            attribute_options
        } = color;

        const {
            attributes: {
                color: {
                    attribute_value
                } = {}
            } = {}
        } = variants[configIndex] || {};

        const { label } = attribute_options[attribute_value] || {};
        return label;
    }

    return NOT_APPLICABLE;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPosition */
export const getPosition = (index, click) => {
    if (click && index) {
        return { index };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBaseCustomDimensions */
export const getBaseCustomDimensions = (item, click) => {
    const { sku: actionSku, product: { variants = [], dimensions = null, type_id } = {} } = item;

    if (type_id === SIMPLE || click) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const correctVarint = variants.find(({ sku }) => sku === actionSku);
        const custom = correctVarint ? correctVarint.dimensions : false;

        return custom ? JSON.parse(custom) : null;
    }
};

/** @namespace GtmNew/EventData/BaseProduct/Data/baseProductData */
export const baseProductData = (currency, item, click = false, notInGetProduct = true, event = '') => {
    const {
        qty = false, price, product: {
            type_id = SIMPLE, categories, sku: parentSku, position = 0, name: parentName, variants
        }, product
    } = item;

    const data = {
        item_name: parentName,
        item_id: parentSku,
        currency,
        price: (type_id === CONFIGURABLE && notInGetProduct === false)
            ? getPrice(variants[0], type_id, price, notInGetProduct)
            : getPrice(product, type_id, price, notInGetProduct),
        ...getQty(qty),
        ...getPosition(position, click),
        item_brand: getBrand(product),
        ...getCategories(categories),
        ...getBaseCustomDimensions(item, click)
    };

    if (event === CHECKOUT) {
        data.item_variant = getVariant(product, type_id, ZERO);
    }

    return data;
};
