/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { creditCardType, getCcCodeByAltCodeUtil } from '../../util/Card.util';
import { handleBackspace, handleNumberInput } from '../../util/ExpiryDate.util';
import PayFlowPro from './PayFlowPro.component';
import {
    CCV_MIN, EXPIRY_INPUT_ID, LAST_4, YEAR_FORMAT
} from './PayFlowPro.config';

/** @namespace Scandiweb/Payflowpro/Component/PayFlowPro/Container/PayFlowProContainer */
export class PayFlowProContainer extends PureComponent {
    static propTypes = {
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        sendPayFlowProState: PropTypes.func.isRequired
    };

    state = {
        cardNumber: '',
        cardIssuer: { type: '', img: undefined },
        month: '1',
        year: '',
        ccv: '',
        expiryDate: ''
    };

    creditCardRef = createRef();

    yearRef = createRef();

    ccvRef = createRef();

    expRef = createRef();

    containerFunctions = {
        handleCardNumberChange: this.handleCardNumberChange.bind(this),
        handleMonthsChange: this.handleMonthsChange.bind(this),
        handleYearChange: this.handleYearChange.bind(this),
        handleCcvChange: this.handleCcvChange.bind(this),
        handleKeyPress: this.handleKeyPress.bind(this),
        handleExpiryDateChange: this.handleExpiryDateChange.bind(this),
        handleExpiryKeyPress: this.handleExpiryKeyPress.bind(this)
    };

    componentDidMount() {
        const { setOrderButtonEnableStatus } = this.props;
        setOrderButtonEnableStatus(false);
    }

    componentDidUpdate() {
        const { setOrderButtonEnableStatus, sendPayFlowProState } = this.props;

        const {
            cardIssuer: { type },
            ccv,
            year,
            month,
            cardNumber,
            expiryDate
        } = this.state;

        if (/(0[1-9]|1[0-2])\/([0-9]{2})$/.test(expiryDate)) {
            const [month, year] = expiryDate.split('/');
            this.setState({ month, year });
        }

        if (year.length === YEAR_FORMAT && ccv.length >= CCV_MIN && type) {
            setOrderButtonEnableStatus(true);
            sendPayFlowProState({
                cc_type: getCcCodeByAltCodeUtil(type),
                cc_exp_year: Number(year),
                cc_exp_month: Number(month),
                cc_last_4: Number(cardNumber.slice(LAST_4)),
                cardNumber,
                ccv
            });
        } else {
            setOrderButtonEnableStatus(false);
        }
    }

    handleMonthsChange(value) {
        this.setState({ month: value });
    }

    handleCardNumberChange() {
        const { current: { value = '' } = {} } = this.creditCardRef;

        this.setState({
            cardNumber: value,
            cardIssuer: creditCardType(value)
        });
    }

    handleYearChange() {
        const { current: { value = '' } = {} } = this.yearRef;
        this.setState({ year: value });
    }

    handleCcvChange() {
        const { current: { value = '' } = {} } = this.ccvRef;
        this.setState({ ccv: value });
    }

    handleExpiryDateChange() {
        const { current: { value = '' } = {} } = this.expRef;
        this.setState({ expiryDate: value });
    }

    handleKeyPress(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    handleExpiryKeyPress(e) {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || handleBackspace(e)) {
            return;
        }

        if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
            e.preventDefault();
            return;
        }

        handleNumberInput(e, this);
        this.validateExpiryDate();
    }

    validateExpiryDate = () => {
        const { expiryDate } = this.state;
        const input = document.getElementById(EXPIRY_INPUT_ID);
        if (expiryDate.split('/').length - 1 !== 1) {
            input.value = '  /  ';
            input.focus();
            input.setSelectionRange(0, 0);
            this.setState({ expiryDate: '  /  ' });
            return false;
        }

        return true;
    };

    containerProps() {
        const {
            cardNumber,
            cardIssuer,
            month,
            year,
            ccv,
            expiryDate
        } = this.state;

        return {
            ccv,
            year,
            month,
            cardIssuer,
            cardNumber,
            expiryDate,
            creditCardRef: this.creditCardRef,
            yearRef: this.yearRef,
            ccvRef: this.ccvRef,
            expRef: this.expRef
        };
    }

    render() {
        return (
            <PayFlowPro
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default PayFlowProContainer;
