import { Field } from 'Util/Query';

const getSubscribeToNewsletterMutation = (args, _callback, instance) => {
    const [email] = args;
    return new Field('subscribeEmailToNewsletter')
        .addArgument('email', 'String!', email)
        .addFieldList(instance._getPageFields());
};

const getClientTypeMutation = (args, _callback, _instance) => {
    const [email, client_type] = args;
    const input = {
        clientEmail: email,
        clientType: client_type
    };

    return new Field('clientTypeSave')
        .addArgument('input', 'clientInput!', input)
        .addField('successMessage');
};

export default {
    'Query/NewsletterSubscription': {
        'member-function': {
            getSubscribeToNewsletterMutation,
            getClientTypeMutation
        }
    }
};
