/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { clerkOpenProductUrl, clerkOpenSearch, clerkResetButton } from './handlers';

/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const CLERK_LOADED_EVENT = 'clerk-loaded';

/* eslint-disable */
let g = null;
// ^^^ needed to ensure we load scripts only once

/**
 * @namespace Scandiweb/Clerk/Util/Init/injectScript
 */
export const injectScript = () => {
    if (g) {
        return g;
    }

    const e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = true;
    e.src = `${document.location.protocol == 'https:' ? 'https' : 'http'}://cdn.clerk.io/clerk.js`;

    g = new Promise((res, rej) => {
        e.onload = () => {
            res();
            document.dispatchEvent(new Event(CLERK_LOADED_EVENT));
        };

        e.onerror = rej;
    })

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(e, s);

    window.__clerk_q = window.__clerk_q || [];
    window.Clerk = window.Clerk || function () {
        window.__clerk_q.push(arguments);
    };

    // Declare helper functions
    window.clerkOpenProductUrl = clerkOpenProductUrl;
    window.clerkOpenSearch = clerkOpenSearch;
    window.clerkResetButton = clerkResetButton;

    return g;
};

/* eslint-enable */

/** @namespace Scandiweb/Clerk/Util/Init/ensureClerkIsLoaded */
export const ensureClerkIsLoaded = async () => {
    if (window.Clerk) {
        return;
    }

    await injectScript();
};
