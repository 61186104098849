/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/vault-graphql
 * @link https://github.com/scandipwa/vault-graphql
 */

import {
    SET_COOKIE_POPUP_VISIBILITY,
    UPDATE_INFO_COOKIE_GROUP
} from './Cookies.action';

/** @namespace GdprCookie/Store/Cookies/Reducer/getInitialState */
export const getInitialState = () => ({
    cookieInfo: {},
    isPopupOpen: false
});

/** @namespace GdprCookie/Store/Cookies/Reducer/CookiesReducer */
export const CookiesReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        cookieInfo,
        isPopupOpen
    } = action;

    switch (type) {
    case UPDATE_INFO_COOKIE_GROUP:

        return {
            ...state,
            cookieInfo
        };

    case SET_COOKIE_POPUP_VISIBILITY:

        return {
            ...state,
            isPopupOpen
        };

    default:
        return state;
    }
};

export default CookiesReducer;
