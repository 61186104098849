/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    isSignedIn
} from 'Util/Auth';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
const handleCustomerDataOnInit = (args, callback) => {
    const [dispatch] = args;
    callback(...args);

    if (isSignedIn()) {
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
        );
    }
};

export const config = {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            handleCustomerDataOnInit
        }
    }
};

export default config;
