import Field from 'Component/Field';
import {
    CartCouponComponent as MoninCartCouponComponent
} from 'MoninComponent/CartCoupon/CartCoupon.component';
import { VALIDATION_STATUS } from 'MoninComponent/Field/Field.config';

import './CartCoupon.extend.style';

/** @namespace Scandipwa/Component/CartCoupon/Component/CartCouponComponent */
export class CartCouponComponent extends MoninCartCouponComponent {
    /**
     * Overridden to add attribute value for styling
     * @returns {*}
     */
    renderRemoveCoupon() {
        const { couponCode } = this.props;

        return (
            <>
                <p block="CartCoupon" elem="Message">
                    { __('Applied coupon code: ').toString() }
                    <strong>{ couponCode.toUpperCase() }</strong>
                </p>
                <button
                  className="RemoveCouponButton"
                  block="CartCoupon"
                  elem="Button"
                  type="button"
                  mix={ { block: 'Button' } }
                  onClick={ this.handleRemoveCoupon }
                >
                    { __('Remove Coupon').toString() }
                </button>
            </>
        );
    }

    renderApplyCoupon() {
        const { enteredCouponCode } = this.state;
        const { skip, success } = VALIDATION_STATUS;

        return (
            <>
                <div block="CartCoupon" elem="Input">
                <Field
                  type="text"
                  id="couponCode"
                  name="couponCode"
                  value={ enteredCouponCode }
                  placeholder={ __('Your discount code').toString() }
                  label={ __('Your discount code').toString() }
                  onChange={ this.handleCouponCodeChange }
                  customValidationStatus={ !enteredCouponCode ? skip : success }
                  mix={ { block: 'CartCoupon', elem: 'Input' } }
                  aria-label={ __('Your discount code').toString() }
                />
                </div>
                <button
                  block="CartCoupon"
                  elem="Button"
                  type="button"
                  mods={ { isHollow: true } }
                  disabled={ !enteredCouponCode }
                  onClick={ this.handleApplyCoupon }
                >
                    { __('Apply coupon code').toString() }
                </button>
            </>
        );
    }
}

export default CartCouponComponent;
