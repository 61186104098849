import { replaceCatcher } from './replaceCatcher';
import { polyfillClone } from './structuredCloneCatcher';

/** @namespace Scandipwa/Util/Polyfill/Polyfills/addPolyfills */
export const addPolyfills = () => {
    // structuredClone fix.
    if (typeof self.structuredClone !== 'function') {
        self.structuredClone = polyfillClone;
    }
    // String.replaceAll fix.
    replaceCatcher();
};

export default addPolyfills;
