/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const POSTS_LISTING = 'posts-listing';
export const TEXT_ASSETS = {
    US_HEADING: 'VIDEO LIBRARY',
    FR_CA_HEADING: 'VIDÉOTHÈQUE',
    SPA_HEADING: 'VIDEOTECA',
    CA_HEADING: 'VIDEO LIBRARY',
    US_DESCRIPTION: 'Explore our extensive library of how-to instruction, new flavor innovation and exciting company info that will guide you through all that Monin has to offer.',
    CA_DESCRIPTION: 'Explore our extensive library of how-to instruction, new flavor innovation and exciting company info that will guide you through all that Monin has to offer.',
    FR_CA_DESCRIPTION: 'Explorez notre vaste bibliothèque d\'instructions pratiques, nouvelle innovation gustative et des informations intéressantes de l\'entreprise qui vous guideront tout ce que Monin a à offrir.',
    SPA_DESCRIPTION: 'Explore nuestra amplia biblioteca de instrucciones paso a paso, información nueva de la empresa e innovación en sabores que te guiará en todo lo que Monin tiene para ofrecer.'

};
export const NEWS_CATEGORY_ID = 15;
export const ADA_VIDEO_OVERLAY_CLASS = 'video-window';
export const TAB_KEY = 'Tab';
export const BLOG_POST_WRAPPER_QUERY = 'div.BlogPostCard-Description';
export const FIRST_FOCUSABLE_QUERY = 'div.first-focusable-element';
