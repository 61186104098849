import { PAYFLOWPRO_CODE } from '../component/PayFlowPro/PayFlowPro.config';

export function getPayFlowProData() {
    const { payFlowData } = this.state;

    return {
        asyncData: {
            additional_data: {
                cc_details: {
                    ...payFlowData
                },
                is_active_payment_token_enabler: false
            }
        }
    };
}

export function sendPayFlowProState(state) {
    this.setState({ payFlowData: state });
}

export const dataMap = (originalMember, instance) => ({
    ...originalMember,
    [PAYFLOWPRO_CODE]: getPayFlowProData.bind(instance)
});

export const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    sendPayFlowProState: sendPayFlowProState.bind(instance)
});

export default {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap,
            containerFunctions
        }
    }
};
