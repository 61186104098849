/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import AddIcon from 'Component/AddIcon';
import { Notification as SourceNotificationComponent } from 'SourceComponent/Notification/Notification.component';
import CSS from 'Util/CSS';

import {
    ANIMATION_DURATION, ERROR_NOTIFICATION_LIFETIME, ERROR_TYPE, NOTIFICATION_LIFETIME
} from './Notification.config';

import './Notification.extend.style';
/** @namespace Scandipwa/Component/Notification/Component/NotificationComponent */
export class NotificationComponent extends SourceNotificationComponent {
    componentDidMount() {
        const { notification: { msgType }, lifeTime } = this.props;

        if (msgType.toLowerCase() === ERROR_TYPE) {
            this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || ERROR_NOTIFICATION_LIFETIME);
        } else {
            this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || NOTIFICATION_LIFETIME);
        }

        CSS.setVariable(this.notification, 'animation-duration', `${ANIMATION_DURATION}ms`);
    }

    extendNotification() {
        clearTimeout(this.hideTimeout);
        this.hideTimeout = setTimeout(() => this.hideNotification(), ERROR_NOTIFICATION_LIFETIME);
    }

    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id } role="alert">
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                <button
                  block="Notification"
                  elem="Extend"
                  aria-label="Extend notification time by 25 seconds"
                  onClick={ this.extendNotification.bind(this) }
                >
                    <AddIcon />
                </button>
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
