/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import ProductLabelSettingQuery from '../query/ProductLabelSetting.query';

/** @namespace ScandipwaAmastyLabels/Plugin/ConfigQuery/_getStoreConfigFields */
export const _getStoreConfigFields = (args, callback) => [
    ...callback(...args),
    ProductLabelSettingQuery.getQuery()
];

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
