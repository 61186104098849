/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Field from 'Component/Field';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Star from 'Component/StarIcon';
import { STAR_EMPTY, STAR_FULL } from 'Component/StarIcon/StarIcon.config';
import { ReactComponent as FacebookIcon } from 'Style/icons/logos/social_share_brown/Facebook.svg';
import { ReactComponent as LinkedInIcon } from 'Style/icons/logos/social_share_brown/Linked_In.svg';
import { ReactComponent as TwitterIcon } from 'Style/icons/logos/social_share_brown/Twitter.svg';
import { DeviceType } from 'Type/Device';
import { isSignedIn } from 'Util/Auth';

import {
    REVIEW_ACCEPTED, REVIEW_IS_FETCHED, SOCIAL_SHARE_LINKS_MAP, STARS_WRAPPER_ID
} from './YotpoPDPAddReview.config';

import './YotpoPDPAddReview.style';
/**
 * @class YotpoPDPReview
 * @namespace YotpoReviewsSpwa5/Component/YotpoPDPAddReview/Component/YotpoPDPAddReviewComponent */
export class YotpoPDPAddReviewComponent extends PureComponent {
    static propTypes = {
        submitReview: PropTypes.func.isRequired,
        fetchReviewStatus: PropTypes.string,
        cbReviewsInputHaveBeenReset: PropTypes.func,
        handleAddReviewShow: PropTypes.func,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        fetchReviewStatus: '',
        cbReviewsInputHaveBeenReset: () => {},
        handleAddReviewShow: () => {}
    };

    state = {
        score: 0,
        headingTextValue: '',
        reviewTextValue: '',
        reviewUserName: '',
        reviewUserEmail: '',
        isDisabledPublishButton: true,
        resetValues: false,
        tempScore: 0
    };

    setScore = (value) => {
        this.setState({ score: value });
    };

    headingTextChangeHandler = (value) => {
        this.setState({ headingTextValue: value });
    };

    reviewTextChangeHandler = (value) => {
        this.setState({ reviewTextValue: value });
    };

    reviewUserNameHandler = (value) => {
        this.setState({ reviewUserName: value });
    };

    reviewUserEmailHandler = (value) => {
        this.setState({ reviewUserEmail: value });
    };

    setTemporaryScore = (value) => {
        this.setState({ tempScore: value });
    };

    updateFormControls = () => {
        const { fetchReviewStatus, cbReviewsInputHaveBeenReset } = this.props;
        const { score } = this.state;
        switch (fetchReviewStatus) {
        case REVIEW_ACCEPTED:
            cbReviewsInputHaveBeenReset();
            this.setState({
                score: 0,
                headingTextValue: '',
                reviewTextValue: '',
                reviewUserName: '',
                reviewUserEmail: '',
                isDisabledPublishButton: true,
                resetValues: true
            });
            break;
        case REVIEW_IS_FETCHED:
            this.setState({ isDisabledPublishButton: true });
            break;

        default:
            this.setState({ isDisabledPublishButton: !score });
            break;
        }
    };

    renderTitle() {
        return (
            <div block="YotpoPDPAddReview" elem="Title">
                <h2>
                    { __('Write A Review').toString() }
                </h2>
            </div>
        );
    }

    renderStars() {
        const { score, tempScore } = this.state;

        const starCount = Number(score.toFixed(0));

        return (
            <div block="YotpoPDPAddReview" elem="Wrapper" id={ STARS_WRAPPER_ID }>
                <div block="YotpoPDPAddReview" elem="StarsHeader">
                    <label htmlFor="evaluation">{ __('Score').toString() }</label>
                </div>
                <div block="YotpoPDPAddReview" elem="Stars">
                    { Array.from(Array(starCount).keys(), (key) => (
                        <button
                          onClick={ () => this.setScore(key + 1) }
                          aria-label={ `Give ${ key + 1 } stars` }
                          block="YotpoPDPAddReview"
                          elem="StarButton"
                          mods={ { isActive: true, isLit: tempScore >= key + 1 } }
                          onFocus={ () => this.setTemporaryScore(key + 1) }
                        >
                            <Star starFill={ STAR_FULL } hideAria />
                        </button>
                    )) }
                    { Array.from(Array('5' - starCount).keys(), (key) => (
                        <button
                          onClick={ () => this.setScore(key + score + 1) }
                          aria-label={ `Give ${ key + score + 1 } stars` }
                          block="YotpoPDPAddReview"
                          elem="StarButton"
                          mods={ { isActive: false, isLit: tempScore >= key + score + 1 } }
                          onFocus={ () => this.setTemporaryScore(key + score + 1) }
                        >
                            <Star starFill={ STAR_EMPTY } hideAria />
                        </button>
                    )) }
                </div>
            </div>
        );
    }

    renderUserFields = () => {
        // if User signed in we use data which was provided by Redux
        // if not we render fields to point
        const {
            reviewUserName,
            reviewUserEmail
        } = this.state;

        if (isSignedIn()) {
            return null;
        }

        return (
            <div block="YotpoPDPAddReview" elem="UserFields">
                <Field
                  type="text"
                  label={ __('Use your name:').toString() }
                  id="user_name"
                  name="user_name"
                  validation={ ['notEmpty'] }
                  value={ reviewUserName }
                  autocomplete="name"
                  onChange={ this.reviewUserNameHandler }
                />
                <Field
                  type="email"
                  label={ __('Email:').toString() }
                  id="user_email"
                  name="user_email"
                  value={ reviewUserEmail }
                  validation={ ['notEmpty', 'email'] }
                  autocomplete="email"
                  onChange={ this.reviewUserEmailHandler }
                />
            </div>
        );
    };

    renderSocialIconButtons() {
        return (
        <div block="YotpoPDPAddReview" elem="SocialIcons">
            <ul>
                <li>
                <Link
                  to={ SOCIAL_SHARE_LINKS_MAP.Linked_In }
                  aria-label="Linked In"
                >
                <LinkedInIcon />
                </Link>
                </li>
                <li>
                <Link
                  to={ SOCIAL_SHARE_LINKS_MAP.Twitter }
                  aria-label="Twitter"
                >
                <TwitterIcon />
                </Link>
                </li>
                <li>
                <Link
                  to={ SOCIAL_SHARE_LINKS_MAP.Facebook }
                  aria-label="Facebook"
                >
                <FacebookIcon />
                </Link>
                </li>

            </ul>
        </div>
        );
    }

    renderActions() {
        const { isDisabledPublishButton } = this.state;

        return (
            <div block="YotpoPDPAddReview" elem="Actions">
                { this.renderSocialIconButtons() }
                <button
                  block="YotpoPDPAddReview"
                  elem="SubmitButton"
                  type="submit"
                  disabled={ isDisabledPublishButton }
                >
                    { __('Post').toString() }
                </button>
            </div>
        );
    }

    renderForm() {
        const { submitReview } = this.props;
        const {
            score,
            headingTextValue,
            reviewTextValue,
            resetValues
        } = this.state;

        this.updateFormControls();

        if (resetValues) {
            this.setState({ resetValues: false });

            return (
                <Loader isLoading={ resetValues } />
            );
        }

        return (
            <Form
              onSubmitSuccess={ submitReview }
            >
                <Field
                  type="number"
                  label={ __('Score').toString() }
                  id="evaluation"
                  name="evaluation"
                  validation={ ['notEmpty'] }
                  value={ score }
                />
                <Field
                  type="text"
                  label={ __('Title').toString() }
                  id="title"
                  name="title"
                  validation={ ['notEmpty'] }
                  value={ headingTextValue }
                  onChange={ this.headingTextChangeHandler }
                />
                <Field
                  type="textarea"
                  label={ __('Review').toString() }
                  id="text"
                  name="text"
                  validation={ ['notEmpty'] }
                  value={ reviewTextValue }
                  onChange={ this.reviewTextChangeHandler }
                />
                <div block="YotpoPDPAddReview" elem="UserFieldsAndActions">
                    { this.renderUserFields() }
                    <div block="YotpoPDPAddReview" elem="UserFieldsAndActionsOr">
                        -
                        { __('OR').toString() }
                        -
                    </div>
                    { this.renderActions() }
                </div>
            </Form>
        );
    }

    renderCloseButton() {
        const { handleAddReviewShow } = this.props;

        return (
            <button block="YotpoPDPAddReview" elem="CloseButton" onClick={ () => handleAddReviewShow(false) }>
                <CloseIcon />
            </button>
        );
    }

    renderInformation() {
        return (
            <div block="YotpoPDPAddReview" elem="Information">
                <p>Asterisk (*) indicates required fields</p>
            </div>
        );
    }

    render() {
        const { device } = this.props;

        return (
            <div block="YotpoPDPAddReview">
                { device.isMobile && this.renderCloseButton() }
                { this.renderTitle() }
                { this.renderInformation() }
                { this.renderStars() }
                { this.renderForm() }
            </div>
        );
    }
}

export default YotpoPDPAddReviewComponent;
