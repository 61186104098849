import SourceFieldSelectContainer from 'SourceComponent/FieldSelect/FieldSelect.container';

import './FieldSelect.override.style';

/** @namespace Scandiweb/MoninTheme/Component/FieldSelect/Container/FieldSelectContainer  */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    state = {
        ...this.state,
        wasSelected: false
    };

    toggleWasSelected() {
        this.setState(({ wasSelected }) => ({ wasSelected: !wasSelected }));
    }

    /**
     * Overridden to pass additional props to child component.
     */
    containerProps() {
        const { value } = this.props;

        return { ...super.containerProps(), value };
    }

    /**
     * Overridden to add 'e' parameter to use 'preventDefault()'
     */
    handleSelectExpandedExpand(e) {
        super.handleSelectExpandedExpand();
        e.preventDefault();
    }
}

export default FieldSelectContainer;
