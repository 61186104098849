/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/zendesk
 * @author  Mert Gulmus <mert.gulmus@scandiweb.com | info@scandiweb.com>
 */

import { PureComponent } from 'react';

import './ZendeskCustomLauncher.style';
/** @namespace Zendesk/Component/ZendeskCustomLauncher/Component/ZendeskCustomLauncherComponent */
export class ZendeskCustomLauncherComponent extends PureComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    showPopup() {
        const { zE } = window;

        zE('webWidget', 'show');
        zE('webWidget', 'open');
        document.getElementById('zendesk-custom-launcher-wrapper').style.opacity = 0;
        const button = document.getElementById('zendesk-custom-launcher-button');
        button?.setAttribute('aria-expanded', 'true');

        const webWidget = document.getElementById('webWidget');

        webWidget?.setAttribute('role', 'region');
        webWidget?.setAttribute('aria-label', 'Support help');
    }

    render() {
        return (
            <div block="ZendeskCustomLauncher" id="zendesk-custom-launcher-wrapper">
                <button
                  block="ZendeskCustomLauncher"
                  elem="Button"
                  id="zendesk-custom-launcher-button"
                  type="button"
                  onClick={ this.showPopup }
                  aria-expanded="false"
                  aria-controls="webWidget"
                  aria-label="Support"
                >
                    <span block="ZendeskCustomLauncher" elem="ButtonIcon" aria-hidden="true">
                        ?
                    </span>
                    <span block="ZendeskCustomLauncher" elem="ButtonText">
                        { __('Support').toString() }
                    </span>
                </button>
            </div>
        );
    }
}

export default ZendeskCustomLauncherComponent;
