/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AFTER_ITEMS_TYPE } from 'Component/Router/Router.config';

import { EXIT_INTENT_COMPONENT_NAME, ExitIntent } from '../../component/ExitIntent';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const addExitIntent = (member) => [
    ...member,
    {
        component: <ExitIntent />,
        position: 90,
        name: EXIT_INTENT_COMPONENT_NAME
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [AFTER_ITEMS_TYPE]: addExitIntent
        }
    }
};
