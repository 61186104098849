import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import SlickSlider from 'Component/SlickSlider';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductListPage as SourceProductListPage } from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.override.style';
/** @namespace Scandiweb/MoninTheme/Component/ProductListPage/Component/ProductListPageComponent */
export class ProductListPageComponent extends SourceProductListPage {
    static propTypes = {
        ...this.propTypes,
        isWidget: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isWidget: false
    };

    /**
     * Overridden to pass isProductLinks and isWidget props
     */
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: { mods: { layout = GRID_LAYOUT } = {} },
            isWidget
        } = this.props;

        return items.map((product, i) => (
            <ProductCard
              product={ product }
                // eslint-disable-next-line react/no-array-index-key
              key={ i }
              selectedFilters={ selectedFilters }
              layout={ layout }
              { ...this.containerProps() }
              isProductLinks={ isWidget }
              isWidget={ isWidget }
            />
        ));
    }

    renderPageItemsInSlider() {
        return <SlickSlider>{ this.renderPageItems() }</SlickSlider>;
    }

    /**
     * Overridden to conditionally render page items in Slider.
     */
    renderItems() {
        const { items, isLoading, isWidget } = this.props;

        if (!items.length || isLoading) {
            return this.renderPlaceholderItems();
        }

        return isWidget ? this.renderPageItemsInSlider() : this.renderPageItems();
    }

    /**
     * Overridden to add 'isLoading' mod.
     */
    render() {
        const {
            pageNumber, wrapperRef, mix, isLoading
        } = this.props;

        return (
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              mods={ { isLoading } }
              key={ pageNumber }
              ref={ wrapperRef }
            >
                { this.renderItems() }
            </ul>
        );
    }
}

export default withRouter(ProductListPageComponent);
