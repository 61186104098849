import { connect } from 'react-redux';

import {
    CartItemContainer as SourceCartItemContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/CartItem/CartItem.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandiweb/MoninTheme/Component/CartItem/Container/CartItemContainer */
export class CartItemContainer extends SourceCartItemContainer {
    /**
     * Get product price before discount.
     */
    getBaseRowTotal() {
        const variant = this.getProductVariant();
        const { item: { product, qty } } = this.props;

        const cartItem = product || variant;
        const { price_range: { maximum_price: { default_price: { value } } } } = cartItem;
        return value * qty;
    }

    /**
     * Add `baseRowTotal` to containerProps. Method copied, as can't override arrow function.
     */
    containerProps = () => {
        const {
            item,
            currency_code,
            isEditing,
            isCartOverlay,
            isMobile,
            showLoader
        } = this.props;
        const { isLoading } = this.state;

        return {
            item,
            currency_code,
            isEditing,
            isCartOverlay,
            isMobile,
            isLoading,
            showLoader,
            linkTo: this._getProductLinkTo(),
            thumbnail: this._getProductThumbnail(),
            minSaleQuantity: this.getMinQuantity(),
            maxSaleQuantity: this.getMaxQuantity(),
            isProductInStock: this.productIsInStock(),
            optionsLabels: this.getConfigurableOptionsLabels(),
            isMobileLayout: this.getIsMobileLayout(),
            baseRowTotal: this.getBaseRowTotal()
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
