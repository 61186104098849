import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import LinksToProductComponent from './LinksToProduct.component';

/** @namespace Component/LinksToProduct/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace  Component/LinksToProduct/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Component/LinksToProduct/Container */
export class LinksToProductContainer extends PureComponent {
    static propTypes = {
        shopNowPathToProduct: PropTypes.string,
        shopNowWebsite: PropTypes.string.isRequired,
        shopNowWebsiteTitle: PropTypes.string.isRequired
    };

    containerProps() {
        const {
            shopNowPathToProduct,
            shopNowWebsite,
            shopNowWebsiteTitle
        } = this.props;

        return {
            shopNowPathToProduct,
            shopNowWebsite,
            shopNowWebsiteTitle
        };
    }

    render() {
        return (
            <LinksToProductComponent
                { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinksToProductContainer);
