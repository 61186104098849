/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { caCodeRegExpArr } from 'Component/CheckoutAddressForm/CheckoutAddressForm.config';
import { formConfig as sourceFormConfig } from 'SourceComponent/Form/Form.config';

export * from 'SourceComponent/Form/Form.config';

/** @namespace Scandipwa/Component/Form/Config/validateCaPostcode */
export const validateCaPostcode = ({ value }) => {
    const regExp = new RegExp(
        `^${caCodeRegExpArr.join('')}$`,
        'i'
    );

    return regExp.test(value);
};

/** @namespace Scandipwa/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => {
    if (value) {
        return value.trim().length > 0;
    }

    return false;
};

/** @namespace Scandipwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    ...sourceFormConfig(),
    caPostcode: {
        validate: validateCaPostcode,
        message: __('Zip / Postal code is invalid').toString()
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!').toString()
    }
});

export default formConfig();
