import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductPriceComponent as SourceProductPriceComponent
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.extend.style';

/** @namespace Scandipwa/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPriceComponent {
    renderOldPrice() {
        const {
            discountPercentage,
            roundedRegularPrice,
            isSchemaRequired,
            variantsCount,
            price_tiers
        } = this.props;
        const isRegularPrice = discountPercentage === 0 || roundedRegularPrice === 0;

        if (isRegularPrice || !!price_tiers.length) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price: %s', roundedRegularPrice) }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { roundedRegularPrice }
            </del>
        );
    }

    renderPlaceholder() {
        const { mix } = this.props;

        return (
            <p block="ProductPrice" mix={ mix }>
                <TextPlaceholder mix={ { block: 'ProductPrice', elem: 'Placeholder' } } length="custom" />
            </p>
        );
    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            discountPercentage,
            mix
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0 } }
              mix={ mix }
            >
                { this.renderOldPrice() }
                { this.renderCurrentPrice() }
                { this.renderSubPrice() }
                { this.renderSchema() }
            </p>
        );
    }
}

export default ProductPriceComponent;
