import Field from 'Component/Field/Field.container';
import {
    CheckoutDeliveryOptionComponent as MoninCheckoutDeliveryOptionComponent
} from 'MoninComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';

import './CheckoutDeliveryOption.override.style';

/** @namespace Scandipwa/Component/CheckoutDeliveryOption/Component/CheckoutDeliveryOptionComponent */
export class CheckoutDeliveryOptionComponent extends MoninCheckoutDeliveryOptionComponent {
    /**
     * Overriden to change ID
     */
    render() {
        const {
            option: {
                carrier_title,
                method_title,
                available
            } = {},
            onOptionClick,
            isSelected
        } = this.props;

        const id = `${carrier_title}_${method_title}`.replace(/(\s\/\s)|\s/g, '_');

        return (
            <li block="CheckoutDeliveryOption" mods={ { isDisabled: !available } }>
                <button
                  block="CheckoutDeliveryOption"
                  mods={ { isDisabled: !available, isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ onOptionClick }
                  disabled={ !available }
                >
                    <Field
                      type="checkbox"
                      id={ `option-${ id }` }
                      name={ `option-${ id }` }
                      checked={ isSelected }
                      disabled
                    />
                    { this.renderRow() }
                </button>
            </li>
        );
    }
}

export default CheckoutDeliveryOptionComponent;
