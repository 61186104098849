/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/zendesk
 * @author  Jort Geurts <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

/** @namespace Zendesk/Plugin/ZendeskLiveChat/Query/ConfigQueryPlugin */
export class ConfigQueryPlugin {
    getZendeskConfigDataFields = (args, callback) => ([
        ...callback(...args),
        this.getZendeskConfig()
    ]);

    getZendeskConfig() {
        return new Field('zendesk')
            .addFieldList(this.getZendeskConfigData());
    }

    getZendeskConfigData() {
        return [
            'zendesk_enabled',
            'zendesk_url'
        ];
    }
}

export const { getZendeskConfigDataFields } = new ConfigQueryPlugin();

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: getZendeskConfigDataFields
        }
    }
};
