import {
    CartItemPrice as SourceCartItemPrice
} from 'SourceComponent/CartItemPrice/CartItemPrice.component';
import { roundPrice } from 'SourceUtil/Price';
import { formatPrice, round } from 'Util/Manipulations';

import './CartItemPrice.style';

/** @namespace Scandiweb/MoninTheme/Component/CartItemPrice/Component/CartItemPriceComponent */
export class CartItemPriceComponent extends SourceCartItemPrice {
    /**
     * Render price of 1 cart item.
     */
    renderSingleProductPrice() {
        const { singleItemPrice, currency_code, mix: { mods } } = this.props;
        const value = roundPrice(singleItemPrice);

        return (
            <span block="CartItem" elem="SinglePrice" mods={ mods } aria-label={ __('Current product price') }>
                <data value={ value }>{ formatPrice(singleItemPrice, currency_code) }</data>
            </span>
        );
    }

    /**
     * Overridden display price render
     */
    renderPrice() {
        const { price, currency_code } = this.props;
        const value = roundPrice(price);

        return (
            <span aria-label={ __('Current product price') }>
                <data value={ value }>{ formatPrice(price, currency_code) }</data>
            </span>
        );
    }

    /**
     * Add discount to cart item price.
     */
    renderHighPrice(isPlaceholder = false) {
        const { baseRowTotal, price, currency_code } = this.props;
        const value = round(baseRowTotal);

        if (price === value) {
            return null;
        }

        return (
            <span
              aria-label={ __('Old product price') }
              block="CartItemPrice"
              elem="HighPrice"
              mods={ { isPlaceholder } }
            >
                <data value={ value }><del>{ formatPrice(baseRowTotal, currency_code) }</del></data>
            </span>
        );
    }

    /**
     * Add single product price and labels to layout.
     */
    renderDesktop() {
        const { mix } = this.props;

        return (
            <>
                <p block="ProductPrice" aria-label={ __('Single Price') } mix={ { ...mix, elem: 'SinglePrice' } }>
                    <span block="CartItem" elem="PriceLabel">{ __('Price exc. tax') }</span>
                    { this.renderSingleProductPrice() }
                    { this.renderHighPrice(true) }
                </p>
                <p block="ProductPrice" aria-label={ __('Product Price') } mix={ mix }>
                    <span block="CartItem" elem="PriceLabel">{ __('Total exc. tax') }</span>
                    { this.renderPrice() }
                    { this.renderHighPrice() }
                </p>
            </>
        );
    }

    /**
     * Render mobile specific price layout.
     */
    renderMobile() {
        const { mix } = this.props;

        return (
            <p block="ProductPrice" aria-label={ __('Product Price') } mix={ mix }>
                { this.renderHighPrice(false) }
                { this.renderPrice() }
            </p>
        );
    }

    /**
     * Render minicart specific price layout.
     */
    renderMinicart() {
        const { mix } = this.props;

        return (
            <div>
                <p block="ProductPrice" aria-label={ __('Product Price') } mix={ mix }>
                    { this.renderHighPrice(false) }
                    { this.renderPrice() }
                </p>
                <p block="ProductPrice" aria-label={ __('Single Price') } mix={ { ...mix, elem: 'SinglePrice' } }>
                    <span block="CartItem" elem="PriceLabel" mods={ { isCartOverlay: true } }>
                        { __('Price exc. tax') }
                    </span>
                    { this.renderSingleProductPrice() }
                </p>
            </div>
        );
    }

    /**
     * Overridden to switch between desktop and mobile layouts.
     */
    render() {
        const { mix: { mods: { isEditing } }, isMobile } = this.props;

        if (isMobile) {
            return this.renderMobile();
        }

        if (isEditing) {
            return this.renderDesktop();
        }

        return this.renderMinicart();
    }
}

export default CartItemPriceComponent;
