/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';

import {
    CheckoutPaymentsContainer as MoninCheckoutPaymentsContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'MoninComponent/CheckoutPayments/CheckoutPayments.container';

/** @namespace Scandipwa/Component/CheckoutPayments/Container/CheckoutPaymentsContainer */
export class CheckoutPaymentsContainer extends MoninCheckoutPaymentsContainer {
    __construct(props) {
        super.__construct(props);

        /* shopgate method is hidden */
        const { paymentMethods } = props;
        const validMethods = paymentMethods.filter(({ code }) => code !== 'shopgate');
        const [{ code } = {}] = validMethods;

        this.state = {
            selectedPaymentCode: code
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
