import {
    CheckoutPayments as SourceCheckoutPayments
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

import './CheckoutPayments.override.style';

/** @namespace Scandiweb/MoninTheme/Component/CheckoutPayments/Component/CheckoutPaymentsComponent */
export class CheckoutPaymentsComponent extends SourceCheckoutPayments {}

export default CheckoutPaymentsComponent;
