import {
    ProductConfigurableAttributeDropdown as SourceProductConfigurableAttributeDropdownComponent
} from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

import ProductActionsDropdown from '../ProductActionsDropdown';

import './ProductConfigurableAttributeDropdown.override.style';

/** @namespace Scandipwa/Component/ProductConfigurableAttributeDropdown/Component/ProductConfigurableAttributeDropdownComponent */
export class ProductConfigurableAttributeDropdownComponent extends SourceProductConfigurableAttributeDropdownComponent {
    render() {
        const {
            selectOptions,
            selectValue,
            selectName,
            selectLabel,
            onChange
        } = this.props;

        return (
            <ProductActionsDropdown
              id={ selectName }
              name={ selectName }
              type="select"
              placeholder={ __('Choose %s', selectLabel.toLowerCase()).toString() }
              mix={ { block: 'ProductConfigurableAttributeDropdown' } }
              selectOptions={ selectOptions }
              value={ selectValue }
              onChange={ onChange }
            />
        );
    }
}

export default ProductConfigurableAttributeDropdownComponent;
