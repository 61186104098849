/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Clerk/Query/GetPurchaseInfo/Query */
export class GetPurchaseInfo {
    getPurchaseInfo(orderID = '') {
        return new Field('getPurchaseInfo')
            .setAlias('purchase')
            .addArgument('orderID', 'String', orderID)
            .addField(this.getProductField())
            .addField('email')
            .addField('customer')
    }

    getProductField() {
        return new Field('products')
            .addFieldList(this.getPurchaseProductFields());
    }

    getPurchaseProductFields() {
        return [
            'id',
            'price',
            'quantity',
        ];
    }
}

export default new GetPurchaseInfo();
