/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';

import { FILTER_ID } from './Overlay.config';

import './Overlay.override.style';

/** @namespace Scandipwa/Component/Overlay/Component/OverlayComponent */
export class OverlayComponent extends SourceOverlay {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { id } = this.props;
        const dialog = document.getElementById(`${id}-overlay`);
        const firstFocusableElement = document.getElementById(`${id}-first`);
        const lastFocusableElement = document.getElementById(`${id}-last`);

        if (dialog && firstFocusableElement && lastFocusableElement) {
            firstFocusableElement.focus();
            dialog.addEventListener('keydown', (e) => {
                if (e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
                    e.preventDefault();
                    lastFocusableElement.focus();
                } else if (e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
                    e.preventDefault();
                    firstFocusableElement.focus();
                }
            });
        }

        window.addEventListener('keydown', (e) => {
            const { id } = this.props;
            const overlay = document.getElementById(id);
            if (overlay?.classList.contains('Overlay_isVisible') && e.code === 'Escape') {
                overlay.classList.remove('Overlay_isVisible');
                this.escapeHide();
                this.checkVisibilities();
            }
        });
    }

    componentDidUpdate() {
        this.checkVisibilities();
    }

    checkVisibilities() {
        const { id } = this.props;

        const overlay = document.getElementById(id);
        const isVisible = overlay?.classList.contains('Overlay_isVisible');

        if (id !== FILTER_ID) {
            if (!isVisible) {
                const focusable = overlay?.querySelectorAll('a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
                focusable?.forEach((element) => {
                    element.setAttribute('tabindex', '-1');
                });
            } else if (isVisible) {
                const focusable = overlay?.querySelectorAll('a[href], button, input, textarea, select, details');
                focusable?.forEach((element) => {
                    element.setAttribute('tabindex', '0');
                });
            }
        }
    }

    escapeHide() {
        const { onHide, isMobile } = this.props;

        if (isMobile) {
            this.unfreezeScroll();
        }

        onHide();
    }

    render() {
        const {
            children,
            mix,
            areOtherOverlaysOpen,
            isStatic,
            id
        } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <div
              block="Overlay"
              id={ id }
              ref={ this.overlayRef }
              mods={ { isVisible, isStatic, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { children && children }
            </div>
        );
    }
}

export default OverlayComponent;
