/* eslint-disable max-len */
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import { CLIENTTYPE_POPUP_ID } from '../component/ClientTypePopup/ClientTypePopup.config';

export const NewsletterSubscriptionDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    './NewsletterSubscription.dispatcher.plugin'
);

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        subscribeToNewsletter: (email, client_type) => NewsletterSubscriptionDispatcher.then(
            ({ default: dispatcher }) => dispatcher.subscribeToNewsletter(dispatch, email, client_type)
        ),
        addClientType: (email, client_type) => NewsletterSubscriptionDispatcher.then(
            ({ default: dispatcher }) => dispatcher.addClientType(dispatch, email, client_type)
        ),
        hideActiveOverlay: () => dispatch(hideActiveOverlay()),
        showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey))
    };
};

const state = (originalMember) => ({
    ...originalMember,
    isClientTypeShown: false,
    clientType: '',
    selectedClientType: '',
    showSuccess: false
});

export const containerProps = (args, callback, instance) => {
    const { isClientTypeShown, showSuccess, key } = instance.state;
    return {
        ...callback(...args),
        isClientTypeShown,
        showSuccess,
        key
    };
};

export const onFormSubmit = (args, callback, instance) => {
    const { isClientTypeShown, selectedClientType } = instance.state;
    const { hideActiveOverlay, showOverlay } = instance.props;
    if (!isClientTypeShown) {
        instance.setState({ isClientTypeShown: true });
        return false;
    }

    const { label: client_type } = selectedClientType;

    const {
        subscribeToNewsletter,
        allowGuestSubscribe,
        isSignedIn,
        showErrorNotification,
        addClientType
    } = instance.props;
    const { newsletterEmail: email } = args[0];

    if (!allowGuestSubscribe && !isSignedIn) {
        showErrorNotification(
            __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.').toString()
        );
        instance.setState({ isClientTypeShown: false });
        return false;
    }

    instance.setState({ isLoading: true });
    subscribeToNewsletter(email, client_type)
        .then(({ value }) => {
            if (value === undefined) {
                instance.setState({ isClientTypeShown: false, isLoading: false });
                hideActiveOverlay();
                return false;
            }
            addClientType(email, client_type);
            const { key = 2 } = instance.state || {};
            instance.setState({
                showSuccess: true, isLoading: false, isSubmitted: true, newsletterEmailValue: '', key: key + 1
            });
            showOverlay(CLIENTTYPE_POPUP_ID);
        })
        .catch(instance.onFormSubmitDone);
};

export class NewsletterSubscriptionContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        formSubmit: this.formSubmit.bind(instance),
        enableClientTypeShown: this.enableClientTypeShown.bind(instance),
        disableClientTypeShown: this.disableClientTypeShown.bind(instance),
        setClientType: this.setClientType.bind(instance),
        getClientType: this.getClientType.bind(instance)
    });

    enableClientTypeShown() {
        this.setState({ isClientTypeShown: true });
    }

    disableClientTypeShown() {
        this.setState({ isClientTypeShown: false, showSuccess: false });
    }

    setClientType(clientType) {
        this.setState({ selectedClientType: clientType });
    }

    getClientType() {
        return this.state.selectedClientType;
    }

    formSubmit() {
        this.setState({ isClientTypeShown: true });
    }
}

const { containerFunctions } = new NewsletterSubscriptionContainer();

export default {
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmit,
            containerProps
        },
        'member-property': {
            state,
            containerFunctions
        }
    },
    'Component/NewsletterSubscription/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
