import {
    Draggable as SourceDraggable
} from 'SourceComponent/Draggable/Draggable.component';

import './Draggable.extend.style';

/** @namespace Scandipwa/Component/Draggable/Component/DraggableComponent */
export class DraggableComponent extends SourceDraggable {
    state = {
        isDragging: false,
        originalX: 0,
        translateX: 0,
        lastTranslateX: 0,
        originalY: 0,
        translateY: 0,
        lastTranslateY: 0,
        isMouseMoved: false
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleMouseMove = ({ clientX, clientY }) => {
        const { isDragging } = this.state;
        const { shiftX, shiftY } = this.props;

        if (!isDragging) {
            return;
        }

        this.setState(({
            originalX,
            originalY
        }) => ({
            translateX: clientX - originalX + shiftX,
            translateY: clientY - originalY + shiftY,
            isMouseMoved: true
        }), () => {
            const { onDrag } = this.props;
            if (onDrag) {
                onDrag({ ...this.state, clientX, clientY });
            }
        });
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    _handleDragEnd() {
        const { onDragEnd } = this.props;

        onDragEnd(this.state, (newState) => this.setState({
            ...newState,
            isDragging: false,
            translateX: 0,
            translateY: 0,
            isMouseMoved: false
        }));
    }

    render() {
        const { isMouseMoved } = this.state;
        const {
            children,
            handleFocus,
            handleKey,
            draggableRef,
            mix
        } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              block="Draggable"
              mods={ { areLinksBlocked: isMouseMoved } }
              mix={ mix }
              ref={ draggableRef }
              onMouseDown={ this.handleMouseDown }
              onTouchStart={ this.handleTouchStart }
              onClick={ this.handleClick }
              onContextMenu={ this.handleClick }
              onKeyDown={ handleKey }
              onFocus={ handleFocus }
            >
                { children }
            </div>
        );
    }
}

export default DraggableComponent;
