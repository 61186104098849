/* eslint-disable no-undef */
import { getCallStack } from 'Util/Error';

/** @namespace Scandipwa/Util/Loggly/Log/_getUserDeviceData */
export const _getUserDeviceData = () => {
    const userDeviceData = {};

    if ('userAgent' in navigator) {
        userDeviceData.userAgent = navigator.userAgent;
    }

    if ('userAgentData' in navigator) {
        userDeviceData.userAgentData = navigator.userAgentData;
    }

    if (location) {
        userDeviceData.location = location.pathname;
    }

    return userDeviceData;
};

/** @namespace Scandipwa/Util/Loggly/Log/getErrorData */
export const getErrorData = (data) => ({
    ...data,
    userDeviceData: _getUserDeviceData(),
    callstack: getCallStack(),
    dataJSON: JSON.stringify(data)
});

/** @namespace Scandipwa/Util/Loggly/Log/logToLoggly */
export const logToLoggly = (data) => {
    if (_LTracker) {
        _LTracker.push(getErrorData(data));
    }
};

/** @namespace Scandipwa/Util/Loggly/Log/logToTrackJs */
export const logToTrackJs = (data) => {
    if (typeof window?.trackJs?.track === 'function') {
        window?.trackJs?.track(getErrorData(data));
    }
};
