import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

/** @namespace Scandiweb/MoninTheme/Component/ExpandableContent/Component/ExpandableContentComponent */
export class ExpandableContentComponent extends SourceExpandableContent {
    /**
    *  Override to change chevron icon
    */
    renderButtonIcon() {
        // isArrow is always true, it marks if we should display chevron
        const { isArrow } = this.props;
        const { isContentExpanded } = this.state;

        if (!isArrow) {
            return null;
        }

        return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
    }
}

export default ExpandableContentComponent;
