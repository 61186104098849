/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { GROUPED } from 'Util/Product';

import {
    BOLT_USER_EMAIL
} from '../../../bolt-checkout/src/component/Bolt/Bolt.config';
import { DELIVERY, PAYMENT, SHIPPING } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_CHECKOUT, EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const state = (originalMember) => ({
    ...originalMember,
    PDPcart: {}
});

const initPDPBoltCheckout = (args, callback, instance) => {
    if (!window.BoltCheckout) {
        return;
    }

    const { hints } = args;

    const {
        isPDP, productOrVariant, quantity,
        boltConfig: {
            store_id: storeId
        }
    } = instance.props;

    if (isPDP && productOrVariant && Object.keys(productOrVariant).length > 0) {
        const {
            name, id, sku,
            price_range: { minimum_price: { final_price: { value, currency = 'USD' } } },
            type_id, image: { url: imageUrl }
        } = productOrVariant;

        console.log(`Running PDP init with id ${id} and sku ${sku}`);

        // eslint-disable-next-line fp/no-let
        let PDPcart = {
            items: [],
            currency
        };

        if (type_id === GROUPED) {
            const {
                groupedProductQuantity,
                product: { items: productItems }
            } = instance.props;

            if (productItems) {
                const items = productItems.map((item) => {
                    const {
                        product: {
                            id, name,
                            price_range: { minimum_price: { final_price: { value } } }
                        }
                    } = item;

                    const quantity = groupedProductQuantity[id];

                    if (quantity > 0) {
                        return {
                            reference: id,
                            quantity,
                            price: value,
                            name,
                            options: JSON.stringify({ storeId })
                        };
                    }

                    return null;

                    // The filter removes null values from the array again
                }).filter((x) => x);

                PDPcart.items = items;
            }
        } else {
            PDPcart = {
                items: [
                    {
                        reference: id,
                        quantity,
                        price: value, // Note that the bolt API returns the actual used value. No attack seems possible here.
                        name,
                        options: JSON.stringify({ storeId, image_url: imageUrl })
                    }
                ],
                currency
            };
        }

        instance.setState({ PDPcart });
        window.BoltCheckout.configureProductCheckout(PDPcart, hints, instance.getBoltCallbacks());
    }
};

const checkoutEvent = (step, props, state, message) => {
    const { isPDP, event } = props;

    if (isPDP) {
        const { PDPcart } = state;
        event(EVENT_GTM_CHECKOUT, {
            step, isPDP, PDPcart, message
        });

        return;
    }

    event(EVENT_GTM_CHECKOUT, { step, message });
};

const checkoutOptionsEvent = (step, option) => {
    event(EVENT_GTM_CHECKOUT_OPTION, { step, option });
};

const getBoltCallbacks = (args, callback, instance) => {
    const { setLoading } = instance.props;

    return {
        // This function is called just before the checkout form loads.
        // This is a hook to determine whether Bolt can proceed with checkout at this point. This function MUST return a boolean.
        check: () => {
            const {
                isPDP,
                addToCartRef,
                onProductValidationError,
                product: { type_id } = {}
            } = instance.props;

            if (isPDP) {
                if (!addToCartRef.validateAddToCart()) {
                    onProductValidationError(type_id);

                    return false;
                }
            }

            return true;
        },
        onCheckoutStart: () => {
            setLoading(true);
            instance.setState({ isBoltLoading: false, isShowSuccessTmp: false });
            // This function is called after the checkout form is presented to the user.
            checkoutEvent(1, instance.props, instance.state, SHIPPING);
            checkoutOptionsEvent(1, SHIPPING);
        },
        onEmailEnter: (email) => { // eslint-disable-line no-unused-vars
            // This function is called after the user enters their email address.
            BrowserDatabase.setItem(email, BOLT_USER_EMAIL);
        },
        onShippingDetailsComplete: (address) => { // eslint-disable-line no-unused-vars
            // This function is called when the user proceeds to the shipping options page.
            // This is applicable only to multi-step checkout.
            // When available the first parameter will contain a user's name and address info.
            const { onShippingDetailsComplete: parentOnShippingDetailsComplete } = callback(...args);
            console.log('onShippingDetailsCompletee', { address });
            parentOnShippingDetailsComplete(address);
            checkoutEvent(2, instance.props, instance.state, DELIVERY);
        },
        onShippingOptionsComplete: () => {
            // This function is called when the user proceeds to the payment details page.
            // This is applicable only to multi-step checkout.
            checkoutEvent(3, instance.props, instance.state, PAYMENT);
        },
        onPaymentSubmit: () => {
            // This function is called after the user clicks the pay button.
            instance.setState({ isShowSuccessTmp: true });

            clearTimeout(instance.autoRedirectTimer);

            // In case when user will be falls into the infinite loading limbo
            // after 35 sec user will be redirected
            instance.autoRedirectTimer = setTimeout(() => {
                window.location.href = '/boltpay/success';
            }, 35000);
        },
        // eslint-disable-next-line no-unused-vars
        success: (transaction, callback) => {
            // This function is called when the Bolt checkout transaction is successful.
            // **IMPORTANT** callback must be executed at the end of this function

            if (transaction) {
                const {
                    shipping_option: {
                        value: {
                            service = null
                        } = {}
                    } = {},
                    type
                } = transaction;

                if (service) {
                    checkoutOptionsEvent(2, service);
                }
                if (type) {
                    checkoutOptionsEvent(3, type);
                }
            }
            callback();
        },
        close: () => {
            // This function is called when the Bolt checkout modal is closed.
            // This will not be called when create_order endpoint returns a valid URL
            // and authorization is successful
            const {
                isShowSuccessTmp
            } = instance.state;

            if (isShowSuccessTmp) {
                window.location.href = '/boltpay/success';
                return;
            }

            setLoading(false);
            instance.setState({ isBoltLoading: false });

            const btnBolt = document.querySelector('[data-tid="bolt-checkout-button"]');

            if (btnBolt) {
                // btnBolt.remove();
            }
        }
    };
};

export default {
    'BoltCheckout/Component/Bolt/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'BoltCheckout/Component/Bolt/Container/BoltContainer': {
        'member-function': {
            initPDPBoltCheckout,
            getBoltCallbacks
        },
        'member-property': {
            state
        }
    }
};
