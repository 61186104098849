// Disabled to pass prop:
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Field from 'Component/Field';
import Popup from 'Component/Popup';

import { renderStars } from '../../util/Yotpo/Yotpo';
import { MORE_FILTER_POPUP_ID } from './YotpoPDPReviewsMoreFiltersPopup.config';

import './YotpoPDPReviewsMoreFiltersPopup.style';

/** @namespace YotpoReviewsSpwa5/Component/YotpoPDPReviewsMoreFiltersPopup/Component/YotpoPDPReviewsMoreFiltersPopupComponent */
export class YotpoPDPReviewsMoreFiltersPopupComponent extends PureComponent {
    static propTypes = {
        isStatic: PropTypes.bool.isRequired,
        closePopup: PropTypes.func.isRequired,
        scoresToFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
        isImagesAndVideosOnlySelectedOption: PropTypes.string.isRequired,
        totalReview: PropTypes.string.isRequired,
        handleClickScoreFilterOption: PropTypes.func.isRequired,
        handleClickOnlyImagesAndVideosIncludedFilterOption: PropTypes.func.isRequired,
        handleFetchReviews: PropTypes.func.isRequired,
        handleResetReviewsFilter: PropTypes.func.isRequired,
        clearFreeTextSearch: PropTypes.func.isRequired,
        isLoadingTotalReview: PropTypes.bool.isRequired,
        freeTextSearch: PropTypes.string.isRequired
    };

    renderCloseButton() {
        const { closePopup } = this.props;

        return (
            <button block="YotpoPDPReviewsMoreFiltersPopup" elem="CloseButton" onClick={ closePopup }>
                <CloseIcon />
            </button>
        );
    }

    renderHeader() {
        const { handleResetReviewsFilter } = this.props;

        return (
            <div block="YotpoPDPReviewsMoreFiltersPopup" elem="Header">
                { this.renderCloseButton() }
                <div block="YotpoPDPReviewsMoreFiltersPopup" elem="Title">{ __('Filters').toString() }</div>
                <button
                  block="YotpoPDPReviewsMoreFiltersPopup"
                  elem="ClearAllButton"
                  onClick={ handleResetReviewsFilter }
                >
                    { __('Clear All').toString() }
                </button>
            </div>
        );
    }

    renderRatingFilter() {
        return (
            <div block="YotpoPDPReviewsMoreFiltersPopup" elem="Filter">
                <div block="YotpoPDPReviewsMoreFiltersPopup" elem="FilterTitle">
                    Rating
                </div>
                <div>
                { this.renderRatingFilterOptions() }
                </div>
            </div>
        );
    }

    renderRatingFilterOptions() {
        const { scoresToFilter, handleClickScoreFilterOption } = this.props;
        const fullStars = 5;

        return (
            <div block="YotpoPDPReviewsMoreFiltersPopup" elem="FilterOptions">
                <Field
                  type="radio"
                  id="option-rating-all"
                  name="option-rating-all"
                  label={ __('All').toString() }
                  onClick={ handleClickScoreFilterOption }
                  checked={ scoresToFilter.includes('all') }
                  value="all"
                />
                { Array.from(Array(fullStars + 1).keys(), (starCount) => {
                    const stringStarCount = String(starCount);

                    return (
                        <Field
                          type="checkbox"
                          id={ `option-rating-${stringStarCount}` }
                          name={ `option-rating-${stringStarCount}` }
                          label={ renderStars(stringStarCount) }
                          onClick={ handleClickScoreFilterOption }
                          checked={ scoresToFilter.includes(stringStarCount) }
                          value={ stringStarCount }
                        />
                    );
                }).slice(1).reverse() }
            </div>
        );
    }

    renderWithImagesAndVideosOnlyFilterOptions() {
        const {
            isImagesAndVideosOnlySelectedOption, handleClickOnlyImagesAndVideosIncludedFilterOption
        } = this.props;
        const options = [
            { value: 'all', label: 'All' },
            { value: 'with-images-and-videos-only', label: 'With Images & Videos' }
        ];

        return (
            <div block="YotpoPDPReviewsMoreFiltersPopup" elem="FilterOptions">
                { options.map((option) => {
                    const { value, label } = option;

                    return (
                    <Field
                      type="radio"
                      id={ `option-review-${value}` }
                      name={ `option-review-${value}` }
                      label={ __(label) }
                      onClick={ handleClickOnlyImagesAndVideosIncludedFilterOption }
                      checked={ isImagesAndVideosOnlySelectedOption === value }
                      value={ value }
                    />
                    );
                }) }
            </div>
        );
    }

    renderReviewsFilter() {
        return (
            <div block="YotpoPDPReviewsMoreFiltersPopup" elem="Filter">
                <div block="YotpoPDPReviewsMoreFiltersPopup" elem="FilterTitle">
                    Reviews
                </div>
                { this.renderWithImagesAndVideosOnlyFilterOptions() }
            </div>
        );
    }

    renderShowFiltersResultButton() {
        const { totalReview, isLoadingTotalReview, handleFetchReviews } = this.props;
        const modifiedTotalReview = isLoadingTotalReview ? ' ... ' : totalReview;

        return (
            <button
              block="YotpoPDPReviewsMoreFiltersPopup"
              elem="ShowFiltersResultButton"
              onClick={ handleFetchReviews }
            >
                { `${__('Show').toString()} ${modifiedTotalReview} ${__('Reviews').toString()}` }
            </button>
        );
    }

    renderClearSearchTextButton() {
        const { clearFreeTextSearch } = this.props;

        return (
            <button
              block="YotpoPDPReviewsMoreFiltersPopup"
              elem="ClearSearchTextButton"
              onClick={ clearFreeTextSearch }
            >
                { __('Clear Search Text').toString() }
            </button>
        );
    }

    renderSearchText() {
        const { freeTextSearch } = this.props;

        return (
            <div block="YotpoPDPReviewsMoreFiltersPopup" elem="SearchTextWrapper">
                { __('Search Text').toString() }
                :
                &quot;
                { freeTextSearch }
                &quot;
                { this.renderClearSearchTextButton() }
            </div>
        );
    }

    renderContent() {
        const { freeTextSearch } = this.props;

        return (
            <div block="YotpoPDPReviewsMoreFiltersPopup" elem="Content">
                { this.renderRatingFilter() }
                { this.renderReviewsFilter() }
                { freeTextSearch && this.renderSearchText() }
            </div>
        );
    }

    render() {
        const { isStatic } = this.props;

        return (
            <Popup
              id={ MORE_FILTER_POPUP_ID }
              mix={ { block: 'YotpoPDPReviewsMoreFiltersPopup' } }
              isStatic={ isStatic }
            >
                { this.renderHeader() }
                { this.renderContent() }
                { this.renderShowFiltersResultButton() }
            </Popup>
        );
    }
}

export default YotpoPDPReviewsMoreFiltersPopupComponent;
