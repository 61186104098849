/* eslint-disable-next-line  */
import {
    DIV_MORE_TEXT_LENGTH_START,
    DIV_MORE_TEXT_LENGTH_END
} from './Lengths';

import { getDecodedHtml } from './getDecodedHtml';

export function getSplitDescription(description) {
    const indexDescription = description.indexOf('Read');

    // description starts
    const startDescription = description.substring(0, indexDescription);

    // more text starts
    const indexMoreTextStart = description.indexOf('none;');

    // more text ends
    const indexMoreTextEnd = description.indexOf('mobile-main-copy');

    // more text string
    const moreText = description.substring(indexMoreTextStart + DIV_MORE_TEXT_LENGTH_START,
        indexMoreTextEnd - DIV_MORE_TEXT_LENGTH_END);

    // more text parsed to html
    const htmlWithMoreText = `<div class="moretext">${ moreText }</div>`;
    const decodedHtmlWithMoreText = getDecodedHtml(htmlWithMoreText);

    return { startDescription, decodedHtmlWithMoreText };
}
