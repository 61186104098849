import { Field } from 'Util/Query';

/** @namespace YotpoReviewsSpwa5/Query/YotpoPDPQATab/Query/YotpoPDPQATabQuery */
export class YotpoPDPQATabQuery {
    getProductQuestionsQuery(productId) {
        return new Field('getProductQuestionsQuery')
            .addFieldList([
                'question_count',
                this.getQuestionFields()
            ])
            .addArgument('product_id', 'String', productId);
    }

    getQuestionFields() {
        return new Field('questions')
            .addFieldList([
                'content',
                'created_at',
                'user_type',
                'display_name',
                'social_image',
                this.getAnswerFields()
            ]);
    }

    getAnswerFields() {
        return new Field('answers')
            .addFieldList([
                'content',
                'display_name',
                'social_image',
                'created_at'
            ]);
    }

    getAddQuestionMutation(question) {
        return new Field('addQuestion')
            .addArgument('input', 'QuestionInput!', question)
            .addFieldList([
                'code',
                'message'
            ]);
    }
}

export default new YotpoPDPQATabQuery();
