export const PAYFLOWPRO_CODE = 'payflowpro';
export const YEAR_FORMAT = 2;
export const CCV_MIN = 3;
export const LAST_4 = -4;

export const URLS = {
    return_url: 'paypal/transparent/redirect/',
    cancel_url: 'paypal/transparent/redirect/',
    error_url: 'paypal/transparent/redirect/'
};

export const EXPIRY_INPUT_ID = 'payflow-expiry';
