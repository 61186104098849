import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductActionsContainer as
    SourceProductActionsContainer
} from 'SourceComponent/ProductActions/ProductActions.container';

import './ProductActions.style';

/** @namespace Scandiweb/MoninTheme/Component/ProductActions/Container/ProductActionsContainer */
export class ProductActionsContainer extends SourceProductActionsContainer {
    containerProps() {
        const { getTabNameByKey, handleOnExpandAndScrollClick } = this.props;

        return {
            ...super.containerProps(),
            getTabNameByKey,
            handleOnExpandAndScrollClick
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);
