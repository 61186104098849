/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { sendCartEvent } from '../../util/tracking';

// vvv importing this const is not efficient
const CART_TOTALS = 'cart_totals';

const interceptSetCartTotals = (args, callback) => {
    const [data, location] = args;

    if (location !== CART_TOTALS) {
        callback(...args);
        return;
    }

    const { items } = data;
    const prevCart = BrowserDatabase.getItem(CART_TOTALS);

    if (!prevCart) {
        return;
    }

    const { items: prevItems } = prevCart;
    const iIds = items.map(({ id }) => id).sort((a, b) => a - b);
    const piIds = prevItems.map(({ id }) => id).sort((a, b) => a - b);

    if (JSON.stringify(iIds) !== JSON.stringify(piIds)) {
        sendCartEvent({ productIds: iIds });
    }

    callback(...args);
};

export default {
    'Util/BrowserDatabase': {
        'member-function': {
            setItem: interceptSetCartTotals
        }
    }
};
