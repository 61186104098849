/* eslint-disable max-len */
import {
    MyAccountAddressTable as SourceMyAccountAddressTable
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import './MyAccountAddressTable.extend.style';

/** @namespace Scandipwa/Component/MyAccountAddressTable/Component/MyAccountAddressTableComponent */
export class MyAccountAddressTableComponent extends SourceMyAccountAddressTable {
    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address: { default_billing, default_shipping }
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <div block="MyAccountAddressTable" elem="Actions">
                <button
                  block="MyAccountAddressTable"
                  elem="EditButton"
                  onClick={ onEditClick }
                >
                    { __('Edit address').toString() }
                </button>
                <button
                  block="MyAccountAddressTable"
                  elem="DeleteButton"
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.').toString() : 'Delete this address' }
                >
                    { __('Delete').toString() }
                </button>
            </div>
        );
    }
}

export default MyAccountAddressTableComponent;
