/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import NewsletterSubscriptionQuery from 'Query/NewsletterSubscription.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export const NOT_ACTIVE = 'NOT_ACTIVE';

/**
 * Product Cart Dispatcher
 * @class NewsletterSubscriptionDispatcher
 * @namespace Store/NewsletterSubscription/Dispatcher
 */
export class NewsletterSubscriptionDispatcher {
    subscribeToNewsletter(dispatch, email, client_type) {
        return fetchMutation(NewsletterSubscriptionQuery.getSubscribeToNewsletterMutation(email, client_type)).then(
            /** @namespace Store/NewsletterSubscription/Dispatcher/fetchMutationThen */
            ({ subscribeEmailToNewsletter: { status } }) => {
                // `NOT_ACTIVE` response status corresponds to `newsletter_subscription_confirm` magento setting
                const message = status === NOT_ACTIVE
                    ? __('Confirmation request has been sent.').toString()
                    : __('Thank you for your subscription.').toString();

                // return dispatch(showNotification('success', message));
                return message;
            },
            /** @namespace Store/NewsletterSubscription/Dispatcher/fetchMutationSuccess */
            (error) => {
                if (getErrorMessage(error) === 'This email address is already subscribed.') {
                    return dispatch(showNotification('error', getErrorMessage(error)));
                }

                return null;
            }

        );
    }

    addClientType(dispatch, email, client_type) {
        return fetchMutation(NewsletterSubscriptionQuery.getClientTypeMutation(email, client_type));
    }
}

export default new NewsletterSubscriptionDispatcher();
