import { PureComponent } from 'react';

import 'MoninComponent/MoninChevronIcon/MoninChevronIcon.style';

/** @namespace Scandipwa/Component/MoninChevronIcon/Component/MoninChevronIconComponent */
export class MoninChevronIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="MoninChevronIcon"
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
                <path
                  d="M1.975 16.0879L9.0625 9.00039L1.975 1.91289"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default MoninChevronIconComponent;
