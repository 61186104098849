export * from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.config.js';

export const CHOOSE_PROVINCE_ID = 'Choose Province';

export const _letterCaCodeRegExp = '[a-zA-Z]';

export const caCodeRegExpArr = [
    _letterCaCodeRegExp,
    '\\d',
    _letterCaCodeRegExp,
    '(\\s|\\s\\d|\\d)',
    _letterCaCodeRegExp,
    '\\d'
];

export const SELECT_OPTIONS_CANADA = {
    id: 'CA',
    label: 'Canada',
    value: 'CA'
};

export const SELECT_OPTIONS_US = {
    id: 'US',
    label: 'United States',
    value: 'US'
};

export const CA_STORE_CODES = ['fr_ca', 'ca'];
