/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkOpenProductUrl */
export const clerkOpenProductUrl = (url) => {
    const { pathname } = new URL(url);
    history.push({ pathname: appendWithStoreCode(pathname) });
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkOpenSearch */
export const clerkOpenSearch = (type) => {
    const search = document.getElementById('search').value.trim().replace(/\s/g, '+');

    if (!search) {
        return;
    }

    history.push(appendWithStoreCode(`/search/${ search }?${type}`));
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkResetButton */
export const clerkResetButton = (search, type) => {
    if (!search) {
        return;
    }

    history.push(appendWithStoreCode(`/search/${ search }?${type}`));
};
