import {
    ProductConfigurableAttributeDropdownContainer as SourceContainerProductConfigurableAttributeDropdownContainer
} from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';

import ProductConfigurableAttributeDropdown from './ProductConfigurableAttributeDropdown.component';
/** @namespace Scandipwa/Component/ProductConfigurableAttributeDropdown/Container/ProductConfigurableAttributeDropdownContainer */
// eslint-disable-next-line max-len
export class ProductConfigurableAttributeDropdownContainer extends SourceContainerProductConfigurableAttributeDropdownContainer {
    _getSelectOptions = () => {
        const {
            option: {
                attribute_options,
                attribute_code
            },
            variants
        } = this.props;

        if (!attribute_options) {
            // eslint-disable-next-line no-console
            console.warn(`Please make sure "${ attribute_code }" is visible on Storefront.`);

            return [];
        }

        /* Create a new object and array to mutate data */
        const attributeOptionsModified = structuredClone(attribute_options);
        const variantsModified = Array.from(variants);

        /* Add stock status to attribute options */
        variantsModified.forEach((element, index) => {
            const { attributes } = element || {};
            const attribute = attributes[attribute_code] || {};
            if (attribute?.attribute_value && attributeOptionsModified[attribute?.attribute_value]) {
                attributeOptionsModified[attribute.attribute_value].stock_status = variantsModified[index].stock_status;
            }
        });

        return Object.values(attributeOptionsModified)
            .reduce((acc, option) => {
                const { value, label } = option;
                return [...acc, {
                    ...option,
                    id: value,
                    label
                }];
            }, []);
    };

    render() {
        return (
            <ProductConfigurableAttributeDropdown
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductConfigurableAttributeDropdownContainer;
