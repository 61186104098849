/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import { Field } from '@scandipwa/scandipwa/src/util/Query';

export const addPaypalFields = (args, callback) => [
    ...callback(...args),
    new Field('paypal').addFieldList([
        'paypal_sandbox_flag',
        'paypal_client_id',
        'paypal_payment_action'
    ])
];

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: addPaypalFields
        }
    }
};
