/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Link from 'Component/Link';
import { isCrawler } from 'Util/Browser';
import { appendWithStoreCode } from 'Util/Url';

import './GdprCookiePopup.style';

/** @namespace GdprCookie/Component/GdprCookiePopup/Component/GdprCookiePopupComponent */
export class GdprCookiePopupComponent extends PureComponent {
    static propTypes = {
        handleClosePopup: PropTypes.func.isRequired,
        isCookieSet: PropTypes.bool,
        isCookieBarActive: PropTypes.bool,
        isClosed: PropTypes.bool,
        isCookieSetState: PropTypes.bool
    };

    static defaultProps = {
        isCookieSet: false,
        isCookieBarActive: false,
        isClosed: false,
        isCookieSetState: false
    };

    renderCloseButton() {
        const { handleClosePopup } = this.props;

        return (
                <button
                  block="GdprCookiesPopup"
                  elem="CloseBtn"
                  aria-label={ __('Close').toString() }
                  onClick={ handleClosePopup }
                >
                    <CloseIcon />
                </button>
        );
    }

    renderContent() {
        return (
            <div
              block="GdprCookiesPopup"
              elem="Content"
            >
                <span>
                    { __('Our website uses cookies to improve your user experience. If you continue browsing, we assume that you consent to our use of cookies.').toString() }
                    <br />
                    <Link
                      block="GdprCookiesPopup"
                      elem="Link"
                      to={ appendWithStoreCode('/privacy-policy') }
                    >
                        { __('More information can be found in our Privacy Policy.').toString() }
                    </Link>
                </span>
            </div>
        );
    }

    render() {
        const {
            isCookieSet, isCookieBarActive, isClosed, isCookieSetState
        } = this.props;

        if (isCookieSet || !isCookieBarActive || isCrawler() || isClosed || isCookieSetState) {
            return null;
        }

        return (
            <div
              block="GdprCookiesPopup"
              elem="Wrapper"
            >
                { this.renderContent() }
                { this.renderCloseButton() }
            </div>
        );
    }
}

export default GdprCookiePopupComponent;
