/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { MY_WISHLIST, SEARCH, WISHLIST } from '../component/GoogleTagManager/GoogleTagManager.config';
import { getPageType } from './general.data';

/** @namespace GtmNew/EventData/ActionField/Data/getActionField */
export const getActionField = (store, categoryId = 0, path = '', search = false) => {
    if (search || path.includes(SEARCH)) {
        return {
            list: SEARCH
        };
    }

    if (path.includes(MY_WISHLIST)) {
        return { list: WISHLIST };
    }

    if (categoryId) {
        const { category: { name = '' } } = store.CategoryReducer;

        return {
            list: name
        };
    }

    const { pageType } = getPageType(store, path);

    return {
        list: pageType
    };
};
