/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { cloneElement } from 'react';

import { fetchQuery } from 'Util/Request';

import { EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import GtmPurchaseQuery from '../query/GtmPurchase.query';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const renderDetailsStep = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    const originalElement = callback(...args);

    return cloneElement(
        originalElement.props.children,
        { event }
    );
};

export const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
};

export const getGTMPurchaseEvent = (orderID, instance) => {
    const { event } = instance.props;
    const { isPurchaseEventFired } = instance.state;

    if (!isPurchaseEventFired && window.google_tag_manager !== undefined) {
        const query = GtmPurchaseQuery.getGTMPurchase(orderID);

        fetchQuery(query).then(
            ({ purchase }) => {
                instance.setState({ isPurchaseEventFired: true });
                event(EVENT_GTM_PURCHASE, { ...purchase, orderID });
            }
        );
    }
};

export const setDetailsStep = (args, callback, instance) => {
    const [orderID] = args;

    getGTMPurchaseEvent(orderID, instance);
    return callback(...args);
};

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            containerProps,
            setDetailsStep
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep
        }
    }
};
