import { checkEveryOption } from 'SourceUtil/Product/Product';

export * from 'SourceUtil/Product/Product';
/** @namespace Scandipwa/Util/Product/getVariantsIndexes */
export const getVariantsIndexes = (variants, options) => {
    const result = Object.entries(variants)
        .reduce((indexes, [index, variant]) => {
            if (checkEveryOption(variant.attributes, options)) {
                indexes.push(+index);
            }

            return indexes;
        }, []);

    return result;
};

/**
* Get product variant index by options
* @param {Object[]} variants
* @param {{ attribute_code: string }[]} options
* @returns {number}
* @namespace Scandipwa/Util/Product/getVariantIndex */
export const getVariantIndex = (variants, options) => getVariantsIndexes(variants, options)[0];

/** @namespace Scandipwa/Util/Product/getViewAllRecipesURL */
export const getViewAllRecipesURL = (id) => `recipes/with/product/id/${ id }/`;
