/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import Loader from 'Component/Loader';

import './PayPal.style';

/**
 * *Note*
 * This component currently can be rendered only once
 * Please try to have no more than 1 component per page and use isDisabled to hide it.
 * @namespace Paypal/Component/PayPal/Component/PayPalComponent */
export class PayPalComponent extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        paypal: PropTypes.any.isRequired,
        clientId: PropTypes.string.isRequired,
        onError: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onApprove: PropTypes.func.isRequired,
        createOrder: PropTypes.func.isRequired,
        environment: PropTypes.oneOf([
            'production',
            'sandbox'
        ]).isRequired
    };

    static defaultProps = {
        isDisabled: false
    };

    getClientId = () => {
        const {
            clientId,
            environment
        } = this.props;

        if (environment === 'sandbox') {
            return 'sb';
        }

        return clientId;
    };

    renderButtons() {
        const {
            paypal,
            onError,
            onCancel,
            onApprove,
            createOrder,
            environment,
            clientId
        } = this.props;

        if (!paypal || !environment || !clientId) {
            return <Loader isLoading />;
        }

        const PayPalButton = paypal && paypal.Buttons.driver('react', { React, ReactDOM });

        return (
            <PayPalButton
              env={ environment }
              onError={ onError }
              onCancel={ onCancel }
              onApprove={ onApprove }
              createOrder={ createOrder }
              style={ {
                  layout: 'horizontal',
                  label: 'pay',
                  size: 'small',
                  color: 'gold',
                  shape: 'rect'
              } }
            />
        );
    }

    render() {
        const { isDisabled } = this.props;

        return (
            <div block="Checkout-StickyButtonWrapper">
                <div block="PayPal" mods={ { isDisabled } }>
                    { this.renderButtons() }
                </div>
            </div>
        );
    }
}

export default PayPalComponent;
