/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slider from 'react-slick';

import SlickSliderArrow from 'Component/SlickSliderArrow';
import { ChildrenType, MixType } from 'Type/Common';

import { settings } from './SlickSlider.config';

import './SlickSlider.style';

/** @namespace Scandiweb/MoninTheme/Component/SlickSlider/Component/SlickSliderComponent */
export class SlickSliderComponent extends PureComponent {
    static propTypes = {
        children: ChildrenType,
        mix: MixType,
        extendedSettings: PropTypes.instanceOf(Object)
    };

    static defaultProps = {
        children: null,
        mix: {},
        extendedSettings: {}
    };

    render() {
        const { children, mix, extendedSettings } = this.props;

        return (
            <div block="SlickSlider" mix={ mix }>
                <Slider
                  nextArrow={ <SlickSliderArrow ariaLabel="Next slide button" /> }
                  prevArrow={ <SlickSliderArrow ariaLabel="Previous slide button" /> }
                  { ...settings }
                  { ...extendedSettings }
                >
                    { children }
                </Slider>
            </div>
        );
    }
}

export default SlickSliderComponent;
