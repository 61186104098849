import PropTypes from 'prop-types';

import { RADIO_TYPE } from 'Component/Field/Field.config';
import SourceFieldContainer from 'SourceComponent/Field/Field.container';

/** @namespace Scandiweb/MoninTheme/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...this.propTypes,
        testValidationMessage: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        testValidationMessage: ''
    };

    /**
     * Overridden to pass testValidationMessage list
     */
    containerProps() {
        const { testValidationMessage } = this.props;

        return {
            ...super.containerProps(),
            testValidationMessage
        };
    }

    /**
     * Overridden to set checked state for radioButton
     */
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const { checked: prevChecked } = prevProps;
        const { checked: currChecked, type } = this.props;

        if (type === RADIO_TYPE && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }
    }
}

export default FieldContainer;
