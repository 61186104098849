/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './BackToTop.style';
import ChevronIconComponent from '../ChevronIcon/ChevronIcon.component';

/**
  * Button for adding product to Cart
  * @class BackToTop
  * @namespace Scandipwa/Component/BackToTop/Component/BackToTopComponent  */
export class BackToTopComponent extends PureComponent {
     static propTypes = {
         isVisible: PropTypes.bool,
         scrollToTop: PropTypes.func.isRequired
     };

     static defaultProps = {
         isVisible: false
     };

     render() {
         const {
             scrollToTop,
             isVisible
         } = this.props;

         if (!isVisible) {
             return null;
         }

         return (
             <button
               onClick={ scrollToTop }
               block="BackToTop"
               elem="Button"
               aria-label={ __('Scroll back to the top of the page') }
             >
                <ChevronIconComponent />
                <ChevronIconComponent />
             </button>
         );
     }
}

export default BackToTopComponent;
