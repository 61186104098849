/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import CheckoutDeliveryOptions from '@scandipwa/scandipwa/src/component/CheckoutDeliveryOptions';
import Form from '@scandipwa/scandipwa/src/component/Form';
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth';
import { getGuestQuoteId } from '@scandipwa/scandipwa/src/util/Cart/Token';
import { fetchMutation } from '@scandipwa/scandipwa/src/util/Request';
import React from 'react';

import { INSTANT_PAYMENT_LOCATION_CHECKOUT } from '../component/InstantPayment/InstantPayment.config';
import InstantPayPalAddress from '../component/InstantPayPalAddress';
import PayPalQuery from '../query/PayPal.query';
import { BILLING_STEP } from '../route/Checkout/Checkout.config';

import '../component/CheckoutBilling/CheckoutBilling.style.scss';
/**
 * Container
 */
export class CheckoutBillingComponent {
    cartResetInProgress = false;

    renderCarrier() {
        const {
            payPalState: {
                shipping_methods,
                selectedCarrier
            }
        } = this.props;

        const onShippingMethodSelect = async (selectedCarrier) => {
            const {
                setPayPalState,
                payPalState: {
                    isLoading,
                    selectedCarrier: {
                        carrier_code: currentCarrierCode,
                        method_code: currentMethodCode
                    } = {}
                } = {},
                syncCartUi
            } = this.props;
            const {
                carrier_code: selectedCarrierCode,
                method_code: selectedMethodCode
            } = selectedCarrier;
            const guestCartId = isSignedIn() ? '' : getGuestQuoteId();
            const differentCarrier = !currentCarrierCode || (currentCarrierCode !== selectedCarrierCode);
            const differentMethod = currentMethodCode !== selectedMethodCode;

            if (!this.cartResetInProgress) {
                if ((differentCarrier || differentMethod) && !isLoading) {
                    setPayPalState({ selectedCarrier, isLoading: true });
                    this.cartResetInProgress = true;

                    try {
                        const {
                            setShippingMethodOnCart = {}
                        } = await fetchMutation(
                            PayPalQuery.getSetShippingMethodOnCartMutation({
                                guest_cart_id: guestCartId,
                                shipping_method: {
                                    carrier_code: selectedCarrierCode,
                                    method_code: selectedMethodCode
                                }
                            })
                        );

                        await syncCartUi(setShippingMethodOnCart);

                        setPayPalState({ isLoading: false });
                    } catch (e) {
                        setPayPalState({ isLoading: false });
                    }

                    this.cartResetInProgress = false;
                }
            }
        };

        if (shipping_methods && shipping_methods.length) {
            this.setOrderButtonVisibility(true);

            /* eslint-disable react/jsx-no-bind */
            return (
                <CheckoutDeliveryOptions
                  shippingMethods={ shipping_methods }
                  onShippingMethodSelect={ onShippingMethodSelect }
                  estimateAddress={ {} }
                  onStoreSelect={ () => {} }
                  selectedShippingMethod={ selectedCarrier }
                />
            );
        }

        return null;
    }

    render = (args, cb, instance) => {
        const {
            onBillingSuccess,
            onBillingError,
            payPalState: {
                widgetLocation,
                shipping_address
            } = {}
        } = instance.props;

        if ((!widgetLocation || widgetLocation === INSTANT_PAYMENT_LOCATION_CHECKOUT)) {
            return cb(...args);
        }

        // virtual product
        if (Object.keys(shipping_address).length === 0) {
            return (
                <Form
                  mix={ { block: 'CheckoutBilling' } }
                  id={ BILLING_STEP }
                  onSubmitError={ onBillingError }
                  onSubmitSuccess={ onBillingSuccess }
                >
                    { instance.renderPayments() }
                    { <InstantPayPalAddress /> }
                    { instance.renderTermsAndConditions() }
                    { instance.renderActions() }
                    { instance.renderPopup() }
                </Form>
            );
        }

        return (
            <Form
              mix={ { block: 'CheckoutBilling' } }
              id={ BILLING_STEP }
              onSubmitError={ onBillingError }
              onSubmitSuccess={ onBillingSuccess }
            >
                { instance.renderPayments() }
                { <InstantPayPalAddress /> }
                { this.renderCarrier.call(instance) }
                { instance.renderTermsAndConditions() }
                { instance.renderActions() }
                { instance.renderPopup() }
            </Form>
        );
    };
}

const { render } = new CheckoutBillingComponent();

const config = {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            render
        }
    }
};

export default config;
