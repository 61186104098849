/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 */

import CheckoutQuery from '@scandipwa/scandipwa/src/query/Checkout.query';
import { PAYMENT_TOTALS } from '@scandipwa/scandipwa/src/route/Checkout/Checkout.config';
import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase/BrowserDatabase';
import { fetchMutation } from '@scandipwa/scandipwa/src/util/Request';
import { ONE_MONTH_IN_SECONDS } from '@scandipwa/scandipwa/src/util/Request/QueryDispatcher';

/** @namespace Paypal/Component/PayPal/Util/payPalDataParser */
export const payPalDataParser = (address, token) => {
    const {
        shipping_address: shippingAddress,
        billing_address: billingAddress,
        shipping_address: {
            firstname,
            lastname,
            telephone,
            email
        } = {}
    } = address;

    return {
        billing_address: (
            billingAddress
                && billingAddress.street
                && billingAddress.city
                && billingAddress.postcode
                && billingAddress?.region?.region_id
        )
            ? billingAddress
            : shippingAddress,
        shipping_address: shippingAddress,
        customer: {
            firstname,
            lastname,
            telephone,
            email
        },
        token
    };
};

/** @namespace Paypal/Component/PayPal/Util/payPalBillingDataParser */
export const payPalBillingDataParser = (address, token) => {
    const {
        billing_address: billingAddress,
        billing_address: {
            firstname,
            lastname,
            telephone,
            email
        } = {}
    } = address;

    return {
        billing_address: billingAddress,
        customer: {
            firstname,
            lastname,
            telephone,
            email
        },
        token
    };
};

/** @namespace Paypal/Component/PayPal/Util/setPaymentMethodOnCart */
export const setPaymentMethodOnCart = ({ guest_cart_id, token, payerID }) => (
    fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
        guest_cart_id,
        payment_method: {
            code: 'paypal_express',
            paypal_express: {
                payer_id: payerID,
                token
            }
        }
    }))
);

/** @namespace Paypal/Component/PayPal/Util/estimateShipping */
export const estimateShipping = (
    { country_id = 'US', guest_cart_id }
) => fetchMutation(CheckoutQuery.getEstimateShippingCosts(
    { country_id },
    guest_cart_id
));

/** @namespace Paypal/Component/PayPal/Util/getShippingMethods */
export const getShippingMethods = (shipping_address, guest_cart_id) => {
    const {
        country_id,
        region: {
            region,
            region_id
        },
        city,
        postcode
    } = shipping_address;

    return fetchMutation(CheckoutQuery.getEstimateShippingCosts(
        {
            country_id,
            postcode,
            region_id,
            region,
            city
        },
        guest_cart_id
    ))
        .then(
            /** @namespace Paypal/Component/PayPal/Util/fetchMutation/then */
            ({ estimateShippingCosts: shippingMethods }) => shippingMethods
        );
};

/** @namespace Paypal/Component/PayPal/Util/getSelectedCarrier */
export const getSelectedCarrier = (payPalState, shippingMethods = [{}], cartTotals) => {
    const {
        selectedCarrier = {},
        selectedCarrier: {
            method_code: selectedCode
        } = {}
    } = payPalState;
    const { shipping_method } = cartTotals;

    // Get shipping method from totals if still available
    if (shipping_method?.includes('_')) {
        const [cartCode, cartMethod] = shipping_method?.split('_');

        const cartCarrierAvailability = shippingMethods.findIndex(
            ({ carrier_code, method_code }) => carrier_code === cartCode && method_code === cartMethod
        );

        if (cartCarrierAvailability > -1) {
            return shippingMethods[cartCarrierAvailability];
        }
    }

    const selectedCarrierAvailability = shippingMethods.findIndex(
        ({ method_code }) => method_code === selectedCode
    );

    // Get previously selected shipping method if still available
    if (selectedCarrierAvailability > -1) {
        return shippingMethods[selectedCarrierAvailability];
    }

    // Get the first shipping method
    if (!Object.keys(selectedCarrier) || selectedCarrierAvailability === -1) {
        return shippingMethods[0];
    }

    return {};
};

/**
 * Select only the fields necessary for setting address;
 * @param address
 * @returns {{}|{}}
 * @namespace Paypal/Component/PayPal/Util/filterAllowedAddressFields */
export const filterAllowedAddressFields = (address) => {
    const {
        region_id: formRegionId,
        region: {
            region,
            region_id
        } = {},
        ...remainder
    } = address;
    const requiredFields = [
        'city',
        'country_id',
        'firstname',
        'lastname',
        'postcode',
        'region_id',
        'street',
        'telephone'
    ];
    const data = {
        ...remainder,
        region,
        region_id: +formRegionId || region_id
    };

    return Object.keys(data).reduce((acc, item) => {
        if (requiredFields.includes(item)) {
            return {
                ...acc,
                [item]: data[item]
            };
        }

        return acc;
    }, {});
};

/** @namespace Paypal/Component/PayPal/Util/handleError */
export const handleError = (error) => {
    const { message, debugMessage } = error;

    showNotification(debugMessage || message);

    return false;
};

/** @namespace Paypal/Component/PayPal/Util/saveAddressInformation */
export const saveAddressInformation = async (
    payPalState,
    guest_cart_id
) => {
    const {
        shipping_address,
        billing_address,
        selectedCarrier: {
            carrier_code,
            method_code
        }
    } = payPalState;

    const addressInformation = {
        billing_address: filterAllowedAddressFields(shipping_address),
        shipping_address: filterAllowedAddressFields(billing_address),
        shipping_carrier_code: carrier_code || '',
        shipping_method_code: method_code || ''
    };

    return fetchMutation(CheckoutQuery.getSaveAddressInformation(
        addressInformation,
        guest_cart_id
    ))
        .then(
            /** @namespace Paypal/Component/PayPal/Util/fetchMutation/then */
            ({ saveAddressInformation: data }) => {
                const { totals } = data;

                BrowserDatabase.setItem(
                    totals,
                    PAYMENT_TOTALS,
                    ONE_MONTH_IN_SECONDS
                );

                return totals;
            },
            handleError
        )
        .catch(
            /** @namespace Amazonpayfe/App/Component/AmazonPayPayment/Handler/fetchMutation/then/catch */
            handleError
        );
};

/** @namespace Paypal/Component/PayPal/Util/saveGuestEmail */
export const saveGuestEmail = async (email, guestCartId) => {
    if (!isSignedIn()) {
        const mutation = CheckoutQuery.getSaveGuestEmailMutation(email, guestCartId);

        return fetchMutation(mutation);
    }

    return Promise.resolve();
};
