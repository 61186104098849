import {
    AddIcon as SourceAddIcon
} from 'SourceComponent/StarIcon/StarIcon.component';
import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'SourceComponent/StarIcon/StarIcon.config';

import './StarIcon.override.style';

/** @namespace Scandiweb/MoninTheme/Component/StarIcon/Component/StarIconComponent */
export class StarIconComponent extends SourceAddIcon {
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    /**
     * Overridden in order to remove rounded edges from SVG stars
     */
    getStarPath() {
        // Filling of star (empty, half-full, full)
        const { starFill } = this.props;

        // Empty and full stars use the same path,
        // we only change the fill
        const starPath = `M11 16.0791L17.489 19.8846L15.767 12.7123L21.4999 
                7.88656L13.9505 7.25401L11 0.5L8.04948 7.25401L0.5 
                7.88656L6.22248 12.7123L4.51099 19.8846L11 16.0791Z`;

        if (starFill === STAR_HALF_FULL) {
            return (
                <>
                    <path d={ starPath } />
                    <path d={ `M8.04948 7.25401L11 0.5V16.0791L4.51099 
                        19.8846L6.22248 12.7123L0.5 7.88656L8.04948 7.25401Z` }
                    />
                </>
            );
        }

        if (starFill === STAR_EMPTY || STAR_FULL) {
            return (
                <path d={ starPath } />
            );
        }

        return null;
    }

    /**
     * Overridden in order to differentiate between "empty", "halfFull" and "full" states
    */
    render() {
        // empty, half-full, full
        const { starFill } = this.props;

        return (
            <svg
              block="StarIcon"
              mods={ { fillState: starFill } }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                { this.getStarPath() }
            </svg>
        );
    }
}

export default StarIconComponent;
