/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { sendClickEvent } from '../../util/tracking';

export const onProductClick = (args, callback, instance) => {
    const { product: { id } = {} } = instance.props;
    callback(...args);
    sendClickEvent({ product: id });
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            registerSharedElement: onProductClick
        }
    }
};
