/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const getResetPasswordMutation = (args, callback) => {
    const [{ customer_id }] = args;

    const mutation = callback(...args);
    mutation.addArgument('customer_id', 'String!', customer_id);

    return mutation;
};

export default {
    'Query/MyAccount': {
        'member-function': {
            getResetPasswordMutation
        }
    }
};
