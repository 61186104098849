/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/zendesk
 * @author  Jort Geurts <info@scandiweb.com>
 */

export const ZENDESK_SCRIPT_ID = 'ze-snippet';

export const CONTACT_US_LINK_ID = 'footer-contact-us-link';
