/**
 * @returns { type: string, img: string }
 * @namespace Scandiweb/Payflowpro/Util/Card/Util/creditCardType
*/
export const creditCardType = (cc) => {
    const amex = new RegExp('^3[47][0-9]{13}$');
    const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    const cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
    const cup2 = new RegExp('^81[0-9]{14}[0-9]*$');
    const mastercard = new RegExp('^5[1-5][0-9]{14}$');
    const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
    const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
    const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
    const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
    const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
    const jcb = new RegExp('^35[0-9]{14}[0-9]*$');

    if (visa.test(cc)) {
        return { type: 'VISA', img: '/VISA.svg' };
    }
    if (amex.test(cc)) {
        return { type: 'AMEX', img: '/AMEX.svg' };
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
        return { type: 'MASTERCARD', img: '/MASTERCARD.svg' };
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
        return { type: 'DISCOVER', img: '/DISCOVER.svg' };
    }
    if (diners.test(cc)) {
        return { type: 'DINERS', img: undefined };
    }
    if (jcb.test(cc)) {
        return { type: 'JCB', img: undefined };
    }
    if (cup1.test(cc) || cup2.test(cc)) {
        return { type: 'CHINA_UNION_PAY', img: undefined };
    }

    return { type: undefined, img: undefined };
};

export const months = [
    { label: '1 / Jan', id: 'Jan', value: '1' },
    { label: '2 / Feb', id: 'Feb', value: '2' },
    { label: '3 / Mar', id: 'Mar', value: '3' },
    { label: '4 / Apr', id: 'Apr', value: '4' },
    { label: '5 / May', id: 'May', value: '5' },
    { label: '6 / Jun', id: 'Jun', value: '6' },
    { label: '7 / Jul', id: 'Jul', value: '7' },
    { label: '8 / Aug', id: 'Aug', value: '8' },
    { label: '9 / Sept', id: 'Sept', value: '9' },
    { label: '10 / Oct', id: 'Oct', value: '10' },
    { label: '11/ Nov', id: 'Nov', value: '11' },
    { label: '12 / Dec', id: 'Dec', value: '12' }
];

export const altCodes = {
    AMEX: 'AE',
    CHINA_UNION_PAY: 'UN',
    DINERS: 'DN',
    DISCOVER: 'DI',
    JBS: 'JCB',
    MASTERCARD: 'MC',
    VISA: 'VI'
};

/**
 * @namespace Scandiweb/Payflowpro/Util/Card/Util/getCcCodeByAltCodeUtil
 */
export const getCcCodeByAltCodeUtil = (altCode = 'VISA') => altCodes[altCode];
