/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { DETAILS_STEP } from 'Route/Checkout/Checkout.config';

import { sendPurchaseEvent } from '../../util/tracking';

export const aroundComponentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const { totals: { items = [] } = {} } = instance.props;

    const {
        ignoreClerkLog = false,
        checkoutStep,
        orderID,
        email: gEmail,
        customer: gCustomer
    } = instance.state;

    if (checkoutStep !== DETAILS_STEP || !orderID || ignoreClerkLog) {
        return;
    }

    const email = gCustomer?.email || gEmail;
    const customer = gCustomer?.id;

    const products = items.map(
        ({ product: { id }, price, qty: quantity }) => ({
            id,
            price,
            quantity
        })
    );

    // Needed to send purchase event only once (after switched from details step)
    instance.setState({
        ignoreClerkLog: true
    }, () => sendPurchaseEvent({ sale: orderID, email, customer, products }));
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            componentDidUpdate: aroundComponentDidUpdate
        }
    }
};
