import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import { updateBoltCheckoutType } from './CartReducer.plugin';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
        updateBoltCheckoutType: (type) => dispatch(updateBoltCheckoutType(type))
    };
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        freeShippingAction: state.ConfigReducer.checkoutActions.freeShippingAction
    };
};

const componentDidMount = (args, callback, _instance) => {
    callback(...args);

    window.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
            const boltInstances = document.querySelectorAll('[aria-label="Bolt CHECKOUT"]');
            const overlayVisible = document.getElementById('cart_overlay')?.classList.contains('Overlay_isVisible');

            if (boltInstances?.length > 1 && overlayVisible) {
                boltInstances.forEach((boltInstance) => {
                    if (boltInstance.parentNode?.getAttribute('aria-hidden') === 'true') {
                        boltInstance.setAttribute('tabindex', '-1');
                    } else {
                        boltInstance.setAttribute('tabindex', '0');
                    }
                });
            }

            if (boltInstances?.length > 1 && !overlayVisible) {
                boltInstances[0].setAttribute('tabindex', '-1');
                if (boltInstances[1].parentNode?.getAttribute('aria-hidden') === 'true') {
                    boltInstances[1].setAttribute('tabindex', '-1');
                } else {
                    boltInstances[1].setAttribute('tabindex', '0');
                }
            }

            if (boltInstances?.length === 1) {
                if (boltInstances[0].parentNode?.getAttribute('aria-hidden') === 'true') {
                    boltInstances[0].setAttribute('tabindex', '-1');
                } else {
                    boltInstances[0].setAttribute('tabindex', '0');
                }
            }
        }
    });
};

const containerProps = (args, callback, instance) => {
    const {
        freeShippingAction, showOverlay, totals, updateBoltCheckoutType
    } = instance.props;

    return {
        ...callback.apply(instance, args),
        freeShippingAction,
        showOverlay,
        totals,
        updateBoltCheckoutType
    };
};

export const config = {
    'BoltCheckout/Component/Bolt/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'BoltCheckout/Component/Bolt/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'BoltCheckout/Component/Bolt/Container/BoltContainer': {
        'member-function': {
            containerProps,
            componentDidMount
        }
    }
};

export default config;
