/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * This will initialize the recommendation widgets on the HomePage,
 * as they are loaded through CMS and are only initialized on the first load
 */
const initClerkWidgets = (args, callback, instance) => {
    const { location: { pathname } } = instance.props;
    const { Clerk: clerk } = window;

    callback(...args);

    // This will trigger when components are rendered, so Clerk could initialize them
    window.requestAnimationFrame(() => {
        const homePagePathnameRegEx = new RegExp(`^/(${window.storeList.join('|')})/?`, 'i');
        const isHomepage = homePagePathnameRegEx.test(pathname);

        if (isHomepage && clerk instanceof Function) {
            // This will init all blocks with "clerk" class, this will be all the widgets that are inserted in the homepage CMS
            // It will not do anything if the widgets are already initialized
            clerk('content', '.clerk');
        }
    });
};

export default {
    'Route/CmsPage/Container': {
        'member-function': {
            onPageLoad: initClerkWidgets
        }
    }
};
