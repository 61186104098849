/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    SwipeToDelete as SourceSwipeToDelete
} from 'SourceComponent/SwipeToDelete/SwipeToDelete.component';

/** @namespace Scandipwa/Component/SwipeToDelete/Component/SwipeToDeleteComponent */
export class SwipeToDeleteComponent extends SourceSwipeToDelete {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    setDraggableRemoveThreshold() {
        const { draggableWidth } = this;
        const {
            dragRightOpenThreshold,
            dragItemRemoveThreshold
        } = this.props;

        this.draggableRemoveThreshold = Math.abs(draggableWidth * dragItemRemoveThreshold - dragRightOpenThreshold);
    }
}

export default SwipeToDeleteComponent;
