/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext } from 'react';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const ResultsContext = createContext({
    spanAttributes: {},
    noResultsText: '',
    targetId: '',
    itemCount: -1,
    searchValue: ''
});

ResultsContext.displayName = 'ClerkResults';
export { ResultsContext };
