/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Link from 'SourceComponent/Link/Link.component';
import { LinkContainer as SourceLinkContainer, mapStateToProps } from 'SourceComponent/Link/Link.container';
import { updateNoMatch as updateNoMatchAction } from 'Store/NoMatch/NoMatch.action';
import { appendWithStoreCode } from 'Util/Url';

export const NoMatchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
);

/** @namespace Scandipwa/Component/Link/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateNoMatch: (options) => dispatch(updateNoMatchAction(options))
});

/** @namespace Scandipwa/Component/Link/Container/LinkContainer */
export class LinkContainer extends SourceLinkContainer {
    static propTypes = {
        ...this.propTypes,
        onClick: PropTypes.func
    };

    static defaultProps = {
        onClick: () => {}
    };

    containerFunctions = {
        onClick: this.onClick.bind(this)
    };

    // Resets no match state on redirect
    onClick(e) {
        const { updateNoMatch, onClick } = this.props;
        updateNoMatch(false);

        if (onClick) {
            onClick(e);
        }
    }

    getTo() {
        const { to: toProp } = this.props;
        const to = toProp || '/';

        if (typeof to === 'string') {
            // Checks if the link is absolute with the wrong format
            if (/^monin\.com/.test(to)) {
                return `https://${to}`;
            }

            if (/^https?:\/\//.test(to)) {
                return to;
            }

            return appendWithStoreCode(to);
        }

        const pathname = to.pathname || '/';

        return {
            ...to,
            pathname: appendWithStoreCode(pathname)
        };
    }

    render() {
        return (
            <Link
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
