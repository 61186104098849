/* eslint-disable */
import Form from "Component/Form";
import { lazy } from "react";

export const ClientTypePopup = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "client-type" */
        "../component/ClientTypePopup"
        )
);

export const renderPopup = (onFormSubmit, disableClientTypeShown, setClientType, getClientType, showSuccess) => {
    return (
        <ClientTypePopup
            onFormSubmit={onFormSubmit}
            disableClientTypeShown={disableClientTypeShown}
            setClientType={setClientType}
            getClientType={getClientType}
            showSuccess={showSuccess}
        />
    );
};

export const renderActions = (args, callback, instance) => {
    return (
        <button
            block="Button"
            mods={ { isHollow: true } }
            mix={ { block: 'FieldForm', elem: 'Button' } }
            aria-label={ __('Submit').toString() }
        >
            { __('Sign Up').toString() }
        </button>
    );
};

export const render = (args, callback, instance) => {
    const {
        isLoading,
        onFormSubmit,
        isClientTypeShown,
        setClientType,
        disableClientTypeShown,
        getClientType,
        showSuccess,
        key = 0
    } = instance.props;

    return (
        <div block="NewsletterSubscription">
            <Form
                onSubmitSuccess={onFormSubmit}
                mix={{ block: "FieldForm" }}
                validateOnLoad={ false }
                key={ key }
            >
                <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                    { isClientTypeShown ? renderPopup(onFormSubmit, disableClientTypeShown, setClientType, getClientType, showSuccess)  : null}
                    <div block="FieldForm" elem="Input">
                        { instance.renderFields() }
                    </div>
                    <div block="FieldForm" elem="Caption">
                        { __('BE THE FIRST TO HEAR ABOUT NEW FLAVORS AND GET RECIPES SENT TO YOUR INBOX').toString() }
                    </div>
                </div>
                <div block="FieldForm" elem="Button">
                    { renderActions() }
                </div>
            </Form>
        </div>
    );
};

export default {
    "Component/NewsletterSubscription/Component": {
        "member-function": {
            renderActions,
            renderPopup,
            render,
        },
    },
};
