/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    FieldContainer as MoninFieldContainer
} from 'MoninComponent/Field/Field.container';
import { CHECKBOX_TYPE, RADIO_TYPE } from 'SourceComponent/Field/Field.config';

import Field from './Field.component';

/** @namespace Scandipwa/Component/Field/Container/FieldContainer */
export class FieldContainer extends MoninFieldContainer {
    static propTypes = {
        ...MoninFieldContainer.propTypes,
        valueChanged: PropTypes.bool,
        message: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        isPlaceholderIncluded: PropTypes.bool,
        size: PropTypes.number,
        selectedOptions: PropTypes.arrayOf(PropTypes.shape),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.arrayOf(PropTypes.number)
        ])
    };

    static defaultProps = {
        ...MoninFieldContainer.defaultProps,
        valueChanged: false,
        message: '',
        isPlaceholderIncluded: true,
        size: null,
        selectedOptions: []
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            valueChanged: false
        };
    }

    containerProps() {
        const {
            message, isPlaceholderIncluded, size, fieldStyle, selectedOptions, categoryFilter, renderADA
        } = this.props;
        const { checked } = this.state;

        return {
            ...super.containerProps(),
            fieldStyle,
            message,
            isPlaceholderIncluded,
            size,
            checked,
            selectedOptions,
            categoryFilter,
            renderADA
        };
    }

    componentDidMount() {
        const { id } = this.props;
        const input = document.getElementById(id);

        if (input) {
            input.addEventListener('keydown', (e) => {
                if (e.code === 'Escape') {
                    input.blur();
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked, isSubmitted: prevSubmitted } = prevProps;
        const {
            value: currentValue,
            checked: currChecked,
            type,
            id,
            validateSeparately,
            isSubmitted,
            valueChanged: propsValueChanged = false,
            onClearInput = () => {}
        } = this.props;
        const { eventId, valueChanged } = this.state;

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue, valueChanged: true });
        }

        if ((type === RADIO_TYPE || type === CHECKBOX_TYPE) && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked, valueChanged: true });
        }

        // prevents validating all fields when entering data in only one of them
        if (eventId === id
            || prevSubmitted !== isSubmitted
            || (!validateSeparately && (valueChanged || propsValueChanged))
        ) {
            this.updateValidationStatus();
            this.setValidationMessage(prevProps);
        }

        const notSameIsSubmitted = prevSubmitted !== isSubmitted;
        if (isSubmitted && notSameIsSubmitted && currentValue !== '') {
            // After submitted, clear newsletter field value
            // Disabled to detect submitting
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: '' });

            if (onClearInput) {
                onClearInput();
            }
        }
    }

    render() {
        return (
            <Field
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldContainer;
