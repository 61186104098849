import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { fetchQuery } from 'Util/Request';

import ClientTypeQuery from '../query/ClientType.query';
import { formatConsents } from '../util/Privacy';

/** @namespace Component/CheckoutAddressForm/Component */
const state = (originalMember) => ({
    ...originalMember,
    clientType: []
});

export const containerProps = (args, callback, { state: { clientType } }) => ({
    ...callback(...args),
    clientType
});

export const componentDidMount = (args, callback, instance) => {
    const { setLoadingState } = instance.props;
    setLoadingState(true);
    fetchQuery(ClientTypeQuery.getClientTypeQuery())
        .then(
            /** @namespace Component/MyAccountCreateAccount/Container/fetchQueryThen */
            ({ clientType }) => {
                instance.setState({ clientType });
                setLoadingState(false);
            },
            instance._handleError
        );

    return {
        ...callback(...args)
    };
};

export class MyAccountCreateAccountContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(instance)
    });

    async onCreateAccountSuccess(fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat,
            customer_type
        } = fields;

        const {
            privacyConsentSelection = {}
        } = this.state;

        const formattedConsentSelection = formatConsents(privacyConsentSelection);

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat,
                privacyConsentSelection: formattedConsentSelection,
                customer_type
            },
            password
        };

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData);

            // if user needs confirmation
            if (code === 2) {
                setSignInState(STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.').toString()
                    );
                }
            } else {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }
}

const { containerFunctions } = new MyAccountCreateAccountContainer();

export default {
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            componentDidMount,
            containerProps
        },
        'member-property': {
            state,
            containerFunctions
        }
    }
};
