/* eslint-disable react/jsx-no-bind,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import SourceFieldSelect from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Scandiweb/MoninTheme/Component/FieldSelect/Component/FieldSelectComponent  */
export class FieldSelectComponent extends SourceFieldSelect {
    /**
     * Overridden to add `isSelected` modifier applying different formatting to selected option,
     * added onTouchEnd to prevent onMouseDown from firing on mobile
     */
    renderOption = (option) => {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            handleSelectExpandedExpand,
            value
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded, isPlaceholder, isSelected: String(id) === String(value) } }
              key={ id }
              isPlaceholder
              id={ `o${id}` }
              role="menuitem"
              onMouseDown={ () => handleSelectListOptionClick(option) }
              onTouchStart={ () => handleSelectListOptionClick(option) }
              onTouchEnd={ (e) => handleSelectExpandedExpand(e) }
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    };
}

export default FieldSelectComponent;
