import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductWriteReviewButton from './ProductWriteReviewButton.component';

/** @namespace Scandiweb/MoninTheme/Component/ProductWriteReviewButton/Container/ProductWriteReviewButtonContainer */
export class ProductWriteReviewButtonContainer extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired
    };

    containerProps = () => {
        const { onClick } = this.props;

        return {
            onClick
        };
    };

    render() {
        return (
            <ProductWriteReviewButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductWriteReviewButtonContainer;
