/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Clerk/Query/ClerkTrackingConfig/Query */
export class ClerkTrackingConfigQuery {
    getQuery() {
        return new Field('clerkTrackingConfig')
            .addFieldList(this.getConfigFields());
    }

    getConfigFields() {
        return [
            'publicKey',
            'collectEmail',
            'collectBasket',
            'language',
            'formKey'
        ];
    }
}

export default new ClerkTrackingConfigQuery();
