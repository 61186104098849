/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const UPDATE_INFO_COOKIE_GROUP = 'UPDATE_INFO_COOKIE_GROUP';
export const SET_COOKIE_POPUP_VISIBILITY = 'SET_COOKIE_POPUP_VISIBILITY';

/** @namespace GdprCookie/Store/Cookies/Action/setCookiePopupVisibilityTo */
export const setCookiePopupVisibilityTo = (isPopupOpen) => ({
    type: SET_COOKIE_POPUP_VISIBILITY,
    isPopupOpen
});

/** @namespace GdprCookie/Store/Cookies/Action/updateInfoCookieGroup */
export const updateInfoCookieGroup = (cookieInfo) => ({
    type: UPDATE_INFO_COOKIE_GROUP,
    cookieInfo
});
