/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Clerk/Query/ClerkWidget/Query */
export class ClerkWidgetQuery {
    getCartQuery = ({ guestCartId }) => new Field('clerkCartWidget')
        .addArgument('guestCartId', 'String', guestCartId)
        .addFieldList(this._getChildFields());

    getProductQuery = ({ productId }) => new Field('clerkProductWidget')
        .addArgument('productId', 'String!', productId)
        .addFieldList(this._getChildFields());

    getCategoryQuery = ({ categoryId }) => new Field('clerkCategoryWidget')
        .addArgument('categoryId', 'String!', categoryId)
        .addFieldList(this._getChildFields());

    _getChildFields() {
        return [
            'spanHtml'
        ];
    }
}

export default new ClerkWidgetQuery();
