export * from 'SourceComponent/FieldSelect/FieldSelect.config';

export const A_CLICKABLE_HEIGHT = 40; // in px;

export const ARROW_UP_KEYCODE = 'ArrowUp';

export const ARROW_DOWN_KEYCODE = 'ArrowDown';

export const ENTER_KEYCODE = 'Enter';

export const ESC_KEYCODE = 'Escape';

export const TAB_KEYCODE = 'Tab';

export const SPACE_KEYCODE = 'Space';
