/* eslint-disable quote-props */
export const BOLT_CHECKOUT = 'bolt';
export const APPLE_PAY_CHECKOUT = 'apple_pay';

export const UPDATE_BOLT_CHECKOUT_TYPE = 'UPDATE_BOLT_CHECKOUT_TYPE';

export const updateBoltCheckoutType = (boltCheckoutType) => ({
    type: UPDATE_BOLT_CHECKOUT_TYPE,
    boltCheckoutType
});

const getInitialState = (args, callback) => ({
    ...callback(...args),
    boltCheckoutType: BOLT_CHECKOUT
});

const CartReducer = (args, callback) => {
    const [state, action] = args;

    const { type, boltCheckoutType } = action;

    switch (type) {
    case UPDATE_BOLT_CHECKOUT_TYPE:
        return {
            ...state,
            boltCheckoutType
        };
    default:
        return {
            ...state,
            ...callback(...args)
        };
    }
};

export default {
    'Store/Cart/Reducer/getInitialState': {
        function: getInitialState
    },
    'Store/Cart/Reducer': {
        function: CartReducer
    }
};
