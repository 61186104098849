/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import GdprCookieQuery from '../../query/GdprCookie.query';
import { setCookiePopupVisibilityTo } from '../../store/Cookies';
import GdprCookiePopup from './GdprCookiePopup.component';

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isCookieBarActive: state?.ConfigReducer?.gdpr_cookie_bar,
    location: state.MetaReducer.canonical_url,
    isPopupOpen: state.CookiesReducer.isPopupOpen
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setCookiePopupVisibilityTo: (isPopupOpen) => dispatch(setCookiePopupVisibilityTo(isPopupOpen))
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/GdprCookiePopupContainer */
export class GdprCookiePopupContainer extends DataContainer {
    static propTypes = {
        isCookieBarActive: PropTypes.bool.isRequired,
        location: PropTypes.string.isRequired,
        isCookiePopupOpen: PropTypes.func.isRequired,
        isPopupOpen: PropTypes.bool.isRequired
    };

    state = {
        isCookieSet: !!this.handleGetCookie('amcookie_allowed'),
        isCookieSetState: false,
        cookieSettings: null,
        isClosed: false
    };

    containerFunctions = {
        handleClosePopup: this.handleClosePopup.bind(this)
    };

    componentDidUpdate(prevProps) {
        const { location, isCookieBarActive, setCookiePopupVisibilityTo } = this.props;
        const { location: prevLocation } = prevProps;
        const { isCookieSet, isCookieSetState } = this.state;

        this.handleAcceptedCookies();

        if ((location && prevLocation) && (location !== prevLocation) && !isCookieSet
        && !isCookieSetState && isCookieBarActive) {
            this.handleAcceptAllGroups();
            this.setState({ isCookieSetState: true });
            setCookiePopupVisibilityTo(false);
        }
    }

    async handleAcceptAllGroups() {
        const request = await fetchMutation(GdprCookieQuery.setAllCookies());

        const { setAllCookies: { result } } = request;

        if (result) {
            this.handleClosePopup();
        }
    }

    handleClosePopup() {
        const { setCookiePopupVisibilityTo } = this.props;
        this.setState({ isClosed: true });
        setCookiePopupVisibilityTo(false);
    }

    handleGetCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);

        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }

        return '';
    }

    handleAcceptedCookies() {
        const {
            isCookieSet, isClosed, isCookieSetState
        } = this.state;
        const { setCookiePopupVisibilityTo, isCookiePopupOpen } = this.props;

        if (isCookieSet || isClosed || isCookieSetState || isCookiePopupOpen) {
            setCookiePopupVisibilityTo(false);
        } else {
            setCookiePopupVisibilityTo(true);
        }
    }

    render() {
        const {
            isCookieSet, isClosed, isCookieSetState
        } = this.state;
        const { isCookieBarActive } = this.props;

        return (
            <GdprCookiePopup
              isCookieSet={ isCookieSet }
              isCookieBarActive={ isCookieBarActive }
              isClosed={ isClosed }
              isCookieSetState={ isCookieSetState }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCookiePopupContainer);
