/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import CloseIcon from 'Component/CloseIcon';
import Field from 'Component/Field';
import Image from 'Component/Image';
import Link from 'Component/Link';
import TrashIcon from 'Component/TrashIcon';
import {
    CartItemComponent as SourceCartItemComponent
} from 'SourceComponent/CartItem/CartItem.component';

import { BLACK_PUMP_SKU } from './CartItem.config';

import './CartItem.extend.style.scss';

/** @namespace Scandipwa/Component/CartItem/Component/CartItemComponent */
export class CartItemComponent extends SourceCartItemComponent {
    renderProductConfigurations() {
        const {
            item: {
                sku
            }
        } = this.props;

        if (sku === BLACK_PUMP_SKU) {
            return (
                <div
                  block="CartItem"
                  elem="Options"
                >
                    { __('Black').toString() }
                </div>
            );
        }

        return super.renderProductConfigurations();
    }

    renderQuantityChangeField() {
        const {
            item: {
                qty,
                product: {
                    stock_item: {
                        qty_increments: qtyIncrement = 1
                    } = {}
                } = {}
            } = {},
            minSaleQuantity,
            maxSaleQuantity,
            handleChangeQuantity,
            isProductInStock,
            isCartOverlay
        } = this.props;

        if (!isProductInStock) {
            return <div block="CartItem" elem="QuantityWrapper" mods={ { isPlaceholder: true } } />;
        }

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div
              block="CartItem"
              elem="QuantityWrapper"
              mods={ { isCartOverlay } }
              onClick={ this.quantityClickHandler }
            >
                <Field
                  id="item_qty"
                  name="item_qty"
                  label="QTY"
                  type="number"
                  isControlled
                  min={ minSaleQuantity }
                  max={ maxSaleQuantity }
                  mix={ { block: 'CartItem', elem: 'Qty' } }
                  value={ qty }
                  onChange={ handleChangeQuantity }
                  step={ qtyIncrement }
                />
            </div>
        );
    }

    renderProductName() {
        const {
            item: {
                product: {
                    name
                }
            }
        } = this.props;

        return (
            <h3>
                <p
                  block="CartItem"
                  elem="Heading"
                >
                    { name }
                </p>
            </h3>
        );
    }

    handleKeyDown = (e) => {
        const enterCode = 13;
        if (e.key === 'Enter' || e.keyCode === enterCode) {
            this.handleItemClick();
        }
    };

    renderContent() {
        return (
            <span block="CartItem" elem="Link">
                { this.renderWrapperContent() }
            </span>
        );
    }

    renderImageElement() {
        const {
            thumbnail,
            isProductInStock,
            isMobileLayout
        } = this.props;
        const isNotAvailable = !isProductInStock;

        return (
            <>
            <Image
              src={ thumbnail }
              mix={ {
                  block: 'CartItem',
                  elem: 'Picture',
                  mods: {
                      isNotAvailable, isMobileLayout
                  }
              } }
              ratio="custom"
              alt=""
              emptyAlt
            />
                <img
                  style={ { display: 'none' } }
                  alt=""
                  src={ thumbnail }
                />
            </>
        );
    }

    renderTitle() {
        const { isMobileLayout } = this.props;
        const { linkTo = {}, isProductInStock } = this.props;

        const {
            item: {
                customizable_options,
                bundle_options,
                downloadable_links
            } = {}
        } = this.props;

        if (!isProductInStock || Object.keys(linkTo).length === 0) {
            return super.renderTitle();
        }

        return (
            <div block="CartItem" elem="Title" mods={ { isMobileLayout } }>
                <Link block="CartItem" elem="Link" to={ linkTo }>
                    { this.renderProductName() }
                </Link>
                { this.renderOutOfStockMessage() }
                { this.renderProductConfigurations() }
                { this.renderProductOptions(customizable_options) }
                { this.renderProductOptions(bundle_options) }
                { this.renderProductLinks(downloadable_links) }
            </div>
        );
    }

    /**
     * Changed translation to render as a string
     */
    renderDeleteIcon() {
        const { isMobileLayout, isCartOverlay } = this.props;

        if (isCartOverlay) {
            return <CloseIcon />;
        }

        if (isMobileLayout) {
            return (
                <span>{ __('Remove item').toString() }</span>
            );
        }

        return <TrashIcon />;
    }

    render() {
        const { isEditing, isCartOverlay, handleItemClick } = this.props;

        return (
            <section>
                <div
                  block="CartItem"
                  mods={ { isEditing, isCartOverlay } }
                  role="group"
                  aria-label={ __('Cart item').toString() }
                  onClick={ handleItemClick }
                  onKeyDown={ this.handleKeyDown }
                >
                    { this.renderLoader() }
                    { this.renderContent() }
                </div>
            </section>
        );
    }
}

export default CartItemComponent;
