/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import CheckoutQuery from '@scandipwa/scandipwa/src/query/Checkout.query';
import { CartDispatcher } from '@scandipwa/scandipwa/src/store/MyAccount/MyAccount.dispatcher';
import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { getFormFields } from '@scandipwa/scandipwa/src/util/Address';
import { fetchMutation } from '@scandipwa/scandipwa/src/util/Request';

import { INSTANT_PAYMENT_LOCATION_CHECKOUT } from '../component/InstantPayment/InstantPayment.config';
import { PAYPAL_EXPRESS } from '../component/PayPal/PayPal.config';
import { saveAddressInformation, setPaymentMethodOnCart } from '../component/PayPal/PayPal.util';

export const PayPalDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/PayPalPayment/PayPalPayment.dispatcher'
);

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        payPalState: state.PayPalPaymentReducer.state
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const dispatchProps = { ...callback(...args) };

    return {
        ...dispatchProps,
        setPayPalState: (state) => PayPalDispatcher.then(
            ({ default: dispatcher }) => dispatcher.setPayPalState(dispatch, state)
        ),
        syncCart: async () => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        ),
        syncCartUi: async (cartData) => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher._updateCartData(cartData, dispatch)
        ),
        showPaypalErrorNotification: (error) => dispatch(showNotification('error', error[0].message))
    };
};

/**
 * Container
 */
export class CheckoutBillingContainer {
    onBillingSuccess = async (args, cb, instance) => {
        const {
            payPalState: {
                token,
                payerID,
                guest_cart_id,
                widgetLocation,
                selectedCarrier,
                isCustomAddressExpanded
            } = {},
            setPayPalState,
            setDetailsStep,
            addressLinesQty
        } = instance.props;

        if ((!widgetLocation || widgetLocation === INSTANT_PAYMENT_LOCATION_CHECKOUT)) {
            return cb(...args);
        }

        try {
            setPayPalState({ isLoading: true });

            if (isCustomAddressExpanded) {
                const [fields] = args;
                const address = getFormFields(fields, addressLinesQty);

                await saveAddressInformation(
                    {
                        shipping_address: address,
                        billing_address: address,
                        selectedCarrier
                    },
                    guest_cart_id
                );
            }

            await setPaymentMethodOnCart({
                guest_cart_id,
                token,
                payerID
            });

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const { placeOrder: { order: { order_id } } } = orderData;

            setPayPalState({ isLoading: false });
            setDetailsStep(order_id);
        } catch (error) {
            setPayPalState({ isLoading: false });

            const { showPaypalErrorNotification } = instance.props;
            showPaypalErrorNotification(error);
        }

        return null;
    };

    containerProps = (args, cb, instance) => {
        const { payPalState, setLoading } = instance.props;
        return {
            ...cb.call(instance),
            payPalState,
            setLoading
        };
    };

    /**
     * Get payment method additional information
     */
    _getPaymentData(args, cb, instance) {
        const {
            payPalState: {
                token,
                widgetLocation
            } = {}
        } = instance.props;

        if ((!widgetLocation || widgetLocation === INSTANT_PAYMENT_LOCATION_CHECKOUT)) {
            return cb(...args);
        }

        return {
            method: PAYPAL_EXPRESS,
            code: PAYPAL_EXPRESS,
            additional_data: {
                token
            }
        };
    }
}

const { onBillingSuccess, containerProps } = new CheckoutBillingContainer();

const config = {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess,
            containerProps
        }
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutBilling/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

export default config;
