/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_SINGLE_POST = 'UPDATE_SINGLE_POST';
export const NO_POST = 'NO_POST';

/** @namespace Scandipwa/Store/PostsDetails/Action/updateSinglePost */
export const updateSinglePost = (post) => ({
    type: UPDATE_SINGLE_POST,
    post
});

/** @namespace Scandipwa/Store/PostsDetails/Action/postException */
export const postException = (message) => ({
    type: NO_POST,
    message
});
