/* eslint-disable max-len */
import {
    CartItemPriceComponent as MoninCartItemPriceComponent
} from 'MoninComponent/CartItemPrice/CartItemPrice.component';

import './CartItemPrice.extend.style';

/** @namespace Scandipwa/Component/CartItemPrice/Component/CartItemPriceComponent */
export class CartItemPriceComponent extends MoninCartItemPriceComponent {
    renderMinicart() {
        const { mix } = this.props;

        return (
            <div>
                <p block="ProductPrice" aria-label={ __('Product Price').toString() } mix={ mix }>
                    { this.renderHighPrice(false) }
                    { this.renderPrice() }
                </p>
                <p block="ProductPrice" aria-label={ __('Single Price').toString() } mix={ { ...mix, elem: 'SinglePrice' } }>
                    <span block="CartItem" elem="PriceLabel" mods={ { isCartOverlay: true } }>
                        { __('Price').toString() }
                    </span>
                    { this.renderSingleProductPrice() }
                </p>
            </div>
        );
    }

    renderDesktop() {
        const { mix } = this.props;

        return (
            <>
                <p block="ProductPrice" aria-label={ __('Single Price').toString() } mix={ { ...mix, elem: 'SinglePrice' } }>
                    <span block="CartItem" elem="PriceLabel">{ __('Price').toString() }</span>
                    { this.renderSingleProductPrice() }
                    { this.renderHighPrice(true) }
                </p>
                <p block="ProductPrice" aria-label={ __('Product Price').toString() } mix={ mix }>
                    <span block="CartItem" elem="PriceLabel">{ __('Total').toString() }</span>
                    { this.renderPrice() }
                    { this.renderHighPrice() }
                </p>
            </>
        );
    }
}

export default CartItemPriceComponent;
