import {
    MoninSearchIconComponent as MoninMoninSearchIconComponent
} from 'MoninComponent/MoninSearchIcon/MoninSearchIcon.component';

/** @namespace Scandipwa/Component/MoninSearchIcon/Component/MoninSearchIconComponent */
export class MoninSearchIconComponent extends MoninMoninSearchIconComponent {
    render() {
        return (
            <svg
              block="MoninSearchIcon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
                <path
                  d="M14 14L9.79994 9.80001M11.1429 6.57143C11.1429 9.09616
                   9.09616 11.1429 6.57143 11.1429C4.0467 11.1429 2 9.09616 2 6.57143C2
                   4.0467 4.0467 2 6.57143 2C9.09616 2 11.1429 4.0467 11.1429 6.57143Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default MoninSearchIconComponent;
