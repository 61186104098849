/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Clerk/Query/ClerkResults/Query */
export class ClerkResultsQuery {
    getQuery({ search }) {
        return new Field('clerkResults')
            .addArgument('searchQuery', 'String', search)
            .addFieldList(this.getConfigFields());
    }

    getConfigFields() {
        return [
            'spanAttributes',
            'targetId',
            'noResultsText'
        ];
    }
}

export default new ClerkResultsQuery();
