/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import {
    INSTANT_PAYMENT_LOCATION_CHECKOUT
} from '../component/InstantPayment/InstantPayment.config';
import { PAYPAL_EXPRESS } from '../component/PayPal/PayPal.config';
import { INSTANT_PAYPAL_BILLING_STEP } from '../route/Checkout/Checkout.config';

export const PayPalDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/PayPalPayment/PayPalPayment.dispatcher'
);

/**
 *
 * @param args
 * @param callback
 */
export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        payPalState: state.PayPalPaymentReducer.state
    };
};

/**
 *
 * @param args
 * @param callback
 */
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const dispatchProps = { ...callback(...args) };

    return {
        ...dispatchProps,
        setInitialPayPalState: () => PayPalDispatcher.then(
            ({ default: dispatcher }) => dispatcher.setInitialPayPalState(dispatch)
        )
    };
};

/**
 * Container
 */
export class CheckoutContainer {
    // eslint-disable-next-line consistent-return
    componentWillMount = (args, cb, instance) => {
        const {
            history: {
                location: {
                    pathname
                } = {}
            } = {},
            payPalState: {
                widgetLocation,
                isActiveInstant
            },
            setInitialPayPalState
        } = instance.props;

        if ((widgetLocation && widgetLocation !== INSTANT_PAYMENT_LOCATION_CHECKOUT)
            && isActiveInstant
            && pathname?.includes('billing')
        ) {
            instance.setState({
                ...instance.state,
                isGuestEmailSaved: true,
                checkoutStep: INSTANT_PAYPAL_BILLING_STEP
            });
        } else {
            setInitialPayPalState();
            return cb(...args);
        }
    };

    containerProps = (args, cb, instance) => {
        const { payPalState } = instance.props;
        return {
            ...cb.call(instance),
            payPalState
        };
    };

    /**
     * Add amazon to custom methods
     */
    customPaymentMethods(originalMember) {
        return [
            ...(originalMember || []),
            PAYPAL_EXPRESS
        ];
    }
}

const { customPaymentMethods, componentWillMount, containerProps } = new CheckoutContainer();

/**
 * Remove payment methods if enabled, but not supposed to be in the default checkout
 *
 * @param res
 * @param cb
 * @returns {*}
 */
const saveAddressInformationFetchMutationThen = (res, cb) => {
    const [data] = res;

    if (!data) {
        return cb(...res);
    }

    const {
        saveAddressInformation,
        saveAddressInformation: {
            payment_methods
        }
    } = data;
    const response = {
        ...data,
        saveAddressInformation: {
            ...saveAddressInformation,
            payment_methods: payment_methods.filter(({ location }) => {
                if (!location || !location[INSTANT_PAYMENT_LOCATION_CHECKOUT]) {
                    return true;
                }

                return location?.[INSTANT_PAYMENT_LOCATION_CHECKOUT] === '1';
            })
        }
    };

    return cb(response);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            customPaymentMethods,
            componentWillMount,
            containerProps
        }
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container/saveAddressInformationFetchMutationThen': {
        function: saveAddressInformationFetchMutationThen
    }
};
