/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

import ClerkExitIntentConfigQuery from '../query/ClerkExitIntentConfig.query';
import ClerkLiveSearchConfigQuery from '../query/ClerkLiveSearchConfig.query';
import ClerkTrackingConfigQuery from '../query/ClerkTrackingConfig.query';
import { ensureClerkIsLoaded } from './init';
import { getVisitor } from './visitor';

export const ONE_DAY_IN_SECONDS = 86400;
export const CLERK_CONFIGURED_EVENT = 'clerk-configured';
export const CLERK_SEARCH_WRAPPER_ID = 'clerk-livesearch';
export const DEFAULT_SEARCH_INPUT_ID = 'search';
export const CLERK_LIVE_SEARCH_ID = 'clerk-live-search';

/** @namespace Scandiweb/Clerk/Util/Config/requestConfig */
export const requestConfig = async () => {
    if (window._clerkConfig) {
        return window._clerkConfig;
    }

    if (window._clerkConfigLoading) {
        return new Promise((resolve) => {
            const timer = 100;
            setTimeout(() => resolve(requestConfig()), timer);
        });
    }

    if (window._clerkConfigLoading === undefined) {
        window._clerkConfigLoading = true;
    }

    window._clerkConfig = await executeGet(prepareQuery([
        ClerkLiveSearchConfigQuery.getQuery(),
        ClerkTrackingConfigQuery.getQuery(),
        ClerkExitIntentConfigQuery.getQuery()
    ]), 'ClerkConfig', Number.POSITIVE_INFINITY);

    window._clerkConfigLoading = false;

    return window._clerkConfig;
};

/** @namespace Scandiweb/Clerk/Util/Config/getConfig */
export const getConfig = async () => {
    await ensureClerkIsLoaded();

    const config = window._clerkConfig;

    if (config) {
        return config;
    }

    return requestConfig();
};

/** @namespace Scandiweb/Clerk/Util/Config/configureClerk */
export const configureClerk = async () => {
    if (window._isClerkConfigured) {
        return;
    }

    window._isClerkConfigured = true;

    const {
        clerkTrackingConfig: {
            publicKey: key,
            collectEmail: collect_email,
            formKey: formkey
        }
    } = await getConfig();

    const config = {
        key,
        collect_email,
        visitor: getVisitor(),
        globals: {
            uenc: window.location.href,
            formkey
        },
        debug: {
            collect: false,
            level: process.env.NODE_ENV === 'development' ? 'log' : 'warn'
        },
        store_settings: {}
    };

    // eslint-disable-next-line new-cap
    window.Clerk('config', config);

    document.dispatchEvent(new Event(CLERK_CONFIGURED_EVENT));
};

/** @namespace Scandiweb/Clerk/Util/Config/getClerk */
export const getClerk = async () => {
    await configureClerk();
    return window.Clerk;
};
