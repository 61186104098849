/* eslint-disable max-lines */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import PrintRecipe from 'Component/PrintRecipe';
import ShopFlavor from 'Component/ShopFlavor';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';

import { LATAM_STORE_CODE } from '../Header/Header.config';

import './RecipeActions.style';

/** @namespace Scandipwa/Component/RecipeActions/Component/RecipeActionsComponent */
export class RecipeActionsComponent extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        product: ProductType.isRequired,
        ingredients: PropTypes.arrayOf(PropTypes.string).isRequired,
        instructions: PropTypes.arrayOf(PropTypes.string).isRequired,
        currentStoreCode: PropTypes.string.isRequired,
        isRecipePdfEnabled: PropTypes.bool.isRequired,
        isPdfLoading: PropTypes.bool.isRequired,
        printPdf: PropTypes.func.isRequired
    };

    renderPrintPdfButton() {
        const { isRecipePdfEnabled, isPdfLoading, printPdf } = this.props;

        if (!isRecipePdfEnabled) {
            return null;
        }

        return (
            <button
              block="Button ShopFlavor RecipeActions"
              elem="PrintPdfButton"
              onClick={ printPdf }
            >
                <span>
                    { isPdfLoading ? __('Printing...') : __('Print / save recipe') }
                </span>
            </button>
        );
    }

    /**
     * render Recipe Ingredients for recipe product
     */
    renderRecipeIingredients() {
        const {
            ingredients
        } = this.props;

        return (
            <section
              block="RecipeActions"
              elem="Section"
            >
                <div block="RecipeActions" elem="IngredientsRow">
                    <h2 block="RecipeActions" elem="IngredientsTitle">
                        { __('Ingredients').toString() }
                    </h2>
                </div>
                { (ingredients.length === 0 || !ingredients)
                    ? this.renderRecipeObjectIngredientsContent()
                    : this.renderRecipeModifiedIngredientsContent() }
            </section>
        );
    }

    /**
     * render Ingredients with Product Links
     */
    renderRecipeModifiedIngredientsContent() {
        const { ingredients } = this.props;

        let ingredientsText = '';

        ingredients.forEach((ingredient) => {
            if (ingredient.match(/^\(*\)/gi)) {
                ingredientsText = ingredientsText.concat(
                    '<p>',
                    ingredient,
                    '</p>'
                );
            } else if (ingredient.replace('&reg;', '').toUpperCase() === ingredient.replace('&reg;', '')) {
                ingredientsText = ingredientsText.concat(
                    '<h3>',
                    ingredient,
                    '</h3>'
                );
            } else {
                ingredientsText = ingredientsText.concat(
                    '<li>',
                    ingredient,
                    '</li>'
                );
            }
        });

        return (
            <ul block="RecipeActions" elem="IngredientsContent">
                <Html content={ ingredientsText } />
            </ul>
        );
    }

    /**
     * render Ingredients fetched with product object
     */
    renderRecipeObjectIngredientsContent() {
        const { product: { ingredients } } = this.props;

        const ingredientsList = ingredients?.split(/[\r\n]+|<br\s*\/?>/);
        let ingredientsText = ingredients;

        if (!ingredients?.match('<li>')) {
            ingredientsText = '';
            ingredientsList?.forEach((element) => {
                const elementText = element.trim()
                    .replace(/<p>|<\/p>/gi, '')
                    .replace('&nbsp;', '')
                    .replace(/<b>/gi, '<strong>')
                    .replace(/<\/b>/gi, '</strong>');

                if (elementText !== '' && !elementText.match('<p></p>')) {
                    if (elementText.match('<h3>')) {
                        ingredientsText = ingredientsText.concat(elementText);
                    } else if (elementText.toUpperCase() === elementText) {
                        ingredientsText = ingredientsText.concat(
                            '<h3>',
                            elementText,
                            '</h3>'
                        );
                    } else if (elementText.match('<strong>')) {
                        ingredientsText = ingredientsText.concat(elementText);
                    } else {
                        ingredientsText = ingredientsText.concat(
                            '<li>',
                            elementText,
                            '</li>'
                        );
                    }
                }
            });
        }

        return (
            <div block="RecipeActions" elem="IngredientsContent">
                <Html content={ ingredientsText } />
            </div>
        );
    }

    /**
     * render Recipe Attributes for recipe product
     */
    renderRecipeAttributes() {
        return (
            <div block="RecipeActions" elem="Attributes">
                { this.renderRecipeGlassRecommended() }
                { this.renderRecipeGlassSize() }
                { this.renderRecipeGarnish() }
            </div>
        );
    }

    /**
     * render Glass Recommended for recipe product
     */
    renderRecipeGlassRecommended() {
        const {
            product: {
                attributes
            }
        } = this.props;

        const isGlass = attributes?.glass_recommended || null;

        if (!isGlass) {
            return null;
        }

        const { glass_recommended: { attribute_label, attribute_options } } = attributes;

        const glassValues = Object.keys(attribute_options)
            .map((item) => (attribute_options[item].label));

        return (
            <>
                { this.renderRecipeAttributeLine(attribute_label, glassValues) }
            </>
        );
    }

    /**
     * render Glass Size for recipe product
     */
    renderRecipeGlassSize() {
        const {
            product: {
                attributes
            }
        } = this.props;

        const isGlassSize = attributes?.serving_size_int || null;

        if (!isGlassSize) {
            return null;
        }

        const { serving_size_int: { attribute_label, attribute_value } } = attributes;

        const glassSizeValues = [`${attribute_value} oz.`];

        return (
            <>
                { this.renderRecipeAttributeLine(attribute_label, glassSizeValues) }
            </>
        );
    }

    /**
     * render Garnish for recipe product
     */
    renderRecipeGarnish() {
        const {
            product: {
                attributes
            }
        } = this.props;

        const isGarnish = attributes?.garnish || null;

        if (!isGarnish) {
            return null;
        }

        const { garnish: { attribute_label, attribute_options } } = attributes;

        const garnishValues = Object.keys(attribute_options)
            .map((item) => (attribute_options[item].label));

        return (
            <>
                { this.renderRecipeAttributeLine(attribute_label, garnishValues) }
            </>
        );
    }

    renderRecipeAttributeLine(attributeLable, attributeValues) {
        return (
            <div block="RecipeActions" elem="Attribute">
                <strong>
                    { attributeLable.replace(' Integer', '') }
                    { __(': ').toString() }
                </strong>
                { attributeValues.map((item, i) => (
                    <span>
                        { item }
                        { (attributeValues.length - 1 !== i) && __(', ') }
                    </span>
                )) }
            </div>
        );
    }

    /**
     * render Recipe Shop Flavor for recipe product
     */
    renderShopFlavor() {
        const {
            product,
            currentStoreCode
        } = this.props;

        if (currentStoreCode === LATAM_STORE_CODE) {
            return null;
        }

        return (
            <ShopFlavor
              product={ product }
            />
        );
    }

    /**
     * render Recipe Print PDF button for recipe product
     */
    renderPrintRecipe() {
        const {
            product
        } = this.props;

        return (
            <PrintRecipe
              product={ product }
            />
        );
    }

    /**
     * render Recipes Shop Flavor and Download View Desktop
     */
    renderRecipesActionBlock() {
        return (
            <div
              block="RecipeActions"
              elem="RecipeActionWrapper"
            >
                { this.renderShopFlavor() }
                { this.renderPrintPdfButton() }
            </div>
        );
    }

    /**
     * render Recipes Shop Flavor and Download View Mobile
     */
    renderRecipesActionMobile() {
        return (
            <div
              block="RecipeActions"
              elem="RecipeActionMobile"
            >
                { this.renderShopFlavor() }
                { this.renderPrintPdfButton() }
            </div>
        );
    }

    /**
     * render Recipe Instructions for recipe product
     */
    renderRecipeMethod() {
        const { instructions } = this.props;

        return (
            <section
              block="RecipeActions"
              elem="Section"
            >
                <h2 block="RecipeActions" elem="MethodTitle">
                    { __('Instructions').toString() }
                </h2>
                { (instructions.length === 0 || !instructions)
                    ? this.renderRecipeObjectMethodContent()
                    : this.renderRecipeModifiedMethodContent() }
            </section>
        );
    }

    /**
     * render Instructions with Product Links
     */
    renderRecipeModifiedMethodContent() {
        const { instructions } = this.props;

        let instructionsText = '';

        instructions.forEach((instruction) => {
            if (instruction.match(/^\(*\)/gi)) {
                instructionsText = instructionsText.concat(
                    '<p>',
                    instruction,
                    '</p>'
                );
            } else if (instruction.replace(/&[regamp]+;/gi, '').toUpperCase()
                === instruction.replace(/&[regamp]+;/gi, '')) {
                if (instructionsText === '') {
                    instructionsText = instructionsText.concat(
                        '<h3 class="RecipeAction InstructionHeader">',
                        instruction,
                        '</h3><ol>'
                    );
                } else {
                    instructionsText = instructionsText.concat(
                        '</ol><h3>',
                        instruction,
                        '</h3><ol>'
                    );
                }
            } else if (instructionsText === '') {
                instructionsText = instructionsText.concat(
                    '<ol><li>',
                    instruction,
                    '</li>'
                );
            } else {
                instructionsText = instructionsText.concat(
                    '<li>',
                    instruction,
                    '</li>'
                );
            }
        });

        return (
            <div block="RecipeActions" elem="MethodContent">
                <Html content={ instructionsText } />
            </div>
        );
    }

    /**
     * render Instructions fetched with product object
     */
    renderRecipeObjectMethodContent() {
        const { product: { method } } = this.props;

        let methodText = method?.replace(/<b>/gi, '<strong>')
            .replace(/<h2><br\s*\/?>/gi, '<h2>')
            .replace(/<\/b>/gi, '</strong>');

        const methodList = methodText?.split(/[\r\n]+|<br\s*\/?>/);

        if (!method?.match('<li>')) {
            methodText = '';
            methodList?.forEach((element) => {
                const elementText = element
                    .replace(/<p>|<em><br><\/em>|<\/p>/gi, '')
                    .trim();

                if (elementText.trim() !== '' && !elementText.match(/<p> *<\/p>/gi)) {
                    const orderedList = methodText === '';

                    if (elementText.match('<h3>|<h2>')) {
                        if (orderedList) {
                            methodText = methodText.concat(
                                elementText,
                                '<ol>'
                            );
                        } else {
                            methodText = methodText.concat(
                                '</ol>',
                                elementText,
                                '<ol>'
                            );
                        }
                    } else if (elementText.toUpperCase() === elementText) {
                        if (orderedList) {
                            methodText = methodText.concat(
                                '<h3>',
                                elementText,
                                '</h3><ol>'
                            );
                        } else {
                            methodText = methodText.concat(
                                '</ol><h3>',
                                elementText.trim(),
                                '</h3><ol>'
                            );
                        }
                    } else if (orderedList) {
                        methodText = methodText.concat(
                            '<ol><li>',
                            elementText.replace(/^\d./gi, ''),
                            '</li>'
                        );
                    } else {
                        methodText = methodText.concat(
                            '<li>',
                            elementText.replace(/^\d./gi, ''),
                            '</li>'
                        );
                    }
                }
            });

            methodText = methodText.concat('</ol>');
        }

        return (
            <div block="RecipeActions" elem="MethodContent">
                <Html content={ methodText } />
            </div>
        );
    }

    renderDesktop() {
        return (
            <>
                { this.renderRecipeIingredients() }
                { this.renderRecipeAttributes() }
                { this.renderRecipesActionBlock() }
                { this.renderRecipeMethod() }
            </>
        );
    }

    renderMobile() {
        return (
            <>
                { this.renderRecipeIingredients() }
                { this.renderRecipeAttributes() }
                { this.renderRecipesActionMobile() }
                { this.renderRecipeMethod() }
            </>
        );
    }

    render() {
        const { device: { isMobile } = {} } = this.props;

        return (
            <div block="RecipeActions">
                { isMobile ? this.renderMobile() : this.renderDesktop() }
            </div>
        );
    }
}

export default RecipeActionsComponent;
