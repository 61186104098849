import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';

import { months } from '../../util/Card.util';
import { EXPIRY_INPUT_ID } from './PayFlowPro.config';

import './PayFlowPro.style';

/** @namespace Scandiweb/Payflowpro/Component/PayFlowPro/Component/PayFlowProComponent */
export class PayFlowProComponent extends PureComponent {
    static propTypes = {
        handleCardNumberChange: PropTypes.func.isRequired,
        handleKeyPress: PropTypes.func.isRequired,
        handleMonthsChange: PropTypes.func.isRequired,
        handleYearChange: PropTypes.func.isRequired,
        handleCcvChange: PropTypes.func.isRequired,
        handleExpiryDateChange: PropTypes.func.isRequired,
        handleExpiryKeyPress: PropTypes.func.isRequired,
        cardIssuer: PropTypes.shape({
            type: PropTypes.string,
            img: PropTypes.string
        }).isRequired,
        creditCardRef: PropTypes.func.isRequired,
        yearRef: PropTypes.func.isRequired,
        ccvRef: PropTypes.func.isRequired,
        month: PropTypes.string.isRequired,
        expRef: PropTypes.func.isRequired
    };

    renderCreditCardIssuer() {
        const { cardIssuer: { type, img } } = this.props;

        if (!type) {
            return <div block="PayFlowPro" elem="CardIssuer" />;
        } if (!img) {
            return (
                <div block="PayFlowPro" elem="CardIssuer">
                    <span>{ type }</span>
                </div>
            );
        }

        return (
            <div block="PayFlowPro" elem="CardIssuer" mods={ { type } } />
        );
    }

    renderCardNumberInput() {
        const { handleCardNumberChange, creditCardRef, handleKeyPress } = this.props;
        return (
            <div block="PayFlowPro" elem="CreditCard">
                { this.renderCreditCardIssuer() }
                <input
                  ref={ creditCardRef }
                  block="PayFlowPro"
                  elem="CreditCard"
                  type="text"
                  inputMode="numeric"
                  autoComplete="cc-number"
                  maxLength="19"
                  placeholder="xxxx xxxx xxxx xxxx"
                  onChange={ handleCardNumberChange }
                  onKeyPress={ handleKeyPress }
                />
            </div>
        );
    }

    renderCardAdditional() {
        return (
            <div block="PayFlowPro" elem="CardAdditional">
                { this.renderMonth() }
                { this.renderYear() }
            </div>
        );
    }

    renderYear() {
        const { handleKeyPress, handleYearChange, yearRef } = this.props;
        return (
            <div block="PayFlowPro" elem="Year">
                <input
                  ref={ yearRef }
                  block="PayFlowPro"
                  elem="Year"
                  type="text"
                  inputMode="numeric"
                  autoComplete="year"
                  maxLength="2"
                  placeholder="Year 22"
                  onChange={ handleYearChange }
                  onKeyPress={ handleKeyPress }
                />
            </div>
        );
    }

    renderMonth() {
        const { handleMonthsChange, month } = this.props;

        return (
            <div block="PayFlowPro" elem="Months">
                <Field
                  id="month"
                  name="month"
                  type="select"
                  mix={ { block: 'PayFlowPro', elem: 'Months' } }
                  selectOptions={ months }
                  value={ month }
                  onChange={ handleMonthsChange }
                  placeholder="Month"
                />
            </div>
        );
    }

    renderCcv() {
        const { handleKeyPress, handleCcvChange, ccvRef } = this.props;

        return (
            <div block="PayFlowPro" elem="Ccv">
                <span>{ __('CCV').toString() }</span>
                <input
                  ref={ ccvRef }
                  block="PayFlowPro"
                  elem="CcvField"
                  type="text"
                  inputMode="numeric"
                  autoComplete="ccv"
                  maxLength="4"
                  placeholder="123"
                  onChange={ handleCcvChange }
                  onKeyPress={ handleKeyPress }
                />
            </div>
        );
    }

    renderExpiryDate() {
        const {
            handleExpiryKeyPress,
            handleExpiryDateChange,
            expRef
        } = this.props;

        return (
            <div block="PayFlowPro" elem="CardAdditional">
                <div block="PayFlowPro" elem="ExpiryDate">
                    <span>{ __('EXPIRATION').toString() }</span>
                    <input
                      id={ EXPIRY_INPUT_ID }
                      ref={ expRef }
                      tabIndex="0"
                      placeholder="MM/YY"
                      type="text"
                      input-mode="numeric"
                      onChange={ handleExpiryDateChange }
                      onKeyDown={ handleExpiryKeyPress }
                      pattern="[0-9]{2}\/[0-9]{2}"
                    />
                </div>
            </div>

        );
    }

    render() {
        return (
            <div block="PayFlowPro">
                { this.renderCardNumberInput() }
                <div block="PayFlowPro" elem="CardAdditionalData">
                    { /* { this.renderCardAdditional() } */ }
                    { this.renderExpiryDate() }
                    { this.renderCcv() }
                </div>
                <span block="PayFlowPro" elem="Notice">
                        { __("Please don't leave the page while payment is processing") }
                </span>
            </div>
        );
    }
}

export default PayFlowProComponent;
