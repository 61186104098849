/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 export const GoogleTagManagerDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/GoogleTagManager/GoogleTagManager.dispatcher'
);

export const data = { status: false, response: null };

/** @namespace ScandipwaSocialshare/Plugin/RouterContainerPlugin/mapDispatchToProps */
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const mdtp = callback(...args);
    const { init } = mdtp;

    mdtp.init = (...args) => {
        init(...args);
        if (!data.status) {
            data.status = true;
            GoogleTagManagerDispatcher.then(
                ({ default: dispatcher }) => {
                    if (!data.response) {
                        data.response = dispatcher.handleData(dispatch);
                        return data.response;
                    }
                }
            );
        }
    };

    return mdtp;
};

export const config = {
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

export default config;
