/* eslint-disable-next-line  */
import React, { PureComponent } from 'react';

import Html from 'Component/Html';

import { getSplitDescription } from 'Util/ProductDescriptionWrapper/index';

import '../ProductActions/ProductActions.extend.style.scss';

/* eslint-disable react/prop-types */
/** @namespace Scandipwa/Component/ProductDescriptionWrapper/Component/ProductDescriptionWrapperComponent */
export class ProductDescriptionWrapperComponent extends PureComponent {
        state = {
            showReadMore: false,
            textButton: 'Read more'
        };

        handleClick = () => {
            const { showReadMore, textButton } = this.state;
            this.setState({
                showReadMore: !showReadMore,
                textButton: textButton === 'Read more' ? 'Read less' : 'Read more'
            });
        };

        renderFullDescription() {
            const { showReadMore, textButton } = this.state;
            const { description } = this.props;

            const {
                startDescription,
                decodedHtmlWithMoreText
            } = getSplitDescription(description);

            return (
                <div>
                    <Html content={ startDescription } />
                    { showReadMore && (
                        <Html content={ decodedHtmlWithMoreText } />
                    ) }

                    <div block="ProductActions" elem="MobileMaincopy">
                        <div onClick={ this.handleClick } onKeyDown={ this.handleClick } role="button" tabIndex={ 0 }>
                            <span
                              block="ProductActions"
                              elem="MoreLessButton"
                            >
                            { textButton }
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        render() {
            const { description } = this.props;
            const indexDescription = description.indexOf('Read');

            if (indexDescription !== -1) {
                return this.renderFullDescription();
            }

            return (
                <Html content={ description } />
            );
        }
}

export default ProductDescriptionWrapperComponent;
