/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    fetchQuery
} from 'Util/Request';

import { sendPurchaseEvent } from '../../util/tracking';
import GetPurchaseInfoQuery from '../../query/GetPurchaseInfo.query';

const state = (originalMember) => ({
    ...originalMember,
    ignoreClerkLog: false
});

const renderSuccess = (args, callback, instance) => {
    const { orderId } = instance.props;
    const { ignoreClerkLog } = instance.state;

    if (orderId && !ignoreClerkLog) {
        const query = GetPurchaseInfoQuery.getPurchaseInfo(orderId);

        fetchQuery(query).then(
            ({ purchase }) => {
                const { email, customer, products } = purchase;
                instance.setState({
                    ignoreClerkLog: true
                }, () => sendPurchaseEvent({ sale: orderId, email, customer, products }));
            }
        );
    }

    return {
        ...callback(...args)
    };
}

export default {
    'BoltCheckout/Route/BoltSuccess/Component/BoltSuccessComponent': {
        'member-function': {
            renderSuccess
        },
        'member-property': {
            state
        }
    }
};
