/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { SHIPPING } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_CHECKOUT, EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const onShippingSuccess = (args, callback, instance) => {
    const { event } = instance.props;
    const {
        selectedShippingMethod: { carrier_title }
    } = instance.state;

    const data = {
        option: carrier_title,
        step: 1
    };

    callback.apply(instance, args);

    event(EVENT_GTM_CHECKOUT_OPTION, data);
};

const componentDidMount = (args, callback, instance) => {
    const { event } = instance.props;
    event(EVENT_GTM_CHECKOUT, { step: 1, message: SHIPPING });

    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess,
            componentDidMount
        }
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
