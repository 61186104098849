import { Field } from 'Util/Query';

/** @namespace MoninClienttype/Query/ClientType/Query/ClientTypeQuery */
export class ClientTypeQuery {
    getClientTypeQuery() {
        return new Field('clientType')
            .addFieldList(this.__getClientField());
    }

    __getClientField() {
        return [
            'value',
            'label'
        ];
    }
}

export default new ClientTypeQuery();
