/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};
const onClearCartButtonClick = (args, callback, instance) => {
    const { totals: { items }, event } = instance.props;

    event(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
        products: items,
        isClearCartButton: true
    });

    callback.apply(instance, args);
};

export default {
    'Route/CartPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/CartPage/Container': {
        'member-function': {
            onClearCartButtonClick
        }
    }
};
