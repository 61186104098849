/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { EVENT_GTM_PRODUCT_DETAIL } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const render = (args, callback, instance) => {
    const { event, product = {}, productSKU } = instance.props;
    const { fired = false } = instance.state;

    if (!(Object.keys(product).length === 0) && !fired && product.sku === productSKU) {
        instance.setState({ fired: true });
        event(EVENT_GTM_PRODUCT_DETAIL, { ...product });
    }

    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            render
        }
    },
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
