/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getClerk, getConfig } from './config';
import { getVisitor } from './visitor';

/** @namespace Scandiweb/Clerk/Util/Tracking/sendGenericEvent */
export const sendGenericEvent = async (endpoint, event) => {
    try {
        const {
            clerkTrackingConfig: {
                publicKey
            }
        } = await getConfig();

        const payload = {
            key: publicKey,
            visitor: getVisitor(),
            ...event
        };

        await new Promise((resolve, reject) => {
            getClerk().then(
                /** @namespace Scandiweb/Clerk/Util/Tracking/sendGenericEvent/getClerk/then */
                (clerk) => {
                    clerk(
                        'call',
                        endpoint,
                        payload,
                        (response) => resolve(response),
                        (response) => reject(response)
                    );
                }
            );
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
    }
};

/** @namespace Scandiweb/Clerk/Util/Tracking/sendEmailEvent */
export const sendEmailEvent = async (data) => sendGenericEvent('log/email', data);

/**
 * @param data {{customer: string, email: string, sale: string, products: Array<{id: string, quantity: number, price: number}>}}
 * @namespace Scandiweb/Clerk/Util/Tracking/sendPurchaseEvent
 */
export const sendPurchaseEvent = async (data) => {
    const {
        clerkTrackingConfig: {
            collectEmail
        }
    } = await getConfig();

    const { email } = data;

    if (collectEmail && email) {
        sendEmailEvent({ email });
    }

    sendGenericEvent('log/sale', data);
};

/**
 * @param data {{product: string}}
 * @namespace Scandiweb/Clerk/Util/Tracking/sendClickEvent
 */
export const sendClickEvent = async (data) => sendGenericEvent('log/click', data);

/**
 * @param data {{product: string}}
 * @namespace Scandiweb/Clerk/Util/Tracking/sendViewEvent
 */
export const sendViewEvent = async (data) => sendGenericEvent('log/product', data);

/** @namespace Scandiweb/Clerk/Util/Tracking/sendCartEvent */
export const sendCartEvent = async ({ productIds = [] }) => {
    const {
        clerkTrackingConfig: {
            collectBasket
        }
    } = await getConfig();

    if (!collectBasket) {
        return;
    }

    const clerk = await getClerk();
    clerk('cart', 'set', productIds);
};
