/* eslint-disable no-console */
import ContentWrapper from 'Component/ContentWrapper';
import {
    SomethingWentWrong as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

import { logToLoggly, logToTrackJs } from '../../util/Loggly/Log';
/** @namespace Scandipwa/Route/SomethingWentWrong/Component/SomethingWentWrongComponent */
export class SomethingWentWrongComponent extends SourceSomethingWentWrong {
    renderErrorDetails() {
        const { errorDetails: { err, info: { componentStack } = {} } } = this.props;
        const errorString = err.toString();
        const errorData = {
            err: 'Error from Ooops component.',
            message: errorString,
            stack: err.stack,
            componentStack
        };

        logToLoggly(errorData);
        logToTrackJs(errorData);

        /* Loggly dashboard shows that console can be undefined */
        console?.groupCollapsed('Suppressed error log:');
        console?.error(errorString);
        console?.groupEnd();

        if (process.env.NODE_ENV === 'production') {
            return null;
        }

        return (
            <div block="SomethingWentWrong" elem="Debug">
                { errorString }
                { componentStack }
            </div>
        );
    }

    render() {
        const { onClick, currentStoreCode } = this.props;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 block="SomethingWentWrong" elem="Heading">{ __('Ooops!').toString() }</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!').toString() }</h2>
                    <a
                      href={ `/${currentStoreCode}` }
                      block="SomethingWentWrong"
                      elem="Button"
                      mix={ { block: 'Button' } }
                      onClick={ onClick }
                    >
                        { __('Back to homepage').toString() }
                    </a>
                    { this.renderErrorDetails() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrongComponent;
