const mapStateToProps = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    code: args[0].ConfigReducer.code
});

export default {
    'Component/MyAccountAddressForm/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
