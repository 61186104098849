/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CANADA_STORE_CODE } from 'Component/Footer/Footer.config';
import {
    CheckoutAddressForm as SourceCheckoutAddressForm
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.component';
import { testCaPostRegExp } from 'Util/Account';

import { CA_STORE_CODES, CHOOSE_PROVINCE_ID, SELECT_OPTIONS_CANADA } from './CheckoutAddressForm.config';

/** @namespace Scandipwa/Component/CheckoutAddressForm/Component/CheckoutAddressForm */
export class CheckoutAddressForm extends SourceCheckoutAddressForm {
    get fieldMap() {
        const { code } = this.props;
        const { postcode: postcodeValue } = this.state;

        const fieldMap = super.fieldMap;
        const {
            region_id,
            postcode,
            country_id
        } = fieldMap;

        if (region_id) {
            fieldMap.region_id = {
                ...region_id,
                onChange: (regionId) => this.onChange('regionId', +regionId),
                validation: ['notEmpty']
            };
        }

        if (country_id && CA_STORE_CODES.includes(code)) {
            fieldMap.country_id = {
                ...country_id,
                disabled: true,
                selectOptions: [
                    SELECT_OPTIONS_CANADA
                ]
            };
        }

        if (postcode && code === CANADA_STORE_CODE) {
            fieldMap.postcode = {
                ...postcode,
                isControlled: true,
                value: postcodeValue,
                onChange: (value) => this.onCanadaPostChange(value)
            };
        }

        return fieldMap;
    }

    onCanadaPostChange(value) {
        const caCodeSpaceIdx = 3;
        const val = value.indexOf(' ') === caCodeSpaceIdx
            ? value
            : value.trim();

        if (val && !testCaPostRegExp(val)) {
            return;
        }

        this.onChange('postcode', val);
    }

    estimateShipping() {
        const { onShippingEstimationFieldsChange } = this.props;

        const {
            countryId,
            regionId,
            region,
            city,
            postcode
        } = this.state;

        if (regionId !== CHOOSE_PROVINCE_ID) {
            onShippingEstimationFieldsChange({
                country_id: countryId,
                region_id: regionId,
                region,
                city,
                postcode
            });
        }
    }
}

export default CheckoutAddressForm;
