/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author  Edgars Kuzmanis <info@scandiweb.com>
 */

export const renderActions = (args, callback, instance) => {
    const { selectedShippingMethod } = instance.props;

    return (
        <div block="Checkout" elem="StickyButtonWrapper">
            <button
              type="submit"
              block="Button"
              disabled={ !selectedShippingMethod }
              mix={ { block: 'CheckoutShipping', elem: 'Button' } }
            >
                { __('Proceed to billing').toString() }
            </button>
        </div>
    );
};

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderActions
        }
    }
};
