import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType, MixType } from 'Type/Common';

import SlickSlider from './SlickSlider.component';

/** @namespace Scandiweb/MoninTheme/Component/SlickSlider/Container/SlickSliderContainer */
export class SlickSliderContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType,
        mix: MixType,
        extendedSettings: PropTypes.instanceOf(Object)
    };

    static defaultProps = {
        children: null,
        mix: {},
        extendedSettings: {}
    };

    containerProps = () => {
        const { children, mix, extendedSettings } = this.props;

        return {
            children,
            mix,
            extendedSettings
        };
    };

    render() {
        return (
            <SlickSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default SlickSliderContainer;
