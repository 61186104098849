/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import SourcePopup from 'SourceComponent/Popup/Popup.component';
import { ESCAPE_KEY } from 'SourceComponent/Popup/Popup.config';

import { POPUP_CLOSE_BUTTON } from './Popup.config';

import './Popup.override.style';

/** @namespace Scandipwa/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    static propTypes = {
        ...super.propTypes,
        renderCloseButton: PropTypes.func
    };

    static defaultProps = {
        ...super.defaultProps,
        renderCloseButton: null
    };

    // Added validation for the visibility of the popup
    handleKeyDown = (e) => {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            if (this.getIsVisible()) {
                this.hidePopupAndGoBack();
                break;
            }
            break;
        default:
            break;
        }
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    findMain() {
        const mainList = document.getElementsByTagName('main');
        if (mainList.length) {
            const focusable = mainList[0]?.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            focusable[0].focus();
        } else {
            const sectionList = document.getElementsByTagName('section');
            if (sectionList.length > 1) {
                const focusable = sectionList[1]?.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                focusable[0].focus();
            }
        }
    }

    hidePopupAndGoBack = () => {
        this.hidePopUp();
        this.findMain();
    };

    renderCloseButton() {
        const { renderCloseButton } = this.props;

        if (renderCloseButton) {
            return renderCloseButton(this.hidePopupAndGoBack);
        }

        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close').toString() }
              onClick={ this.hidePopupAndGoBack }
              id={ POPUP_CLOSE_BUTTON }
            >
                <CloseIcon />
            </button>
        );
    }

    renderImageZoomPopUpCloseIcon() {
        return (
            <svg
              block="Popup"
              elem="CloseIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="26.458332mm"
              height="26.458332mm"
              viewBox="0 0 26.457999 26.457998"
              version="1.1"
              id="svg8"
              aria-hidden="true"
            >
                <g
                  id="layer1"
                  transform="matrix(0.17594776,0,0,0.17594776,-5.245515,-12.899243)"
                >
                    <path
                      d="m 30,73.5 150,150"
                      id="path4592"
                    />
                    <path
                      d="M 180,73.5 30,223.5"
                      id="path4594"
                    />
                </g>
            </svg>

        );
    }

    renderImageZoomPopUpCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close').toString() }
              onClick={ this.hidePopupAndGoBack }
              id="popup-close-button"
            >
                { this.renderImageZoomPopUpCloseIcon() }
            </button>
        );
    }

    // Changed heading level to h2
    renderTitle() {
        const { title } = this.props;
        if (!title) {
            return null;
        }

        return (
            <h2 block="Popup" elem="Heading">
                { title }
            </h2>
        );
    }

    /**
     * Overridden to use this component renderImageZoomPopUpCloseButton
     * @returns {*}
     * */
    renderContent() {
        const { children, contentMix, hideNotifications } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        const { isImageZoom } = this.props;

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <div block="Popup" elem="Header">
                        { this.renderTitle() }
                        { isImageZoom && this.renderImageZoomPopUpCloseButton() }
                        { !isImageZoom && this.renderCloseButton() }
                    </div>
                    { !hideNotifications && this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }

    renderLastFocusable() {
        const { id } = this.props;

        if (!this.getIsVisible()) {
            return null;
        }

        return (
            <div id={ `${id}-last` } tabIndex="0" />
        );
    }

    render() {
        const {
            mix,
            areOtherOverlaysOpen,
            label,
            id
        } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              role="dialog"
              id={ `${id}-overlay` }
              aria-modal="true"
              aria-label={ label }
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
                { this.renderLastFocusable() }
            </div>,
            document.body
        );
    }
}

export default PopupComponent;
