import PropTypes from 'prop-types';

import CloseIcon from 'Component/CloseIcon';
import TrashIcon from 'Component/TrashIcon';
import {
    CartItem as SourceCartItem
} from 'SourceComponent/CartItem/CartItem.component';
import CartItemPrice from 'SourceComponent/CartItemPrice';

import './CartItem.override.style';

/** @namespace Scandiweb/MoninTheme/Component/CartItem/Component/CartItemComponent */
export class CartItemComponent extends SourceCartItem {
    static propTypes = {
        ...this.propTypes,
        baseRowTotal: PropTypes.number.isRequired
    };

    /**
     * Pass isEditing, single item price and base price of row total to child component.
     */
    renderProductPrice() {
        const {
            currency_code,
            item: {
                price,
                row_total,
                row_total_incl_tax
            },
            isCartOverlay,
            isMobileLayout,
            isEditing,
            isMobile,
            baseRowTotal
        } = this.props;

        return (
            <CartItemPrice
              singleItemPrice={ price }
              row_total={ row_total }
              row_total_incl_tax={ row_total_incl_tax }
              baseRowTotal={ baseRowTotal }
              currency_code={ currency_code }
              isMobile={ isMobile }
              mix={ {
                  block: 'CartItem',
                  elem: 'Price',
                  mods: { isCartOverlay, isMobileLayout, isEditing }
              } }
            />
        );
    }

    /**
     * Rearranged render method position
     */
    renderDesktopContent() {
        return (
            <div block="CartItem" elem="Wrapper" mods={ { isCart: true } }>
                <div block="CartItem" elem="ProductInfo">
                    { this.renderImage() }
                </div>
                <div
                  block="CartItem"
                  elem="ProductActions"
                >
                    { this.renderTitle() }
                    { this.renderQuantityChangeField() }
                </div>
                { this.renderProductPrice() }
                { this.renderDeleteButton() }
            </div>
        );
    }

    /**
     * Added render close icon method
     */
    renderMobileContent() {
        const { isMobileLayout, isProductInStock } = this.props;

        return (
            <div block="CartItem" elem="Wrapper" mods={ { isMobileLayout, isProductOutOfStock: !isProductInStock } }>
                { this.renderImage() }
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo" mods={ { isMobileLayout } }>
                        { this.renderTitle() }
                        { this.renderDeleteButton() }
                    </div>
                    <div block="CartItem" elem="ProductActions" mods={ { isMobileLayout } }>
                        { this.renderQuantityChangeField() }
                        { this.renderProductPrice() }
                    </div>
                    { this.renderCloseIcon() }
                </div>
            </div>
        );
    }

    /**
     * Overriden mods value
     */
    renderDesktopSummary() {
        const { isCartOverlay } = this.props;

        return (
            <div block="CartItem" elem="Wrapper" mods={ { isSummary: true, isCartOverlay } }>
                { this.renderImage() }
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo" mods={ { isCartOverlay } }>
                        { this.renderTitle() }
                        { this.renderCloseIcon() }
                    </div>
                    <div block="CartItem" elem="ProductActions">
                        { this.renderQuantityChangeField() }
                        { this.renderProductPrice() }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Overriden render when its cart overlay
     */
    renderWrapperContent() {
        const { isCartOverlay } = this.props;

        if (isCartOverlay) {
            return this.renderDesktopSummary();
        }

        return super.renderWrapperContent();
    }

    /**
     * Created render method for just close icon
     */
    renderCloseIcon() {
        const { handleRemoveItem, isMobileLayout } = this.props;

        return (
            <button
              block="CartItem"
              id="RemoveItem"
              name="RemoveItem"
              elem="CloseIcon"
              mods={ { isMobileLayout } }
              aria-label="Remove item from cart"
              onClick={ handleRemoveItem }
            >
               <CloseIcon />
            </button>
        );
    }

    /**
     * Created render method for delete button
     */
    renderDeleteIcon() {
        const { isMobileLayout, isCartOverlay } = this.props;

        if (isCartOverlay) {
            return <CloseIcon />;
        }

        if (isMobileLayout) {
            return (
                <span>{ __('Remove item') }</span>
            );
        }

        return <TrashIcon />;
    }

    /**
     * Updated SVG Icon for delete button
     */
    renderDeleteButton() {
        const { handleRemoveItem, isMobileLayout } = this.props;

        return (
            <button
              block="CartItem"
              id="RemoveItem"
              name="RemoveItem"
              elem="Delete"
              mods={ { isMobileLayout } }
              aria-label="Remove item from cart"
              onClick={ handleRemoveItem }
            >
               { this.renderDeleteIcon() }
            </button>
        );
    }
}

export default CartItemComponent;
