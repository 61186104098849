import Field from 'Component/Field/Field.container';
import {
    CheckoutDeliveryOption as SourceCheckoutDeliveryOption
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';

import './CheckoutDeliveryOption.override.style';

/** @namespace Scandiweb/MoninTheme/Component/CheckoutDeliveryOption/Component/CheckoutDeliveryOptionComponent */
export class CheckoutDeliveryOptionComponent extends SourceCheckoutDeliveryOption {
    /**
     * Overridden to change checkbox to radio input, added isSelected mod
     */
    render() {
        const {
            option: {
                carrier_title,
                available
            } = {},
            onOptionClick,
            isSelected
        } = this.props;

        return (
            <li block="CheckoutDeliveryOption" mods={ { isDisabled: !available } }>
                <button
                  block="CheckoutDeliveryOption"
                  mods={ { isDisabled: !available, isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ onOptionClick }
                  disabled={ !available }
                >
                    <Field
                      type="radio"
                      id={ `option-${ carrier_title }` }
                      name={ `option-${ carrier_title }` }
                      checked={ isSelected }
                    />
                    { this.renderRow() }
                </button>
            </li>
        );
    }
}

export default CheckoutDeliveryOptionComponent;
