import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace Scandiweb/MoninTheme/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPrice {
    /**
     * Hide subprice from layout.
     */
    renderSubPrice() {
        return null;
    }

    /**
     * Overridden to remove price badge
     */
    renderCurrentPrice() {
        const {
            discountPercentage,
            formattedFinalPrice
        } = this.props;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (formattedFinalPrice == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                <span
                  itemProp={ itemProp }
                  content={ content }
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { formattedFinalPrice }
                </span>
            </PriceSemanticElementName>
        );
    }
}

export default ProductPriceComponent;
