/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author  Vladlens Starovoitovs <info@scandiweb.com>
 * @author  Zintis Scerbakovs <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Html from 'Component/Html';
import Popup from 'Component/Popup';
import BrowserDatabase from 'Util/BrowserDatabase';

import { COOKIES_ACCEPTED, FIRST_VISIT_PRIVACY_POLICY_POPUP_ID } from './FirstVisitPrivacyPolicyPopup.config';

import './FirstVisitPrivacyPolicyPopup.style.scss';

/** @namespace Gdpr/Component/FirstVisitPrivacyPolicyPopup/Component/FirstVisitPrivacyPolicyPopupComponent */
export class FirstVisitPrivacyPolicyPopupComponent extends PureComponent {
    static propTypes = {
        privacyPolicy: PropTypes.string.isRequired,
        acceptCookies: PropTypes.func.isRequired,
        handleClosePopup: PropTypes.func.isRequired,
        isStatic: PropTypes.bool.isRequired
    };

    renderContent() {
        const { privacyPolicy } = this.props;

        return (
            <div block="FirstVisitPrivacyPolicyPopup" elem="PrivacyPolicyContent">
                <Html content={ privacyPolicy } />
            </div>
        );
    }

    renderCloseButton() {
        const { handleClosePopup } = this.props;

        return (
            <div block="FirstVisitPrivacyPolicyPopup" elem="Header">
                <button
                  block="Popup"
                  elem="CloseBtn"
                  aria-label={ __('Close').toString() }
                  onClick={ handleClosePopup }
                >
                    <CloseIcon />
                </button>
            </div>
        );
    }

    render() {
        const isAccepted = BrowserDatabase.getItem(COOKIES_ACCEPTED);
        const { acceptCookies, isStatic, privacyPolicy } = this.props;

        if (isAccepted || !privacyPolicy || window.FirstVisitPrivacyPolicyPopup === true) {
            return null;
        }

        window.FirstVisitPrivacyPolicyPopup = true;

        return (
            <Popup
              id={ FIRST_VISIT_PRIVACY_POLICY_POPUP_ID }
              clickOutside={ false }
              isStatic={ isStatic }
              mix={ { block: 'FirstVisitPrivacyPolicyPopup' } }
            >
                { this.renderCloseButton() }
                { this.renderContent() }
                    <button
                      block="Button"
                      onClick={ acceptCookies }
                    >
                        { __('Accept privacy policy').toString() }
                    </button>
            </Popup>
        );
    }
}

export default FirstVisitPrivacyPolicyPopupComponent;
