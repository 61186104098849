import CheckoutPayment from 'Component/CheckoutPayment';
import {
    CheckoutPaymentsComponent as MoninCheckoutPaymentsComponent
} from 'MoninComponent/CheckoutPayments/CheckoutPayments.component';

/** @namespace Scandipwa/Component/CheckoutPayments/Component/CheckoutPaymentsComponent */
export class CheckoutPaymentsComponent extends MoninCheckoutPaymentsComponent {
    renderPayment = (method) => {
        const {
            selectPaymentMethod,
            selectedPaymentCode
        } = this.props;

        const { code } = method;
        const isSelected = selectedPaymentCode === code;

        if (isSelected) {
            return (
                <>
                    <CheckoutPayment
                      key={ code }
                      isSelected={ isSelected }
                      method={ method }
                      onClick={ selectPaymentMethod }
                    />
                    { this.renderSelectedPayment() }
                </>
            );
        }

        return (
            <CheckoutPayment
              key={ code }
              isSelected={ isSelected }
              method={ method }
              onClick={ selectPaymentMethod }
            />
        );
    };

    /**
     * remove Shopgate from payment list
     */
    renderPayments() {
        const { paymentMethods } = this.props;
        return paymentMethods
            .filter((method) => method.code !== 'shopgate')
            .map(this.renderPayment);
    }

    renderContent() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                // eslint-disable-next-line max-len
                <p>{ __('The error occurred during initializing payment methods. Please try again later!').toString() }</p>
            );
        }

        return (
            <>
                { this.renderHeading() }
                <ul block="CheckoutPayments" elem="Methods">
                    { this.renderPayments() }
                </ul>
            </>
        );
    }
}

export default CheckoutPaymentsComponent;
