import { PAYFLOWPRO_CODE } from '../component/PayFlowPro/PayFlowPro.config';

const _getPaymentData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;

    if (code === PAYFLOWPRO_CODE) {
        // eslint-disable-next-line no-unused-vars
        const [_, asyncData] = args;
        const { additional_data } = asyncData[0];

        return { code, additional_data };
    }

    return callback(...args);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData
        }
    }
};
