// Disabled by scandipwa:
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-useless-fragment */
// Disabled due `domToReact` internal logic:
/* eslint-disable consistent-return */

// To process another logic than rendering:
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';

import {
    Html as SourceHtml,
    WidgetFactory
} from 'SourceComponent/Html/Html.component';
import { hash } from 'Util/Request/Hash';

export {
    WidgetFactory
};

/** @namespace Scandipwa/Component/Html/Component/HtmlComponent */
export class HtmlComponent extends SourceHtml {
    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { name: ['button'] },
            replace: this.replaceButton
        }
    ];

    parserOptions = {
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                } if (attribs && domAttrs) {
                    // Disabled by scandipwa:
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }
        }
    };

    POSSIBLE_EVENT_ARGUMENTS = ['event', 'e', 'ev'];

    __getCallbackStringFunctionName(onclick) {
        // if onclick is undefined, delegate to function which will do nothing
        if (!onclick) {
            return 'clearTimeout';
        }

        const functionName = onclick.split('(')[0];
        return functionName;
    }

    __getCallbackStringParameters(onclick) {
        if (!onclick) {
            return [];
        }

        const fromOpenParenthesis = onclick?.split('(')[1];
        const parametersString = fromOpenParenthesis?.split(')')[0];
        const removedSpaceParametersString = parametersString?.replace(/\s/, '');
        const removedSpaceAndSingleQuoteParametersString = removedSpaceParametersString?.replace(/'/g, '');
        const removedSpaceAndSingleAndDoubleQuoteParametersString = removedSpaceAndSingleQuoteParametersString
            ?.replace(/"/, '');
        const parameters = removedSpaceAndSingleAndDoubleQuoteParametersString?.split(',');

        if (!parameters) {
            return [];
        }

        return parameters;
    }

    __checkCallbackHasEvent(parameters) {
        const hasEvent = parameters.some((parameter) => {
            const isCorrect = this.POSSIBLE_EVENT_ARGUMENTS.includes(parameter);
            return isCorrect;
        });

        return hasEvent;
    }

    __getCallbackParametersWithoutEvent(parameters) {
        const parametersWithoutEvent = parameters.filter((parameter) => {
            const isCorrect = !this.POSSIBLE_EVENT_ARGUMENTS.includes(parameter);
            return isCorrect;
        });

        return parametersWithoutEvent;
    }

    __buttonOnClickFunction(e, hasEvent, functionName, parameters, parametersWithoutEvent) {
        return hasEvent ? window[functionName](e, ...parametersWithoutEvent)
            : window[functionName](...parameters);
    }

    /**
     * Replace button `onclick` to `onClick`
     * @param  {{ attribs: Object, children: Array }}
     * @return {JSX} Return JSX of modified button
     * @memberof Html
     */
    replaceButton(elem) {
        const { attribs, children } = elem;
        const { onclick = null } = attribs;

        if (onclick) {
            const parametersWithoutEvent = [];

            const functionName = this.__getCallbackStringFunctionName(onclick);
            const parameters = this.__getCallbackStringParameters(onclick);
            const hasEvent = this.__checkCallbackHasEvent(parameters);
            if (hasEvent) {
                const toReplaceParametersWithoutEvent = this.__getCallbackParametersWithoutEvent(parameters);
                parametersWithoutEvent.splice(0, parametersWithoutEvent.length, ...toReplaceParametersWithoutEvent);
            }
            // window[functionName] is defined in script tag

            return (
                <button
                // Disabled to pass event and other parameters
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => {
                      const onClickFunction = this.__buttonOnClickFunction(
                          e,
                          hasEvent,
                          functionName,
                          parameters,
                          parametersWithoutEvent
                      );

                      return onClickFunction;
                  } }
                  { ...this.attributesToProps(attribs) }
                >
                    { domToReact(children, this.parserOptions) }
                </button>
            );
        }

        return (
                <button { ...this.attributesToProps(attribs) }>
                    { domToReact(children, this.parserOptions) }
                </button>
        );
    }

    replaceScript(elem) {
        const { attribs, children } = elem;
        const elemHash = hash(elem);

        if (this.createdOutsideElements[elemHash]) {
            return <></>;
        }

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (children && children[0]) {
            script.appendChild(document.createTextNode(children[0].data));
        }

        const isToBePlacedInBody = Boolean(attribs['data-in-body-end']) || false;
        if (isToBePlacedInBody) {
            document.body.appendChild(script);
        } else {
            document.head.appendChild(script);
        }
        this.createdOutsideElements[elemHash] = true;

        return <></>;
    }

    render() {
        const { content } = this.props;

        return parser(content, this.parserOptions);
    }
}

export default HtmlComponent;
