/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

import Popup from './Popup.component';
import { POPUP_CLOSE_BUTTON, POPUP_IS_VISIBLE } from './Popup.config';

export * from 'SourceComponent/Popup/Popup.container';

/** @namespace Scandipwa/Component/Popup/Container/PopupContainer */
export class PopupContainer extends SourcePopupContainer {
    static propTypes = {
        ...this.propTypes,
        isImageZoom: PropTypes.bool,
        label: PropTypes.string,
        hideNotifications: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isImageZoom: false,
        label: 'Popup',
        hideNotifications: false
    };

    state = {
        ...this.state,
        isVisible: this.getIsVisible()
    };

    getIsVisible() {
        const { id } = this.props;
        const dialog = document.getElementById(`${id}-overlay`);
        return dialog?.classList.contains(POPUP_IS_VISIBLE);
    }

    componentDidMount() {
        const { id } = this.props;
        const dialog = document.getElementById(`${id}-overlay`);

        const currentVisibility = this.getIsVisible();
        this.setState({ isVisible: currentVisibility });

        if (currentVisibility) {
            const firstFocusableElement = document.getElementById(POPUP_CLOSE_BUTTON);
            firstFocusableElement?.focus();
        }

        // Focus trap when the popup is visible
        dialog.addEventListener('keydown', (e) => {
            const isVisible = dialog?.classList.contains(POPUP_IS_VISIBLE);
            if (!isVisible) {
                return;
            }

            const firstFocusableElement = document.getElementById(POPUP_CLOSE_BUTTON);
            const lastFocusableElement = document.getElementById(`${id}-last`);

            if (e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
                e.preventDefault();
                lastFocusableElement?.focus();
            } else if (e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                firstFocusableElement?.focus();
            }
        });
    }

    /**
     * Focus the first focusable element when the popup becomes visible
     */
    componentDidUpdate() {
        const { isVisible } = this.state;
        const currentState = this.getIsVisible();

        if (!isVisible && currentState) {
            const firstFocusableElement = document.getElementById(POPUP_CLOSE_BUTTON);
            firstFocusableElement?.focus();

            this.setState({ isVisible: currentState });
        }

        if (isVisible && !currentState) {
            this.setState({ isVisible: currentState });
        }
    }

    containerProps() {
        const {
            isImageZoom, label, hideNotifications, clickOutside
        } = this.props;

        return {
            ...super.containerProps(),
            isImageZoom,
            label,
            hideNotifications,
            clickOutside
        };
    }

    render() {
        return (
            <Popup
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
