import {
    ProductTab as SourceProductTab
} from 'SourceComponent/ProductTab/ProductTab.component';

import './ProductTab.override.style.scss';

/** @namespace Scandiweb/MoninTheme/Component/ProductTab/Component/ProductTabComponent */
export class ProductTabComponent extends SourceProductTab {
    /**
     * Overridden to add id to the buttons
     */
    render() {
        const { tabName, isActive } = this.props;

        return (
            <li
              block="ProductTab"
              elem="Item"
              mods={ { isActive } }
            >
                <button
                  id={ tabName }
                  mix={ { block: 'ProductTab', elem: 'Button' } }
                  onClick={ this.onClick }
                >
                    { tabName.toUpperCase() }
                </button>
            </li>
        );
    }
}

export default ProductTabComponent;
