/* eslint-disable max-len */
import {
    FilterIcon as SourceFilterIcon
} from 'SourceComponent/FilterIcon/FilterIcon.component';

import './FilterIcon.style';

/** @namespace Scandiweb/MoninTheme/Component/FilterIcon/Component/FilterIconComponent */
export class FilterIconComponent extends SourceFilterIcon {
    /**
     * Overridden to replace the filter icon with a new one
     */
    render() {
        return (
            <svg
              block="FilterIcon"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1.50017 3.75H1.50069H19.5006C19.5007 3.75 19.5007 3.75 19.5007 3.75C19.5474 3.75002 19.5933 3.76314 19.6329 3.78789C19.6726 3.81264 19.7046 3.84803 19.7251 3.89004C19.7457 3.93204 19.7541 3.97898 19.7493 4.0255C19.7445 4.07191 19.7269 4.11605 19.6984 4.15293C19.6983 4.15304 19.6982 4.15314 19.6981 4.15325L12.9087 12.8825L12.7507 13.0857V13.343V20C12.7507 20.0464 12.7378 20.0919 12.7134 20.1314C12.689 20.1709 12.654 20.2028 12.6125 20.2236C12.571 20.2444 12.5245 20.2532 12.4782 20.249C12.432 20.2448 12.3878 20.2279 12.3507 20.2L8.3507 17.2C8.31964 17.1767 8.29444 17.1465 8.27709 17.1118C8.25973 17.0771 8.25069 17.0388 8.25069 17V13.344V13.0867L8.09271 12.8835L1.30271 4.15354L1.30267 4.1535C1.27393 4.11655 1.25614 4.07226 1.25132 4.0257C1.24651 3.97913 1.25487 3.93214 1.27546 3.89009C1.29604 3.84804 1.32801 3.81262 1.36774 3.78785C1.40747 3.76308 1.45336 3.74997 1.50017 3.75Z" strokeWidth="1.5" />
            </svg>
        );
    }
}

export default FilterIconComponent;
