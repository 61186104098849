export const REVIEW_NOT_FETCHED = 'review_not_fetched';
export const REVIEW_ACCEPTED = 'review_accepted';
export const REVIEW_ERROR = 'review_error';
export const REVIEW_IS_FETCHED = 'review_is_fetched';
export const REVIEW_RESPONSE_CODE_SUCCESS = 200;
export const REVIEW_RESPONSE_CODE_SERVER_ERROR = 500;

export const SOCIAL_SHARE_LINKS_MAP = {
    Linked_In: 'http://www.linkedin.com/company/monin-americas',
    Twitter: 'https://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=MoninUSA',
    Facebook: 'https://www.facebook.com/MoninUSA'
};

export const STARS_WRAPPER_ID = 'stars-wrapper';
