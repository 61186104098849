import Field from 'Component/Field/Field.container';
import {
    CheckoutPayment as SourceCheckoutPayment
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.override.style';

/** @namespace Scandiweb/MoninTheme/Component/CheckoutPayment/Component/CheckoutPaymentComponent */
export class CheckoutPaymentComponent extends SourceCheckoutPayment {
    /**
     * Overridden to remove 'disabled' prop
     */
    render() {
        const {
            isSelected,
            method: { title }
        } = this.props;

        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <Field
                      type="radio"
                      id={ `option-${ title }` }
                      name={ `option-${ title }` }
                      checked={ isSelected }
                    />
                    { title }
                </button>
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
