/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';
import { getStore } from 'Util/Store';

import ClerkResultsQuery from '../query/ClerkResults.query';
import {
    CLERK_CONFIGURED_EVENT,
    configureClerk,
    getClerk,
    getConfig
} from './config';

export const CLERK_SEARCH_RESULTS_WRAPPER_ID = 'search-results';

/** @namespace Scandiweb/Clerk/Util/Hooks/useWidgetResults */
export const useWidgetResults = (query) => {
    const location = useLocation();
    const [results, setResults] = useState('');

    useEffect(() => {
        (async () => {
            if (!query) {
                setResults('');
                return;
            }

            const data = await executeGet(prepareQuery([query]));
            setResults(Object.values(data)[0]);
        })();
    }, [location, query]);

    return results;
};

/** @namespace Scandiweb/Clerk/Util/Hooks/useSearchResults */
export const useSearchResults = (instance) => {
    const { perPage } = instance.state;
    const location = useLocation();
    const [results, setResults] = useState({});

    useEffect(() => {
        (async () => {
            const { ConfigReducer: { code = null } = {} } = getStore().getState() || {};
            const LENGTH_OF_SEARCH_PREFIX = code ? 9 + code.length : 11;
            const search = location.pathname.slice(LENGTH_OF_SEARCH_PREFIX).replace(/\+/gm, ' ');

            if (!search) {
                setResults({});
                return;
            }

            const { clerkResults } = await executeGet(prepareQuery([
                ClerkResultsQuery.getQuery({ search })
            ]));

            const spanAttributes = Object.fromEntries(
                clerkResults.spanAttributes.split('\' ')
                    .map((v) => v.split('=\''))
            );

            spanAttributes.id = CLERK_SEARCH_RESULTS_WRAPPER_ID;
            if(spanAttributes['data-facets-multiselect-attributes']){
                spanAttributes['data-facets-multiselect-attributes'] = spanAttributes['data-facets-multiselect-attributes'].slice(0, spanAttributes['data-facets-multiselect-attributes'].length -1);
            }
            else{
                spanAttributes['data-facets-attributes'] = spanAttributes['data-facets-attributes'].slice(0, spanAttributes['data-facets-attributes'].length -1);
            }
            spanAttributes['data-limit'] = perPage;

            if(location.search.includes('recipes')){
                spanAttributes['data-template'] += '-recipes';
            }
            else{
                spanAttributes['data-template'] += '-products';
            }

            /* eslint-disable fp/no-delete */
            delete spanAttributes.class;
            delete spanAttributes['data-after-render'];
            /* eslint-enable fp/no-delete */

            const newResults = {
                ...clerkResults,
                spanAttributes,
                searchValue: search
            };

            setResults(newResults);
        })();
    }, [location, perPage]);

    return results;
};

/** @namespace Scandiweb/Clerk/Util/Hooks/useClerkConfigured */
export const useClerkConfigured = () => {
    configureClerk();
    const [isConfigured, setIsConfigured] = useState(window._isClerkConfigured);

    useEffect(() => {
        document.addEventListener(CLERK_CONFIGURED_EVENT, () => {
            setIsConfigured(true);
        }, { once: true });
    }, []);

    return isConfigured;
};

/** @namespace Scandiweb/Clerk/Util/Hooks/useClerkConfig */
export const useClerkConfig = () => {
    const [config, setConfig] = useState({});

    useEffect(() => {
        (async () => {
            const newConfig = await getConfig();
            setConfig(newConfig);
        })();
    }, []);

    return config;
};

/** @namespace Scandiweb/Clerk/Util/Hooks/useClerkInitById */
export const useClerkInitById = (id) => {
    useEffect(() => {
        (async () => {
            const clerk = await getClerk();
            clerk('content', id);
        })();
    }, []);
};

/** @namespace Scandiweb/Clerk/Util/Hooks/withConfig */
export const withConfig = (Component) => (props) => {
    const config = useClerkConfig();
    const configured = useClerkConfigured();

    if (Object.keys(config).length === 0 || !configured) {
        return null;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
    return <Component { ...props } clerkConfig={ config } />;
};
