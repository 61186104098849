import {
    ProductTabComponent as SourceProductTabComponent
} from 'MoninComponent/ProductTab/ProductTab.component';

import './ProductTab.extend.style.scss';

/** @namespace Scandipwa/Component/ProductTab/Component/ProductTabComponent */
export class ProductTabComponent extends SourceProductTabComponent {
    // TODO implement logic
}

export default ProductTabComponent;
