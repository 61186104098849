import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Link from '@scandipwa/scandipwa/src/component/Link/Link.component';
import './LinksToProduct.style';
import '../../plugin/CmsPage.style.scss'

/** @namespace Component/LinksToProduct/Component */
export class LinksToProduct extends PureComponent {
    static propTypes = {
        shopNowPathToProduct: PropTypes.string,
        shopNowWebsite: PropTypes.string.isRequired,
        shopNowWebsiteTitle: PropTypes.string.isRequired
    };

    renderRedirectButton() {
        const { shopNowWebsiteTitle } = this.props;

        return (
            <Link
                to={ this.getDirectShopNowLink() }
                isOpenInNewTab={ true }
            >
                <div
                    block='LinksToProduct'
                    elem='ShopNow'
                >
                    { __( shopNowWebsiteTitle) }
                </div>
            </Link>
        )
    }

    getDirectShopNowLink() {
        const { shopNowPathToProduct, shopNowWebsite } = this.props;

        return shopNowPathToProduct ? shopNowWebsite + shopNowPathToProduct : shopNowWebsite;
    }

    render() {
        return (
            <div block="LinksToProduct">
                { this.renderRedirectButton() }
            </div>
        );
    }
}

export default LinksToProduct;
