/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ExpandableContentComponent as MoninExpandableContentComponent
} from 'MoninComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.extend.style';

/** @namespace Scandipwa/Component/ExpandableContent/Component/ExpandableContentComponent */
export class ExpandableContentComponent extends MoninExpandableContentComponent {
    keyHandler = (e) => {
        if (e.key === 'Enter') {
            this.toggleExpand();
        }
    };

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix, id } = this.props;

        return (
            <div
              role="button"
              tabIndex="0"
              block="ExpandableContent"
              elem="Button"
              aria-expanded={ isContentExpanded }
              aria-controls={ `${id}-content` }
              mods={ isContentExpanded }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.keyHandler }
            >
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        heading
                    ) }
                </div>
                { this.renderButtonIcon() }
            </div>
        );
    }

    renderContent() {
        const { children, mix, id } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              id={ `${id}-content` }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { children }
            </div>
        );
    }

    render() {
        const { mix, mods } = this.props;

        return (
            <div
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </div>
        );
    }
}

export default ExpandableContentComponent;
