/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace Scandiweb/MoninTheme/Component/AddToCart/Component/AddToCartComponent */
export class AddToCartComponent extends SourceAddToCart {
    /**
     * Overridden to pass isSmall mod
     */
    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick,
            layout,
            disabled
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        return (
            <button
              onClick={ buttonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading, layout, isSmall: true } }
              disabled={ isLoading || disabled }
            >
                { this.renderCartIcon() }
                <span>{ isLoading ? __('Adding...') : __('Add to cart') }</span>
            </button>
        );
    }
}

export default AddToCartComponent;
