/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Clerk/Query/ClerkLiveSearchConfig/Query */
export class ClerkLiveSearchConfigQuery {
    getQuery() {
        return new Field('clerkLiveSearchConfig')
            .addFieldList(this.getConfigFields());
    }

    getConfigFields() {
        return [
            'liveSearchTemplate',
            'suggestionsCount',
            'categoriesCount',
            'pagesCount',
            'pagesType',
            'positioning',
            'inputSelector', // ignored in the code
            'formSelector' // ignore in the code
        ];
    }
}

export default new ClerkLiveSearchConfigQuery();
