import AddIcon from 'Component/AddIcon';
import FieldInput from 'Component/FieldInput';
import MinusIcon from 'Component/MinusIcon';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import './Field.override.style';

/** @namespace Scandiweb/MoninTheme/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            step
        } = this.props;

        return (
            <>
                <FieldInput
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...this.props }
                  type="number"
                  readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value') }
                />
                <button
                  block="FieldNumber"
                  elem="IncreaseButton"
                  disabled={ +value === max }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + step) }
                  aria-label={ __('Add') }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  block="FieldNumber"
                  elem="DecreaseButton"
                  disabled={ +value === min }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - step) }
                  aria-label={ __('Subtract') }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }

    /**
     * Overridden to display testValidationMessage if not empty
     */
    renderMessages() {
        const {
            testValidationMessage
        } = this.props;

        if (testValidationMessage) {
            return (
                <div block="Field" elem="ErrorMessages">
                    { testValidationMessage }
                </div>
            );
        }

        return super.renderMessages();
    }

    /**
     * Overriden to remove brackets around count
     */
    renderCheckbox() {
        const {
            id,
            onChangeCheckbox,
            label,
            subLabel,
            disabled
        } = this.props;

        return (
            <label htmlFor={ id } block="Field" elem="CheckboxLabel">
                <FieldInput
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                  isDisabled={ disabled }
                />
                <div block="input-control" />
                <span>
                    { label }
                    { subLabel && (
                        <strong block="Field" elem="SubLabel">
                            { ` ${subLabel}` }
                        </strong>
                    ) }
                </span>
            </label>
        );
    }

    /**
     * Overridden to apply hasError mod if
     * test validation message is not empty
     */
    render() {
        const {
            mix,
            type,
            message,
            validationStatus,
            testValidationMessage
        } = this.props;

        return (
            <div
              block="Field"
              mods={ {
                  type,
                  hasError: validationStatus === false || !!message || !!testValidationMessage,
                  isValid: validationStatus === true
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}

export default FieldComponent;
