import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Scandiweb/MoninTheme/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...this.propTypes,
        isProductLinks: PropTypes.bool,
        isWidget: PropTypes.bool,
        isRecentlyViewed: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isProductLinks: false,
        isWidget: false,
        isRecentlyViewed: false
    };

    /**
     * Overridden to pass isProductLinks, isWidget, and isRecentlyViewed props
     */
    containerProps() {
        const { isProductLinks, isWidget, isRecentlyViewed } = this.props;

        return {
            ...super.containerProps(),
            isProductLinks,
            isWidget,
            isRecentlyViewed
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
