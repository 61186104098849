/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { useClerkInitById, withConfig } from '../util/hooks';

export const CLERK_EXIT_INTENT_WRAPPER_ID = 'clerk-exit-intent';
export const EXIT_INTENT_COMPONENT_NAME = 'exitIntent';

/** @namespace Scandiweb/Clerk/Component/ExitIntent/ExitIntent */
export const ExitIntent = withConfig(({ clerkConfig }) => {
    useClerkInitById(`#${CLERK_EXIT_INTENT_WRAPPER_ID}`);
    const { exitIntent: { templates = [] } = {} } = clerkConfig;

    return (
        <div>
            { templates.map((template) => (
                <span
                  id={ CLERK_EXIT_INTENT_WRAPPER_ID }
                  key={ template }
                  data-template={ `@${template.replace(/\s/g, '')}` }
                  data-exit-intent="true"
                />
            )) }
        </div>
    );
});
