/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const mapStateToProps = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    code: args[0].ConfigReducer.code
});

const pluginProps = (args, callback, instance) => {
    const { isBigOffline, code } = instance.props;

    return { isBigOffline, code };
};

export const config = {
    'Component/Router/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Router/Container': {
        'member-function': {
            containerProps: pluginProps
        }
    }
};

export default config;
