import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'SourceUtil/Query';

/** @namespace Scandiweb/MoninTheme/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    /**
     * Add price range info fields to cart item data.
     */
    _getCartProductInterfaceFields() {
        return [...super._getCartProductInterfaceFields(), this._getPriceRangeField()];
    }

    /**
     * Add price range info fields to cart item variant data.
     */
    _getCartProductField() {
        return new Field('product')
            .addFieldList([
                'id',
                'sku',
                'stock_status',
                'salable_qty',
                this._getPriceRangeField(),
                this._getStockItemField(),
                this._getProductThumbnailField(),
                this._getAttributesField(true, true)
            ]);
    }
}

export default new ProductListQuery();
