import Field from 'Component/Field';
import {
    ProductAttributeValueComponent as SourceProductAttributeValueComponent
} from 'MoninComponent/ProductAttributeValue/ProductAttributeValue.component';
import { getBooleanLabel } from 'Util/Product';

import './ProductAttributeValue.extend.style';

/** @namespace Scandipwa/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends SourceProductAttributeValueComponent {
    renderDropdown(value, subLabel) {
        const { isSelected } = this.props;

        return (
            <Field
              id={ value }
              name={ value }
              type="checkbox"
              label={ value }
              value={ value }
              subLabel={ subLabel }
              categoryFilter
              mix={ {
                  block: 'ProductAttributeValue',
                  elem: 'Text',
                  mods: { isSelected }
              } }
              checked={ isSelected }
            />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getOptionLabel(value) {
        const {
            attribute: {
                attribute_options,
                is_boolean
            },
            isProductCountVisible
        } = this.props;

        if (attribute_options) {
            const optionValues = attribute_options[value];
            if (optionValues) {
                if (!isProductCountVisible) {
                    const { label } = optionValues;
                    const adjustedLabel = getBooleanLabel(label, is_boolean);

                    return { ...optionValues, labelText: adjustedLabel };
                }

                const { label, count = 0 } = optionValues;

                const adjustedLabel = getBooleanLabel(label, is_boolean);

                return {
                    ...optionValues,
                    label: `${adjustedLabel} (${count})`,
                    labelText: adjustedLabel,
                    count
                };
            }
        }

        return {};
    }

    renderStringValue(value, label, count) {
        const { isFormattedAsText, isSelected } = this.props;
        const isSwatch = label;

        if (isFormattedAsText) {
            return label || __(value).toString() || __('N/A').toString();
        }

        if (!isSwatch) {
            return this.renderDropdown(value, count);
        }

        return (
            <span
              block="ProductAttributeValue"
              elem="String"
              mods={ { isSelected } }
              title={ label }
            >
                { value }
            </span>
        );
    }
}

export default ProductAttributeValueComponent;
