/* eslint-disable max-len */
import { PureComponent } from 'react';

import './WriteIcon.style';

/** @namespace Scandiweb/MoninTheme/Component/WriteIcon/Component/WriteIconComponent */
export class WriteIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="WriteIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M15.9925 2.29289C16.4036 1.90237 17.0701 1.90237 17.4812 2.29289L21.6917 6.29289C22.1028 6.68342 22.1028 7.31658 21.6917 7.70711L8.00748 20.7071C7.81007 20.8946 7.54233 21 7.26316 21H3.05263C2.47128 21 2 20.5523 2 20L2 16C2 15.7348 2.1109 15.4804 2.30831 15.2929L12.8344 5.2931L15.9925 2.29289ZM13.5789 7.41421L4.10526 16.4142L4.10526 19H6.82714L16.3008 10L13.5789 7.41421ZM17.7895 8.58579L19.4587 7L16.7368 4.41421L15.0676 6L17.7895 8.58579Z" />
            </svg>

        );
    }
}

export default WriteIconComponent;
