/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {
    cloneElement,
    createRef
} from 'react';

import { Form as SourceForm } from 'SourceComponent/Form/Form.component';

/** @namespace Scandipwa/Component/Form/Component/Form */
export class Form extends SourceForm {
    static propTypes = {
        ...SourceForm.propTypes,
        validateOnLoad: PropTypes.bool
    };

    static defaultProps = {
        ...SourceForm.defaultProps,
        validateOnLoad: false
    };

    static updateChildrenRefs(props, state = {}, validate = true) {
        const { children: propsChildren, validateOnLoad } = props;
        const { refMap: refMapState = {} } = state;

        const refMap = {};

        const children = Form.cloneChildren(
            propsChildren,
            (child) => {
                const { props: { name } } = child;
                const { message } = Object.keys(refMapState).length && (validateOnLoad || validate)
                    ? Form.validateField(child, refMapState)
                    : {};

                refMap[name] = createRef();

                const childProps = {
                    formRef: refMap[name],
                    formRefMap: refMap
                };

                if (message) {
                    childProps.message = message;
                    childProps.valueChanged = true;
                }

                return cloneElement(child, childProps);
            }
        );

        return { children, refMap };
    }

    static cloneAndValidateChildren(propsChildren, refMap, validate = true) {
        const invalidFields = [];
        const children = Form.cloneChildren(
            propsChildren,
            (child) => {
                const { props: { id, name } } = child;
                const { message } = (validate) ? Form.validateField(child, refMap) : {};

                if (message) {
                    invalidFields.push(id);

                    return cloneElement(child, {
                        message,
                        valueChanged: true,
                        formRef: refMap[name]
                    });
                }

                return cloneElement(child, {
                    formRef: refMap[name]
                });
            }
        );

        return { children, fieldsAreValid: !invalidFields.length, invalidFields };
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            attemptedSubmit: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { refMap, attemptedSubmit } = state;
        const { children, validateOnLoad } = props;

        return {
            ...Form.cloneAndValidateChildren(children, refMap, attemptedSubmit || validateOnLoad),
            ...Form.updateChildrenRefs(props, state, attemptedSubmit || validateOnLoad)
        };
    }

    errorFocus = () => {
        // eslint-disable-next-line max-len
        const formErrors = document.getElementsByClassName('Form_isInvalid')[0]?.querySelectorAll('[aria-invalid="true"]');
        if (formErrors) {
            formErrors[0].focus();
        }
    };

    handleFormSubmit = async (e) => {
        const {
            onSubmitSuccess,
            onSubmitError,
            onSubmit,
            id
        } = this.props;

        e.preventDefault();
        this.setState({ attemptedSubmit: true });
        onSubmit();

        const portalData = id ? await window.formPortalCollector.collect(id) : [];

        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;

            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
        }, this.collectFieldsInformation());

        const asyncData = Promise.all(portalData.reduce((acc, { asyncData }) => {
            if (!asyncData) {
                return acc;
            }

            return [...acc, asyncData];
        }, []));

        asyncData.then(
            /** @namespace Scandipwa/Component/Form/Component/then */
            (asyncDataList) => {
                if (!invalidFields.length) {
                    this.setState({ attemptedSubmit: false }, () => {
                        onSubmitSuccess(inputValues, asyncDataList);
                    });

                    return;
                }

                onSubmitError(inputValues, invalidFields);
                this.errorFocus();
            },
            /** @namespace Scandipwa/Component/Form/Component/then */
            (e) => onSubmitError(inputValues, invalidFields, e)
        );
    };
}

export default Form;
