import PropTypes from 'prop-types';

import Field from 'Component/Field';
import {
    FieldForm as SourceFieldForm
} from 'SourceComponent/FieldForm/FieldForm.component';

import './FieldForm.override.style';

/** @namespace Scandipwa/Component/FieldForm/Component/FieldFormComponent */
export class FieldFormComponent extends SourceFieldForm {
    static propTypes = {
        ...this.propTypes,
        message: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ])
    };

    renderField = (fieldEntry) => {
        const { isSubmitted: newestPropIsSubmitted = null, onClearInput = null } = this.props;

        const {
            key = null,
            isSubmitted,
            isControlled = false,
            id = null,
            label = null,
            name = null,
            onChange = null,
            placeholder = null,
            type = null,
            validateSeparately,
            validation = [],
            value = null,
            selectOptions = [],
            checked = null,
            ariaLabel = null,
            size = 0,
            autocomplete = 'off',
            disabled = false
        } = this.getDefaultValues(fieldEntry);

        //  If the prop value is true, then replace default with it
        const newestIsSubmitted = isSubmitted || newestPropIsSubmitted;

        return (
            <Field
              id={ id }
              key={ key }
              label={ label }
              name={ name }
              onChange={ onChange }
              placeholder={ placeholder }
              type={ type }
              validateSeparately={ validateSeparately }
              validation={ validation }
              value={ value }
              isSubmitted={ newestIsSubmitted }
              isControlled={ isControlled }
              selectOptions={ selectOptions }
              checked={ checked }
              ariaLabel={ ariaLabel }
              onClearInput={ onClearInput }
              size={ size }
              autocomplete={ autocomplete }
              disabled={ disabled }
            />
        );
    };
}

export default FieldFormComponent;
