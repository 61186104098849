import Field from 'Component/Field';
import history from 'Util/History';

export const renderCreateAccountPersonalInfoFields = (args, callback, instance) => {
    const { newsletterActive, isSubmitted, clientType = [] } = instance.props;
    const { location: { state: { firstName = '', lastName = '' } = {} } } = history;

    return (
        <fieldset block="MyAccountOverlay" elem="Legend">
            <h3>
                <legend>{ __('Personal Information').toString() }</legend>
            </h3>
            <Field
              type="text"
              label={ __('First Name').toString() }
              id="firstname"
              name="firstname"
              placeholder={ __('Your first name').toString() }
              value={ firstName }
              autocomplete="given-name"
              validation={ ['notEmpty'] }
              validateSeparately
              isSubmitted={ isSubmitted }
            />
            <Field
              type="text"
              label={ __('Last Name').toString() }
              id="lastname"
              name="lastname"
              placeholder={ __('Your last name').toString() }
              value={ lastName }
              autocomplete="family-name"
              validation={ ['notEmpty'] }
              validateSeparately
              isSubmitted={ isSubmitted }
            />
            <Field
              type="select"
              label={ __('WHICH BEST DESCRIBES YOU').toString() }
              placeholder={ __('Choose option').toString() }
              id="customer_type"
              name="customer_type"
              validation={ ['notEmpty'] }
              selectOptions={ clientType.map(({ value, label }) => ({
                  id: value,
                  label,
                  value
              })) }
              validateSeparately
              isSubmitted={ isSubmitted }
            />
            { instance.renderVatNumberField() }
            { /* eslint-disable-next-line */ }
            {newsletterActive ? instance.renderSubscribeToNewsletter() : null}
        </fieldset>
    );
};

export const renderCreateAccountSignUpInfoFields = (args, callback, instance) => {
    const { isSubmitted } = instance.props;
    const { location: { state: { email = '' } = {} } } = history;

    return (
        <fieldset block="MyAccountOverlay" elem="Legend">
            <h3>
                <legend>{ __('Sign-Up Information').toString() }</legend>
            </h3>
            <Field
              type="email"
              label={ __('Email').toString() }
              id="email"
              name="email"
              placeholder={ __('Your email address').toString() }
              value={ email }
              autocomplete="email"
              validation={ ['notEmpty', 'email'] }
              validateSeparately
              isSubmitted={ isSubmitted }
            />
            <div block="MyAccountOverlay" elem="PasswordBlock">
                <Field
                  type="password"
                  label={ __('Password').toString() }
                  id="password"
                  name="password"
                  placeholder={ __('Enter your password').toString() }
                  autocomplete="new-password"
                  validation={ ['notEmpty', 'password'] }
                  validateSeparately
                  isSubmitted={ isSubmitted }
                />
                <Field
                  type="password"
                  label={ __('Confirm password').toString() }
                  id="confirm_password"
                  name="confirm_password"
                  placeholder={ __('Retype your password').toString() }
                  autocomplete="new-password"
                  validation={ ['notEmpty', 'password', 'password_match'] }
                  validateSeparately
                  isSubmitted={ isSubmitted }
                />
            </div>
        </fieldset>
    );
};

export default {
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            renderCreateAccountPersonalInfoFields,
            renderCreateAccountSignUpInfoFields
        }
    }
};
