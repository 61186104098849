/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import LiveSearch from '../../component/LiveSearch';
import { CLERK_LIVE_SEARCH_ID, DEFAULT_SEARCH_INPUT_ID } from '../../util/config';
import { clerkOpenSearch } from '../../util/handlers';
import { setSearchResultsPosition } from '../../util/htmlHelpers';

const PRODUCTS_TYPE = 'products';

export const onKeyDown = (e, instance) => {
    const { isActive } = instance.props;
    const wrapper = document.getElementById('clerk-livesearch');
    const input = document.getElementById('search');
    const firstColumn = document.getElementById('2EPV3YJx');
    const secondColumn = document.getElementById('VjuHOTLa');

    if (isActive && wrapper && e.code === 'Escape') {
        wrapper.classList.remove('clerk-instant-search-visible');
        wrapper.classList.add('clerk-instant-search-hidden');
    }

    if (input && e.code === 'Escape') {
        instance.closeSearch();
        input.blur();
    }

    if (e.code === 'Tab' && isActive && wrapper && document.activeElement.className === 'SearchField-CloseIcon' || document.activeElement.className === 'SearchField-SearchIcon') {
        if (typeof (firstColumn) !== 'undefined' && firstColumn != null) {
            firstColumn.tabIndex = -1;
            firstColumn.focus();
        } else if (typeof (secondColumn) !== 'undefined' && secondColumn != null) {
            secondColumn.tabIndex = -1;
            secondColumn.focus();
        }
    }

    if (e.code === 'Tab' && document.activeElement.id === 'nOYFOVwE' && isActive && wrapper) {
        document.getElementById('switcher-list').focus();
        e.preventDefault();
        instance.closeSearch();
        input.blur();
    } else if (e.code === 'Tab' && document.activeElement.id === 'nOYFOVwE' && !isActive) {
        document.getElementById('switcher-list').focus();
        e.preventDefault();
        wrapper.classList.remove('clerk-instant-search-visible');
        wrapper.classList.add('clerk-instant-search-hidden');
    }
}

const componentDidMount = (_args, _callback, instance) => {
    setSearchResultsPosition();
    window.addEventListener('resize', setSearchResultsPosition);
    window.addEventListener('scroll', instance.closeLiveSearch);
    window.addEventListener('keydown', (e) => onKeyDown(e, instance));
};

export const componentWillUnmount = (_args, _callback, instance) => {
    window.removeEventListener('resize', setSearchResultsPosition);
    window.removeEventListener('scroll', instance.closeLiveSearch);
    window.removeEventListener('keydown', (e) => onKeyDown(e, instance));
    _callback(..._args);
}

const renderLiveSearch = (_args, _callback, instance) => {
    const { onSearchBarFocus, isActive } = instance.props;

    return (
        <div
          block="SearchField"
          elem="SearchInnerWrapper"
          id={ CLERK_LIVE_SEARCH_ID }
        >
            <input
              id={ DEFAULT_SEARCH_INPUT_ID }
              ref={ instance.searchBarRef }
              block="SearchField"
              elem="Input"
              onFocus={ onSearchBarFocus }
              onKeyDown={ instance.onSearchEnterPress }
              mods={ { isActive } }
              placeholder={ __('Search products').toString() }
              autoComplete="off"
              aria-label={ __('Search').toString() }
            />
                { instance.renderSearchIcon() }
            <div block="SearchOverlay">
                <div block="SearchOverlay" elem="Results" aria-label="Search results" id="live-search-results">
                    <LiveSearch
                      isActive={ isActive }
                      closeSearch={ instance.closeLiveSearch }
                    />
                </div>
            </div>
        </div>
    );
};

const handleLiveSearchEnterPress = (args, _callback, instance) => {
    const [e] = args;

    const search = e.target.value.trim().replace(/\s/g, '+');

    if (e.key === 'Enter' && search !== '') {
        clerkOpenSearch(PRODUCTS_TYPE);
        instance.searchBarRef.current.blur();
        instance.closeLiveSearch();
    }
};

const handleCloseLiveSearch = (_args, _callback, instance) => {
    const { hideActiveOverlay } = instance.props;
    hideActiveOverlay();
    instance.closeSearch();
};

const closeSearch = (args, callback, instance) => {
    const { onSearchOutsideClick } = instance.props;
    document.getElementById(DEFAULT_SEARCH_INPUT_ID).value = '';
    const liveSearchWrapper = document.getElementById('clerk-livesearch');

    if (liveSearchWrapper) {
        // Clearing previous search results
        liveSearchWrapper.innerHTML = '';
    }

    onSearchOutsideClick();
};

export default {
    'Component/SearchField/Component': {
        'member-function': {
            renderSearch: renderLiveSearch,
            onSearchEnterPress: handleLiveSearchEnterPress,
            closeLiveSearch: handleCloseLiveSearch,
            closeSearch,
            componentDidMount,
            componentWillUnmount
        }
    }
};
