/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import './BreadcrumbChevronIcon.style.scss';

/** @namespace Scandiweb/MoninTheme/Component/BreadcrumbChevronIcon/Component/BreadcrumbChevronIconComponent */
export class BreadcrumbChevronIconComponent extends PureComponent {
    render() {
        return (
             <svg
               block="BreadcrumbChevronIcon"
               width="8"
               height="12"
               viewBox="0 0 8 12"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
                 <path
                   d="M1 1L7 6L1 11"
                 />
             </svg>
        );
    }
}

export default BreadcrumbChevronIconComponent;
