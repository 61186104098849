/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

/** @namespace Scandiweb/MoninTheme/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends SourceProductAttributeValue { }

export default ProductAttributeValueComponent;
