import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import WriteIcon from 'Component/WriteIcon';

/** @namespace Scandiweb/MoninTheme/Component/ProductWriteReviewButton/Component/ProductWriteReviewButtonComponent */
export class ProductWriteReviewButtonComponent extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired
    };

    render() {
        const { onClick } = this.props;

        return (
            <button
              block="Button"
              mods={ { likeLink: true } }
              onClick={ onClick }
              mix={ { block: 'ProductWriteReviewButton' } }
            >
                    <WriteIcon />
                    { __('Write a review') }
            </button>
        );
    }
}

export default ProductWriteReviewButtonComponent;
