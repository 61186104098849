/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const BLOG_SEARCH = 'blog-search';
export const SEARCH_SR_ONLY = 'This search bar is for searching video library posts. As you type, the results will be filtered below. You can navigate the results using the up and down arrows. Press enter to select a result.';
