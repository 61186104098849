import { POPUP } from 'Component/Header/Header.config';

export const onVisible = (args, callback, instance) => {
    const { changeHeaderState, onVisible, isPump = false } = instance.props;

    if (!isPump) {
        changeHeaderState({
            name: POPUP,
            onCloseClick: () => {
                history.back();
            }
        });
    }

    onVisible();
};

export default {
    'Component/Popup/Container': {
        'member-function': {
            onVisible
        }
    }
};
