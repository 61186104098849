/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_GMT_CONFIG = 'UPDATE_GMT_CONFIG';
export const EVENT_EXECUTED = 'EVENT_EXECUTED';
export const EXECUTE_EVENT = 'EXECUTE_EVENT';

/** @namespace GtmNew/Store/GoogleTagManager/Action/updateConfig */
export const updateConfig = (payload) => ({
    type: UPDATE_GMT_CONFIG,
    payload
});

/** @namespace GtmNew/Store/GoogleTagManager/Action/setExecuted */
export const setExecuted = () => ({
    type: EVENT_EXECUTED
});

/** @namespace GtmNew/Store/GoogleTagManager/Action/event */
export const event = (event = '', custom = {}) => ({
    type: EXECUTE_EVENT,
    event,
    custom
});
